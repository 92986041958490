import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { useNavigate } from 'react-router-dom';
import Nav from './Nav'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/Magic.png"
import Library from '../images/NewLibrary.png'
import Tool from '../images/toolsIcon.png'
import Explore from '../images/Explore.png'
import Doc from '../images/doc.png'
import Form from '../images/form.png'
import SchoolAI from '../images/SchoolAI.jpg'
import Abe from '../images/Abe.png'
import Newsletter from '../images/newsletter.png'
import Guide from '../images/Guide.png';


const MagicSchoolDeepDive = () => {

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    return (

        <body>
            <div className="holder">
                <Nav />
                <header className="header-purple2 shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <div className="directions ">
                        <p> Check out the content below that we'll cover.</p>
                    </div> */}
                    <div className="title noMarginBottom">MagicSchool Deep Dive</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="AITools" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">📦💡</span> Resources</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="AILinks" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Globe">📱💬</span> Our Socials</div>
                            </ScrollLink>

                            <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Contact">📞📧</span> Contact Me</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />

                        <section name='AITools' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📦💡 Resources</h2>
                            <div className="tool-grid">
                                <a href="https://app.magicschool.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Magic School</h3>
                                    <p className="tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1wqrOdDGAFdCjyWj6-VQpCV_saWwLDGIZ8lY8jQ2ipz8/edit?usp=sharing" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Doc} alt="SchoolAI" />
                                    </div>
                                    <h3 className="tool-title">Magic School Question and Answer Document</h3>
                                    <p className="tool-text">Enter questions you have about Magic School, or questions you think staff might have, into this document.</p>
                                </a>
                                <a href="https://gruvyeducation.com/static/media/A%20Schools%20Guide%20to%20Student%20Data%20Privacy%20With%20AI.baac2c59cfae4f9dcc92.pdf" target="_blank" class="tool-card">
                                    <div class="tool-icon">
                                        <img src={Guide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 class="tool-title">Gruvy Education's Guide to Data Privacy With AI</h3>
                                    <p class="tool-text">A school's guide to data privacy with AI.</p>
                                </a>
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT Prompt Library</h3>
                                    <p className="tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                                <a href="https://gruvyeducation.com/aitools" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Tool} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Educator AI Tools Library</h3>
                                    <p className="tool-text">Collection of useful AI tools for educators</p>
                                </a>
                            </div>
                        </section>
                        <section name='AILinks' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🌐 Socials</h2>
                            <div className="tool-grid">
                                <a href="https://gruvy-ai-tips.beehiiv.com/subscribe" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Newsletter} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Educator Exclusive AI Newsletter</h3>
                                    <p className="tool-text">Join our community of educators staying ahead of the curve with AI.</p>
                                </a>
                                <a href="https://twitter.com/Gruvy_edu" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={X} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">X</h3>
                                </a>
                                <a href="https://www.youtube.com/@GruvyEducation/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">YouTube</h3>
                                </a>
                                <a href="https://www.linkedin.com/company/gruvy-education/about/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={LinkedIn} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">LinkedIn</h3>
                                </a>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default MagicSchoolDeepDive;
