import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import db from '../../Firebase';
import { UserAuth } from '../../Context/authContext';

const AIResourceCenterSteps = ({ resources, videoSrc, videoThumbnail }) => {
    const [progress, setProgress] = useState({});
    const [steps, setSteps] = useState([]);
    const { user } = UserAuth();

    useEffect(() => {
        loadUserProgress();
        organizeResourcesIntoSteps();
    }, [user, resources]);

    const loadUserProgress = async () => {
        if (user) {
            const progressRef = doc(db, "Lead Magnet", "users", "Accounts", user.email);
            const progressDoc = await getDoc(progressRef);
            if (progressDoc.exists()) {
                setProgress(progressDoc.data().progress || {});
            }
        }
    };

    const organizeResourcesIntoSteps = () => {
        const organizedSteps = resources.reduce((acc, resource) => {
            const stepIndex = resource.step - 1;
            if (!acc[stepIndex]) {
                acc[stepIndex] = {
                    title: `Step ${resource.step}: ${resource.stepTitle}`,
                    description: resource.stepDescription,
                    resources: []
                };
            }
            acc[stepIndex].resources.push(resource);
            return acc;
        }, []);

        setSteps(organizedSteps);
    };

    const handleResourceClick = async (resourceName) => {
        if (user) {
            try {
                const progressRef = doc(db, "Lead Magnet", "users", "Accounts", user.email);
                const now = new Date();
                const updatedProgress = {
                    ...progress,
                    [resourceName]: {
                        status: 'completed',
                        firstClicked: progress[resourceName]?.firstClicked || now,
                        lastClicked: now
                    }
                };

                await updateDoc(progressRef, { progress: updatedProgress });
                setProgress(updatedProgress);
            } catch (error) {
                console.error('Error saving progress:', error);
            }
        }
    };

    const getResourceStatus = (resourceName) => {
        if (progress[resourceName]?.status === "completed") {
            return {
                text: "Completed",
                className: "ai-resource-center-resource-completed",
            };
        } else {
            return { text: "Incomplete", className: "ai-resource-center-resource-incomplete" };
        }
    };

    const isStepCompleted = (step) => {
        if (step.isVideo) return progress['Intro_Video']?.status === "completed";
        return step.resources.every(resource => progress[resource.resourceName]?.status === "completed");
    };

    const isStepInProgress = (step) => {
        if (step.isVideo) return false;
        const completedResources = step.resources.filter(resource => progress[resource.resourceName]?.status === "completed");
        return completedResources.length > 0 && completedResources.length < step.resources.length;
    };

    const getStepStatus = (step) => {
        if (isStepCompleted(step)) return "completed";
        if (isStepInProgress(step)) return "in-progress";
        return "not-started";
    };

    const renderStep = (step, stepIndex) => {
        const status = getStepStatus(step);
        return (
            <div
                key={stepIndex}
                id={`step-${stepIndex + 1}`}
                className={`ai-resource-center-step ai-resource-center-step-${status}`}
            >
                <h2 className="ai-resource-center-step-title">{step.title}</h2>
                <p className="ai-resource-center-step-description">{step.description}</p>
                {stepIndex === 0 ? (
                    <div className="ai-resource-center-video-container">
                        <video
                            controlsList="nodownload"
                            controls
                            className="ai-resource-center-video"
                            poster={videoThumbnail}
                            onEnded={() => handleResourceClick('Intro_Video')}
                        >
                            <source src={videoSrc} type="video/mp4" />
                        </video>
                    </div>
                ) : null}
                <div className="ai-resource-center-tool-grid">
                    {step.resources.map((item, index) => {
                        if (stepIndex === 0 && index === 0) return null; // Skip the first resource in the first step (it's the video)
                        const { text, className } = getResourceStatus(item.resourceName);
                        return (
                            <a
                                key={index}
                                href={typeof item.link === 'function' ? undefined : item.link}
                                target={item.link && typeof item.link === 'string' ? '_blank' : undefined}
                                className={`ai-resource-center-tool-card ${className}`}
                                onClick={(e) => {
                                    if (typeof item.link === 'function') {
                                        e.preventDefault();
                                        item.link();
                                    } else if (item.link) {
                                        window.open(item.link, '_blank');
                                    } else {
                                        e.preventDefault();
                                    }
                                    handleResourceClick(item.resourceName);
                                }}
                            >
                                <div className="ai-resource-center-resource-status" style={{ color: className.includes('completed') ? '#28a745' : '#ff0000' }}>
                                    {text}
                                </div>
                                <div className="ai-resource-center-tool-icon">
                                    <img src={item.img} alt={item.name} />
                                </div>
                                <h3 className="ai-resource-center-tool-title">{item.name}</h3>
                                <p className="ai-resource-center-tool-text">{item.description}</p>
                            </a>
                        );
                    })}
                </div>
            </div>
        );
    };
    
    return (
        <div className="ai-resource-center-steps-container">
            <div className="ai-resource-center-steps-nav-bar">
                {steps.map((step, index) => {
                    const status = getStepStatus(step);
                    return (
                        <a
                            key={index}
                            href={`#step-${index + 1}`}
                            className={`ai-resource-center-step-nav-item ai-resource-center-step-nav-item-${status}`}
                        >
                            {index + 1}
                        </a>
                    );
                })}
            </div>
            <div className="ai-resource-center-content">
                {steps.map(renderStep)}
            </div>
        </div>
    );
};

export default AIResourceCenterSteps;