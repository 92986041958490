import React, { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import db from '../Firebase';

const SuperSecretAdmin = () => {
    const allSchoolsRef = collection(db, "School");
    const [resultsAreLoaded, setResultsAreLoaded] = useState(false);
    const [schools, setSchools] = useState([]);
    const [allCompletedParticipants, setAllCompletedParticipants] = useState({});
    const [allDataIsFetched, setAllDataIsFetched] = useState(false)
    const [schoolCount, setSchoolCount] = useState(0);
    let numberOfDays;

    useEffect(() => {
        const fetchAllSchools = async () => {
            const querySnapshot = await getDocs(allSchoolsRef);
            const schoolsList = querySnapshot.docs.map((doc) => doc.data());
            setSchools(schoolsList);
            setResultsAreLoaded(true);
        };

        fetchAllSchools();
    }, []);

    const [allDayCompleters, setAllDayCompleters] = useState({
        'Day 1Completers': [],
        'Day 2Completers': [],
        'Day 3Completers': [],
        'Day 4Completers': []
    });

    // Mock fetchIdealDataForSchool function
    const fetchIdealDataForSchool = async (schoolDoc) => {
        let newIdealData = {};
        const asyncDocRef = doc(schoolDoc, 'Content', 'Async');
        const asyncDocSnap = await getDoc(asyncDocRef);
        numberOfDays = asyncDocSnap.data().numberOfDays;

        for (let i = 1; i <= numberOfDays; i++) {
            const dayRef = collection(asyncDocRef, `Day ${i}`);
            const daySnapshot = await getDocs(dayRef);

            const dayData = {
                activities: {},
                closingQuestions: {},
                polls: {},
                resources: {}
            };

            // Loop through each document in the day
            daySnapshot.forEach((doc) => {
                const docData = doc.data();
                const docId = doc.id;

                if (docId.startsWith('Activity')) {
                    dayData.activities[docId] = docData;
                } else if (docId === 'Closing Questions') {
                    Object.keys(docData).forEach((key) => {
                        if (key.startsWith('Question')) {
                            dayData.closingQuestions[key] = docData[key];
                        }
                    });
                } else if (docId === 'Lecture') {
                    Object.keys(docData).forEach((key) => {
                        if (key.startsWith('Poll')) {
                            dayData.polls[key] = docData[key];
                        }
                    });
                } else if (docId.startsWith('Resource')) {
                    dayData.resources[docId] = docData;
                }
            });

            newIdealData[`Day ${i}`] = dayData;
        }

        return newIdealData;
    };


    const isDayCompleteForParticipant = (idealDataForDay, progressDataForDay, email) => {
        console.log(idealDataForDay)
        if (idealDataForDay == null) {
            return false
        }
        // console.log(progressDataForDay)
        // Extract ideal data for polls, activities, and questions
        const idealPolls = Object.keys(idealDataForDay.polls || {});
        const idealActivities = Object.keys(idealDataForDay.activities || {});
        const idealQuestions = Object.keys(idealDataForDay.closingQuestions || {});

        // Extract progress data for the participant
        const progressPolls = Object.keys(progressDataForDay.polls || {});
        const progressActivities = Object.keys(progressDataForDay.activities || {});
        const progressQuestions = Object.keys(progressDataForDay.closingQuestions || {});

        // Normalize the keys for easier comparison
        const normalizeKeys = arr => arr.map(key => key.replace(/\s+/g, '').toLowerCase());
        const normalizedIdealPolls = normalizeKeys(idealPolls);
        const normalizedIdealActivities = normalizeKeys(idealActivities);
        const normalizedIdealQuestions = normalizeKeys(idealQuestions);

        const normalizedProgressPolls = normalizeKeys(progressPolls);
        const normalizedProgressActivities = normalizeKeys(progressActivities);
        const normalizedProgressQuestions = normalizeKeys(progressQuestions);

        // Additional code to check if all questions in each activity are complete
        const areAllQuestionsInActivitiesComplete = Object.keys(idealDataForDay.activities || {}).every(activity => {
            const idealQuestions = Object.keys(idealDataForDay.activities[activity]).filter(key => key.startsWith("Question"));
            return idealQuestions.every(questionKey => {
                const spaceQuestionKey = questionKey.replace(/(\d+)/, ' $1')
                return progressDataForDay.activities?.[activity]?.[spaceQuestionKey];
            });
        });

        // Check if each item is complete for the day
        const isPollsComplete = normalizedIdealPolls.every(poll => normalizedProgressPolls.includes(poll));
        const isActivitiesComplete = areAllQuestionsInActivitiesComplete;
        const isQuestionsComplete = normalizedIdealQuestions.every(question => normalizedProgressQuestions.includes(question));

        console.log(email + " " + isPollsComplete && isActivitiesComplete && isQuestionsComplete)
        // Check if the day as a whole is complete
        return isPollsComplete && isActivitiesComplete && isQuestionsComplete;
    };


    useEffect(() => {
        const fetchAllData = async () => {
            for (const school of schools) {
                console.log("School " + school.Name)
                const schoolDoc = doc(db, 'School', school.Name);
                const schoolData = await getDoc(schoolDoc);
                const schoolName = schoolData.data().Name;
                const idealData = await fetchIdealDataForSchool(schoolDoc);
                // Fetching participants
                const emailsRef = collection(schoolDoc, "Emails");
                const querySnapshot = await getDocs(emailsRef);
                let participants = [];
                querySnapshot.forEach((doc) => {
                    participants.push({
                        name: doc.data().Name,
                        email: doc.data().Email,
                    });
                });

                // Initializing newProgressData
                let newProgressData = {};

                // Loop to populate newProgressData based on participants
                // Loop to populate newProgressData based on participants
                for (let participant of participants) {
                    const participantRef = doc(emailsRef, participant.email);
                    let participantProgress = {};  // Initialize object to hold each participant's progress

                    // Your code to fetch numberOfDays from schoolDoc should be here
                    // You could also fetch it once outside the loop and reuse it
                    const dayNames = Array.from({ length: numberOfDays }, (_, i) => `Day ${i + 1}`);

                    for (const dayName of dayNames) {

                        const dayRef = collection(participantRef, dayName);
                        const daySnapshot = await getDocs(dayRef); // Fetch all documents in the "Day" collection

                        let activities = {};
                        let clickedResources = {};
                        let polls = {};
                        let closingQuestions = {};

                        daySnapshot.forEach(doc => {
                            const docId = doc.id;
                            const docData = doc.data();

                            if (docId.startsWith('Activity')) {
                                let questions = {};
                                for (const [key, value] of Object.entries(docData)) {
                                    if (key.startsWith("Question")) {
                                        questions[key] = value;
                                    }
                                }
                                activities[docId] = questions;
                            }
                            else if (docId === 'ClickedResources') {
                                clickedResources = docData;
                            }
                            else if (docId.startsWith('Poll')) {
                                polls[docId] = docData.answer;
                            }
                            else if (docId.startsWith('Question')) {
                                closingQuestions[docId] = docData.answer;
                            }
                        });

                        let dayData = {
                            activities: activities,
                            clickedResources: clickedResources,
                            polls: polls,
                            closingQuestions: closingQuestions
                        };

                        // Set the dayData into participantProgress
                        participantProgress[dayName] = dayData;
                    }

                    // Store the participantProgress in newProgressData
                    newProgressData[participant.email] = participantProgress;
                }


                // Existing logic for checking day completers
                Object.keys(newProgressData).forEach(email => {
                    const participantData = newProgressData[email];
                    const participantName = participants.find(p => p.email === email)?.name;

                    ['Day 1', 'Day 2', 'Day 3', 'Day 4'].forEach(day => {
                        if (isDayCompleteForParticipant(idealData[day], participantData[day], email)) {
                            setAllDayCompleters(prevState => {
                                const updatedState = { ...prevState };

                                if (!updatedState[`${day}Completers`]) {
                                    updatedState[`${day}Completers`] = [];
                                }
                                updatedState[`${day}Completers`].push({
                                    name: participantName,
                                    email: email,
                                    school: schoolName
                                });

                                return updatedState;
                            });
                        }
                    });
                });
                setSchoolCount(prevCount => prevCount + 1);
            }

            // Do something with allDayCompleters, like updating state
        };

        if (resultsAreLoaded) {
            setSchoolCount(0); // Reset the count
            fetchAllData().then(() => {
                setAllDataIsFetched(true);
            });
        }

    }, [resultsAreLoaded, schools]);

    return (
        <>
            {
                resultsAreLoaded &&
                <div className="super-secret-container">
                    {['Day 1', 'Day 2', 'Day 3', 'Day 4'].map(day => (
                        <div key={day} className="super-secret-day-section">
                            <h3 className="super-secret-day-title">{day} Completers</h3>
                            <div className="super-secret-completers-list">
                                {
                                    Array.isArray(allDayCompleters[`${day}Completers`]) ?
                                        allDayCompleters[`${day}Completers`].map((completer, index) => (
                                            <div key={index} className="super-secret-completer-row">
                                                <span className="super-secret-completer-name">{completer.name},</span>
                                                <span className="super-secret-completer-email">{completer.email},</span>
                                                <span className="super-secret-completer-school">{completer.school}</span>
                                            </div>
                                        )) :
                                        <div className="super-secret-no-completers">No completers for this day.</div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            }
            {
                !allDataIsFetched && <div className="loader-wrapper">
                    <div className="loader">
                        <div className="spinner"></div>
                        {resultsAreLoaded && <div className="loading-text">{schoolCount}/{schools.length}</div>}
                    </div>
                </div>
            }

        </>
    );

};

export default SuperSecretAdmin;
