import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './SchoolBoardFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavSchoolBoard from './NavSchoolBoard';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import OverWelm from '../../images/OverWelm.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'
import Policy from '../../images/Policy.png'
import Practical from '../../images/Practical.png'
import DataAnalysis from '../../images/DataAnalysis.png'
const SchoolBoardBreakout = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavSchoolBoard module="home" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">Cambridge-Isanti Schools: School Board</div>
                        </div>
                    </header>
                    <div className="course-container">
                        <div className="MoASSP-course-navbar">
                            <ScrollLink
                                activeClass="active"
                                to="resources"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Resources">📦💡</span> Resources
                                </div>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">📞🎤</span> Contact William
                                </div>
                            </ScrollLink>
                            <Link
                                target="_blank"
                                to="/cisbaboutgruvyeducationbreakout"
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">❓🎓</span> About Our Training
                                </div>
                            </Link>
                        </div>
                        <section name='resources' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">📦💡Resources</h2>
                            <div className="MoASSP-tool-grid">
                            <a href="https://docs.google.com/document/d/1ttR5recd5AAm88gwcCMO5bGLnzkcNiTXF1noduXuc6w/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Practical} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">A Practical Guide to Using ChatGPT as a School Leader</h3>
                                    <p className="MoASSP-tool-text">Use this resource to introduce ChatGPT as a tool to your leadership team.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1sMVqVShJOi539RIix7d68dW2J9cQJY6V/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">ChatGPT Guide for School Leaders: ChatGPT Uses & Prompts</h3>
                                    <p className="MoASSP-tool-text">30+ ways to use ChatGPT as a School Leader.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1joLuo9fNFkJdamJVzWtZomVfGsp2tIogS2-WjFROvDs/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Policy} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">OpenAI (ChatGPT) Privacy Policy Breakdown for School Leaders</h3>
                                    <p className="MoASSP-tool-text">An overview of the privacy policy of the company that developed ChatGPT.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gruvy Education's Guide to Student Data Privacy With AI</h3>
                                    <p className="MoASSP-tool-text">A school's guide to student data privacy with AI.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1-Wh0kmFzaDuGHNx--YOpTmugAx7yQ3VaQuk6NwAWHpk/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataAnalysis} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Using ChatGPT for Data Analysis: Prompt Ideas for School Leaders</h3>
                                    <p className="MoASSP-tool-text">Several ideas for using ChatGPT in data analysis, each with a prompt.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Anonymize} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Anonymizing Student Data for Use with ChatGPT: Guide for School Leaders</h3>
                                    <p className="MoASSP-tool-text">A school leader's guide for anonymizing student data for use with ChatGPT.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1DFaJDM6nI1LeJPEtQZFQIBTPbXOhG3E6/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={OverWelm} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Introduction and Fundamentals of ChatGPT</h3>
                                    <p className="MoASSP-tool-text">Use to introduce ChatGPT as a tool to your staff. Or/and give it to staff who do not think they have the tech skills to use AI.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={PromptDesign} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Framework for Designing ChatGPT Prompts</h3>
                                    <p className="MoASSP-tool-text">A guide on how school leaders should design prompts for ChatGPT.</p>
                                </a>
                                
                                <a href="https://drive.google.com/file/d/1M2Dejn-OC27POEfaHSPsgVqSfc_eeCb6/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIresistant} alt="Magic School" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Creating AI-Resistant Curriculum: A Guide for Educators</h3>
                                    <p className="MoASSP-tool-text">A guide to keeping students learning in the AI era by crafting AI-resistant curriculum.</p>
                                </a>
                                <ScrollLink activeClass="active" to="rupak-section" spy={true} smooth={true} offset={-70} duration={50} className="MoASSP-tool-card cursorPointer">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guidance} alt="SchoolAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Guidance and Responsible Use Resources</h3>
                                    <p className="MoASSP-tool-text">Resources for AI policy, responsible AI use by students, and more.</p>
                                </ScrollLink>
                                <a href="https://docs.google.com/document/d/1jB_5rMS7OyA3-X6DQdbXPxOoT4ue9Rx_iiw4Zx7VVoY/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Doc} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">The Most Powerful Way I've Used ChatGPT: Streamlining Task Delegation</h3>
                                    <p className="MoASSP-tool-text">This has transformed how I delegate tasks and allows me to spend time on higher-leverage tasks.</p>
                                </a>
                                <a href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Newsletter} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI School Leadership Minute</h3>
                                    <p className="MoASSP-tool-text">My "cut the fluff, only give me what I need" AI newsletter for school leaders.</p>
                                </a>
                            </div>
                        </section>
                        <section name='rupak-section' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">🤖📚 AI Guidance</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://www.teachai.org/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={TeachAIpolicy} alt="TeachAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">TeachAI | AI Guidance for Schools</h3>
                                    <p className="MoASSP-tool-text">TeachAI provides free AI policy resources and guidance for schools.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1Chyf1e2BrPwGHod7HNFucM6agBKHQVzq/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={StudentGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI in Education: Teacher's Checklist for Success</h3>
                                    <p className="MoASSP-tool-text">A practical checklist for teachers to effectively implement AI tools in the classroom.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1px7ZjoCuOA-CpwdQl2pC_QOhiXem-o3M/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={TeacherChecklist} alt="AI Guidelines for Students" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Classroom Use: Guidelines for Students</h3>
                                    <p className="MoASSP-tool-text">An easy-to-understand graphic for students on using AI responsibly.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide2} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Guide for Navigating AI Tools as an Educator</h3>
                                    <p className="MoASSP-tool-text">Use this guide to teach your staff how to use MagicSchool's MagicStudent.</p>
                                </a>
                                <a href="https://www.aiforeducation.io/ai-resources-main" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIEDU} alt="AIEDU" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI for Education | Downloadable Resources</h3>
                                    <p className="MoASSP-tool-text">Free AI resources for your school.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gruvy Education's Guide to Data Privacy With AI</h3>
                                    <p className="MoASSP-tool-text">A school's guide to student data privacy with AI.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Anonymize} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Anonymizing Student Data for Use with ChatGPT: Guide for School Leaders </h3>
                                    <p className="MoASSP-tool-text">A school leader's guide for anonymizing student data for use with ChatGPT.</p>
                                </a>
                            </div>
                        </section>
                        <section name='NextSteps' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">✅ Next Steps</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://docs.google.com/document/d/1-s6CdrvtxNlC3sH20q0cEMdrfvFvyS3C9KxrwTHLfw4/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Logo} alt="Gruvy Education Training" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Learn More About Gruvy Education's AI Training</h3>
                                    <p className="MoASSP-tool-text">Click here to learn more about the training 100+ schools and state-level organizations have adopted.</p>
                                </a>
                                <a href="https://gruvyeducation.com/trainingportalapplication" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Form} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Apply to Bring AI Training to Your School</h3>
                                    <p className="MoASSP-tool-text">Apply to join the 100+ schools participating in our AI in Education Training.</p>
                                </a>
                                <a href="https://usemotion.com/meet/william-grube/meeting?d=30" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Schedule a Meeting with William</h3>
                                    <p className="MoASSP-tool-text">We will talk where your staff is at with AI, what the training involves, and it it is a good fit.</p>
                                </a>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default SchoolBoardBreakout;
