import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { useNavigate } from 'react-router-dom';
import Nav from './Nav'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import Doc from '../images/doc.png'

const Landing = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    return (

        <body>
            <div className="holder">
                <Nav />
                <header className="header-blue shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <div className="directions ">
                        <p>Welcome to our breakout session page! Check out the content below that we'll cover.</p>
                    </div>
                    <div className="title noMarginBottom">Maximizing the potential of AI to enhance efficiency as an Educator.</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="activities-section" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">🎨</span> Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="AITools" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">🛠️</span> AI Tools</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="Library" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📚</span> Libraries</div>
                            </ScrollLink>

                            <ScrollLink activeClass="active" to="AILinks" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Globe">🌐</span> Socials</div>
                            </ScrollLink>

                            <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Contact">📱</span> Contact</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <section id='activities-section' className="course-section course-activities-section">
                            <h2 className="course-sub-title">🎨 Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a>
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                    <a
                                        href={`#3`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        3
                                    </a>
                                    <a
                                        href={`#4`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        4
                                    </a>
                                    <a
                                        href={`#5`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        5
                                    </a>
                                    <a
                                        href={`#6`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        6
                                    </a>
                                    <a
                                        href={`#7`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        7
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Streamlining Parent Newsletters with Magic School
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/tools" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow along with the instructor to learn how to create a class newsleter with magic school.</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number">Once your newsletter is ready, follow along with the instructor to learn how to translate the newsletter to a different language.</p>
                                        </div>
                                    </form>
                                </div>


                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Navigating Difficult Parent Emails with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="chatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/1tk6JuoGdoEoMABVCtyd1zvpza4GSobTzdIA4iCP9GAQ/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="chatGPT" />
                                                    <span className="link-label">Prompt</span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Open ChatGPT and use the provided prompt document as a guide to generate an email.</p>
                                        </div>
                                    </form>
                                </div>


                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing Magic School for Student Feedback Generation
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/tools/student-work-feedback" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Please either use an actual assignment submitted by a student or request a simulated student answer from ChatGPT. To do so, you can use the following prompt: "Provide me with a student response for a [Grade] grade [Subject] assignment." Insert the text into the Magic School Feedback Tool.</p>
                                        </div>
                                    </form>
                                </div>


                                <div key="4" id="4" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Crafting Comprehensive Rubrics Using Magic School
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/tools/rubric-generator" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow the instructor, and create a rubric using Magic School's Rubric Generator Tool.</p>
                                        </div>
                                    </form>
                                </div>


                                <div key="5" id="5" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Streamlining Lesson Planning with Magic School
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/tools/lesson-plan-generator" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Use Magic School's lesson plan generator to generate a lesson plan.</p>
                                        </div>
                                    </form>
                                </div>


                                <div key="6" id="6" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Leveraging ChatGPT for Comprehensive Lesson Planning
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="chatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/1Jsqb1xzhPOHISCjhdCq1UtSwbr9HLFdEIj8yUy-7bK4/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="chatGPT" />
                                                    <span className="link-label">Prompt</span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Utilize ChatGPT by following the guidelines in the prompt document to craft a lesson plan.</p>
                                        </div>
                                    </form>
                                </div>

                                <div key="7" id="7" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Create a Project-Based Learning Activity with Magic School
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/tools/pbl-project" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Eduaide" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow along with the instructor to create a project-based learning activity using Magic School</p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='AITools' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🛠️ Free AI Tools</h2>
                            <div className="tool-grid">
                                <a href="https://autoclassmate.io/tools/ai-powered-lesson-plan-generator/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Auto} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Auto Classmate</h3>
                                    <p className="tool-text">Automated lesson planning aligned with state standards.</p>
                                </a>
                                <a href="https://chat.openai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={ChatGPT} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT</h3>
                                    <p className="tool-text">ChatGPT direct link.</p>
                                </a>
                                <a href="https://app.magicschool.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Magic School</h3>
                                    <p className="tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>
                                <a href="https://web.diffit.me/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Diffit} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Diffit</h3>
                                    <p className="tool-text">Creates AI Generated Google Docs for Teachers.</p>
                                </a>
                                <a href="https://gptzero.me/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={GPTZero} alt="GPTZero" />
                                    </div>
                                    <h3 className="tool-title">GPTZero</h3>
                                    <p className="tool-text">AI Detefction Tool</p>
                                </a>
                            </div>
                        </section>
                        <section name='Library' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📚 Libraries</h2>
                            <div className="tool-grid">
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT Prompt Library</h3>
                                    <p className="tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                                <a href="https://gruvyeducation.com/aitools" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Educator AI Tools Library</h3>
                                    <p className="tool-text">Collection of useful AI tools for educators</p>
                                </a>

                            </div>
                        </section>
                        <section name='AILinks' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🌐 Socials</h2>
                            <div className="tool-grid">
                                <a href="https://www.facebook.com/groups/630420622459086/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Facebook} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Join our Facebook Group</h3>
                                    <p className="tool-text">Join our community of educators staying ahead of the curve with AI.</p>
                                </a>
                                <a href="https://twitter.com/Gruvy_edu" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={X} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">X</h3>
                                </a>
                                <a href="https://www.youtube.com/@GruvyEducation/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">YouTube</h3>
                                </a>
                                <a href="https://www.linkedin.com/company/gruvy-education/about/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={LinkedIn} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">LinkedIn</h3>
                                </a>
                            </div>
                        </section>
                        <section name='NextSteps' className="course-section course-resources-section">
                            <h2 className="course-sub-title">✅ Next Steps</h2>
                            <div className="tool-grid">
                                <a href="https://gruvyeducation.com/traininginfo" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Explore} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Explore AI Training Opportunities for K-12 Educators</h3>
                                    <p className="tool-text">Join the 30+ schools participating in our AI in Education Training.</p>
                                </a>
                                {/* <a href="https://usemotion.com/meet/william-grube/meeting?d=30" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Schedule a time to come see me today.</h3>
                                    <p className="tool-text">I will be set up in Hall B.</p>
                                </a> */}
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default Landing;
