import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './MoASSPFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavNDCEL from './NavNDCEL1';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'

const MoASSPBreakout = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    const prompts1 = [
        {
            id: 'difficult-parent-email',
            directions: "Copy and paste the prompt below into ChatGPT to draft a response to a difficult parent email. Fill in the variables in square brackets with specific details relevant to your position and the issue at hand.",
            text: `Act as a [Position e.g., Principal, Teacher]. You've received an email from a parent who is visibly upset or angry about [Issue]. Please draft a tactful, empathetic, and professional email that acknowledges the parent's feelings, clarifies any misunderstandings, and outlines steps the school is taking or will take to address the issue. Also, if appropriate, suggest a follow-up meeting or phone call to discuss the matter further. Include the following points: [what you would like to include].`.split('\n')
        },
        {
            id: 'parental-communication-email',
            directions: "Copy and paste the prompt below into ChatGPT to draft a response to a parent's email.",
            text: `Act as a [position]. You've received an email from a parent concerning their child. The email discusses [Concern Topic]. Please draft a balanced, reassuring, and informative email that addresses this topic. Include: [what you would like to include].`.split('\n')
        },
        {
            id: 'professional-email',
            directions: "Copy and paste the prompt below into ChatGPT to draft an email to a colleague.",
            text: `Act as a [position]. You want to send an email to a colleague. The email discusses [Concern Topic]. Please draft a balanced, reassuring, and informative email that addresses this topic. Include: [what you would like to include].`.split('\n')
        },
    ];


    const prompts3 = [
        {
            id: 'improvment-plan',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in creating an improvement plan for a technology coach.",
            text: `Act as if you are an high school school principal. You are struggling with your technology coach. Not only do they have a sarcastic and offputting personality, you haven't seen any results from their work over the last year and half. Please write an improvement plan and suggest activities for this improvement plan.`.split('\n')
        },
        {
            id: 'tech-coach-improvement-plan',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in coaching a teacher on their instruction in AP Physics.",
            text: `Act as if you are a high school principal coaching a teacher on their instruction. Though you lack the content expertise of AP Physics, you recognize that the teacher struggles with alignment between what they teach and what the students learn. The teacher has a wide variety of engaging lessons and lab activities, but you are unable to see a structure that is based on the standard the teacher needs to teach and evidence-based outcomes demonstrating student proficiency of those standards. Provide me with some notes to include in the teacher's evaluations or things I can say to the teacher in our coaching conversations.`.split('\n')
        },
        {
            id: 'role-play-registrar-conversation',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in generating kindly worded suggestions for improvement.",
            text: `Please provide a gently worded, constructive suggestion for a school staff member to enhance their [specific area needing improvement, e.g., 'classroom management techniques', 'student engagement methods', 'use of educational technology']. The feedback should be supportive and aimed at fostering professional growth.`.split('\n')
        },
    ];


    const prompts4 = [
        {
            id: 'trauma-support',
            directions: "Open ChatGPT using the link in the \"Links Needed for Activity\" section. Inside of ChatGPT, click on the paper clip button in the prompt bar, select \"Upload from computer,\" and choose your downloaded Excel file. Send the following prompt to ChatGPT with the attached file:",
            text: `Analyze the behavior incident data in the attached Excel file. Identify significant trends over time, categorize types of behavior incidents, and highlight students with recurring behavior issues. Suggest potential interventions to address both the recurring issues and the students involved. Provide visualizations to support your analysis.`.split('\n')
        },
    ];

    const prompts2 = [
        {
            id: 'role-action-context-format',
            directions: "Use the role-action-context-format prompt design framework and experiment with it in ChatGPT. Here is an example:",
            text: `Act as a school principal. (Role) Draft a student-friendly contract for a 'Classroom Device Care Agreement' that students can understand and follow. (Action) Include clear expectations, consequences for non-compliance, and positive reinforcement techniques to motivate adherence. (Context) Make sure the contract is straightforward and engaging for students. (Format)`.split('\n')
        }
    ];


    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavNDCEL module="home" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">Getting Started with AI for School Leaders</div>
                        </div>
                    </header>
                    <div className="course-container">
                        <div className="MoASSP-course-navbar">
                            <ScrollLink
                                activeClass="active"
                                to="william"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">🏃‍♂️🎨</span> Activities
                                </div>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="resources"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Resources">📦💡</span> Resources
                                </div>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">📞🎤</span> Contact William
                                </div>
                            </ScrollLink>
                            <Link
                                target="_blank"
                                to="/ndcel1aboutgruvyeducationbreakout"
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">❓🎓</span> About Gruvy Education
                                </div>
                            </Link>
                        </div>
                        <section id="william" className="MoASSP-course-section MoASSP-activities-section">
                            <h2 className="MoASSP-course-sub-title">🏃‍♂️🎨 Activities</h2>
                            <div className="MoASSP-activities-wrapper">
                                {/* Navigation bar */}
                                <div className="MoASSP-activity-nav-bar">
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="1" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">1</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="2" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">2</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="3" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">3</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="4" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">4</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="5" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">5</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="6" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">6</ScrollLink>
                                </div>

                                {/* Activity 1 */}
                                <div key="1" id="1" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 1: </span>
                                        Utilizing ChatGPT for Communications via Email
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open up ChatGPT. The link can be found in the "Links Needed for Activity" section above.
                                            </p>
                                        </div>
                                        {prompts1.map((prompt, index) => (
                                            <div key={prompt.id} className="MoASSP-activity-step">
                                                <p className="MoASSP-activity-direction">
                                                    <strong>{index + 2}.</strong> {prompt.directions}
                                                </p>
                                                <CopyToClipboard
                                                    textToCopy={prompt.text.join('\n')}
                                                    displayText={prompt.text}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Activity 2 */}
                                <div key="2" id="2" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 2: </span>
                                        Role, Action, Context, Format
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={PromptDesign} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Guide</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        {prompts2.map((prompt, index) => (
                                            <div key={prompt.id} className="MoASSP-activity-step">
                                                <p className="MoASSP-activity-direction">
                                                    <strong>{index + 1}.</strong> {prompt.directions}
                                                </p>
                                                <CopyToClipboard
                                                    textToCopy={prompt.text.join('\n')}
                                                    displayText={prompt.text}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Activity 3 */}
                                <div key="3" id="3" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 3: </span>
                                        Using AI in Human Capital
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div key="7-1" className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open up ChatGPT. The link can be found in the "Links Needed for Activity" section above.
                                            </p>
                                        </div>
                                        {prompts3.map((prompt, index) => (
                                            <div key={prompt.id} className="MoASSP-activity-step">
                                                <p className="MoASSP-activity-direction">
                                                    <strong>{index + 2}.</strong> {prompt.directions}
                                                </p>
                                                <CopyToClipboard
                                                    textToCopy={prompt.text.join('\n')}
                                                    displayText={prompt.text}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div key="4" id="4" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 4: </span>
                                        Streamlining Data Analysis with ChatGPT: Behavior Data Insights
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/spreadsheets/d/1HRUjQ021cXDxnvE2_IiXJ8C61Wi4YNA-MlinSZTPAS8/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={GoogleSheetIcon} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Google Sheet</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Anonymize} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Anonymize Data</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div key="7-1" className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the Google Sheet found in the "Links Needed for Activity" section. Click on "File" in the top left corner, hover over "Download," and then click on "Microsoft Excel (.xlsx)."
                                            </p>
                                        </div>
                                        {prompts4.map((prompt, index) => (
                                            <div key={prompt.id} className="MoASSP-activity-step">
                                                <p className="MoASSP-activity-direction">
                                                    <strong>{index + 2}.</strong> {prompt.directions}
                                                </p>
                                                <CopyToClipboard
                                                    textToCopy={prompt.text.join('\n')}
                                                    displayText={prompt.text}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div key="5" id="5" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 5: </span>
                                        Exploring Magic School
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://app.magicschool.ai/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Magic} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">MagicSchool</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Guide2} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Guide</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div key="7-1" className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Click on the link in the "Links Needed for Activity" section to access Magic School. Your teachers can use Magic School to save a significant amount of time. Here some of the most popular Magic School tools: Report Card Comments Generator, Multiple Choice Assessments Generator, Text Rewriter, Lesson Plan Generator, Rubric Generator, Professional Email Writer, YouTube Video Questions Generator. I highly recommend sharing this resource with your teachers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="6" id="6" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 6: </span>
                                        Learn How to Use QuillBot, an AI Detection Tool
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://quillbot.com/ai-content-detector" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Quill} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">QuillBot</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div key="7-1" className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> First, ask ChatGPT to summarize your favorite book. The goal is to take the text from ChatGPT and input it into the AI text detection tool.
                                            </p>
                                        </div>
                                        <div key="7-1" className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Next, put that summary into QuillBot and press on "Check Origin".
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section name='resources' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">📦💡Resources</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://drive.google.com/file/d/1sMVqVShJOi539RIix7d68dW2J9cQJY6V/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">ChatGPT Guide for School Leaders: ChatGPT Uses & Prompts</h3>
                                    <p className="MoASSP-tool-text">30+ ways to use ChatGPT as a School Leader.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={PromptDesign} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Framework for Designing ChatGPT Prompts</h3>
                                    <p className="MoASSP-tool-text">A guide on how school leaders should design prompts for ChatGPT.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gruvy Education's Guide to Student Data Privacy With AI</h3>
                                    <p className="MoASSP-tool-text">A school's guide to student data privacy with AI.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Anonymize} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Anonymizing Student Data for Use with ChatGPT: Guide for School Leaders </h3>
                                    <p className="MoASSP-tool-text">A school leader's guide for anonymizing student data for use with ChatGPT.</p>
                                </a>
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Educator ChatGPT Prompt Library</h3>
                                    <p className="MoASSP-tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide2} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Guide for Navigating AI Tools as an Educator</h3>
                                    <p className="MoASSP-tool-text">A guide outlining popular educator-specific AI tools, what they do, and whether they are free or their cost.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1M2Dejn-OC27POEfaHSPsgVqSfc_eeCb6/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIresistant} alt="Magic School" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Creating AI-Resistant Curriculum: A Guide for Educators</h3>
                                    <p className="MoASSP-tool-text">A guide to keeping students learning in the AI era by crafting AI-resistant curriculum.</p>
                                </a>
                                <a href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Newsletter} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI School Leadership Minute</h3>
                                    <p className="MoASSP-tool-text">My "cut the fluff, only give me what I need" AI newsletter for school leaders.</p>
                                </a>
                                <ScrollLink activeClass="active" to="rupak-section" spy={true} smooth={true} offset={-70} duration={50} className="MoASSP-tool-card cursorPointer">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guidance} alt="SchoolAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Guidance and Responsible Use Resources</h3>
                                    <p className="MoASSP-tool-text">Resources for AI policy, responsible AI use by students, and more.</p>
                                </ScrollLink>
                                <a href="https://drive.google.com/drive/folders/1embys9TFeGJ8jRXX8njetkCllJG0QHSl?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Folder} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Resource Toolkit for Teachers</h3>
                                    <p className="MoASSP-tool-text">Resources to help teachers use AI as a tool.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1-s6CdrvtxNlC3sH20q0cEMdrfvFvyS3C9KxrwTHLfw4/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={PurpleLogo} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Learn about Gruvy Education's AI Training</h3>
                                    <p className="MoASSP-tool-text">Learn about the AI training trusted by over 100 schools.</p>
                                </a>
                                <a href="https://gruvyeducation.com/trainingportalapplication" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={GreenLogo} alt="Gruvy Education Training" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Apply to Talk to William About Brining AI Training for Your Staff</h3>
                                    <p className="MoASSP-tool-text">Submit your application to bring the AI training trusted by over 100 schools to your staff.</p>
                                </a>
                            </div>
                        </section>
                        <section name='rupak-section' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">🤖📚 AI Guidance</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://www.teachai.org/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={TeachAIpolicy} alt="TeachAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">TeachAI | AI Guidance for Schools</h3>
                                    <p className="MoASSP-tool-text">TeachAI provides free AI policy resources and guidance for schools.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1Chyf1e2BrPwGHod7HNFucM6agBKHQVzq/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={StudentGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI in Education: Teacher's Checklist for Success</h3>
                                    <p className="MoASSP-tool-text">A practical checklist for teachers to effectively implement AI tools in the classroom.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1px7ZjoCuOA-CpwdQl2pC_QOhiXem-o3M/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={TeacherChecklist} alt="AI Guidelines for Students" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Classroom Use: Guidelines for Students</h3>
                                    <p className="MoASSP-tool-text">An easy-to-understand graphic for students on using AI responsibly.</p>
                                </a>
                                <a href="https://www.aiforeducation.io/ai-resources-main" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIEDU} alt="AIEDU" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI for Education | Downloadable Resources</h3>
                                    <p className="MoASSP-tool-text">Free AI resources for your school.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gruvy Education's Guide to Data Privacy With AI</h3>
                                    <p className="MoASSP-tool-text">A school's guide to student data privacy with AI.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Anonymize} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Anonymizing Student Data for Use with ChatGPT: Guide for School Leaders </h3>
                                    <p className="MoASSP-tool-text">A school leader's guide for anonymizing student data for use with ChatGPT.</p>
                                </a>
                            </div>
                        </section>
                        <section name='breakout' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">🔧💻 Library of Relevant AI Tools</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide2} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Guide for Navigating AI Tools as an Educator</h3>
                                    <p className="MoASSP-tool-text">A guide outlining popular educator-specific AI tools, what they do, and whether they are free or their cost.</p>
                                </a>

                                <a href="https://chat.openai.com/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={ChatGPT} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">ChatGPT</h3>
                                    <p className="MoASSP-tool-text">ChatGPT direct link.</p>
                                </a>

                                <a href="https://app.magicschool.ai/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Magic School</h3>
                                    <p className="MoASSP-tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>

                                <a href="https://schoolai.com/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={SchoolAI} alt="SchoolAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">SchoolAI</h3>
                                    <p className="MoASSP-tool-text">Designed to safely integrate AI into the classroom for K-12 students, with built-in monitoring and controls for teachers.</p>
                                </a>

                                <a href="https://web.diffit.me/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Diffit} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Diffit</h3>
                                    <p className="MoASSP-tool-text">Diffit helps teachers save time by providing "just right" instructional materials, ensuring all students access grade-level content.</p>
                                </a>
                                <a href="https://www.khanacademy.org/teacher/khanmigo-tools" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Khan} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Khanmigo Tools</h3>
                                    <p className="MoASSP-tool-text">Free AI powered tools designed to save you time and improve instruction!</p>
                                </a>
                                <a href="https://gamma.app/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Gamma} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gamma</h3>
                                    <p className="MoASSP-tool-text">Your AI-powered presentation creator.</p>
                                </a>
                                <a href="https://www.eduaide.ai/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Eduaide} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Eduaide.Ai</h3>
                                    <p className="MoASSP-tool-text">Eduaide.AI is your AI-powered tool for designing lessons, creating resources, and offering feedback, guided by your expertise.</p>
                                </a>
                                <a href="https://chromewebstore.google.com/detail/brisk-teaching/pcblbflgdkdfdjpjifeppkljdnaekohj" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Brisk} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Brisk Teaching</h3>
                                    <p className="MoASSP-tool-text">A Chrome extension designed to save teachers time with AI-powered tools.</p>
                                </a>
                                <a href="https://curipod.com/21ec955a-72e5-4169-96c1-0352e9f11cbd/home" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Curipod} alt="QuilBot" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Curipod</h3>
                                    <p className="MoASSP-tool-text">Instantly create lessons, slides, exit tickets, polls, and questions with AI-generated content.</p>
                                </a>
                            </div>
                        </section>
                        <section name='NextSteps' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">✅ Next Steps</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://docs.google.com/document/d/1-s6CdrvtxNlC3sH20q0cEMdrfvFvyS3C9KxrwTHLfw4/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Logo} alt="Gruvy Education Training" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Learn More About Gruvy Education's AI Training</h3>
                                    <p className="MoASSP-tool-text">Click here to learn more about the training 100+ schools and state-level organizations have adopted.</p>
                                </a>
                                <a href="https://gruvyeducation.com/trainingportalapplication" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Form} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Apply to Bring Gruvy Education's AI Training to Your Educators</h3>
                                    <p className="MoASSP-tool-text">Apply to join the 100+ schools participating in our AI in Education Training.</p>
                                </a>
                                <a href="https://usemotion.com/meet/william-grube/meeting?d=30" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Schedule a Meeting with William</h3>
                                    <p className="MoASSP-tool-text">We will talk where your staff is at with AI, what the training involves, and it it is a good fit.</p>
                                </a>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default MoASSPBreakout;
