import React, { useState, useRef, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const AICalculator = () => {
    const [teacherCount, setTeacherCount] = useState('');
    const [currentConfidence, setCurrentConfidence] = useState(1);
    const [showResults, setShowResults] = useState(false);
    const [results, setResults] = useState(null);
    const [errors, setErrors] = useState({ teacherCount: false, confidence: false });
    const teacherInputRef = useRef(null);
    const resultsRef = useRef(null);
    const resultsTitleRef = useRef(null);

    const scrollToResults = () => {
        setTimeout(() => {
            resultsTitleRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
    };

    useEffect(() => {
        if (showResults) {
            scrollToResults();
        }
    }, [showResults, results]);

    const handlePrint = () => {
        window.print();
    };

    const PrintButton = () => (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }}>
            <button
                onClick={handlePrint}
                style={{
                    backgroundColor: '#4c49ea',
                    color: 'white',
                    padding: '0.75rem 1.5rem',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    transition: 'background-color 0.3s'
                }}
            >
                Print Results
            </button>
        </div >
    );

    const validateInputs = () => {
        const newErrors = {
            teacherCount: teacherCount === '',
            confidence: currentConfidence === 0
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const calculateResults = () => {
        if (!validateInputs()) {
            return;
        }

        const weeklyTimeSaved = Number(teacherCount) * 3.23;
        const yearlyTimeSaved = weeklyTimeSaved * 36;

        // Adjust the improved confidence calculation
        const minImprovedConfidence = 7.4;
        const maxImprovedConfidence = 9.1;
        const improvedConfidence = Math.min(
            Math.max(
                currentConfidence * 2.25,
                minImprovedConfidence
            ),
            maxImprovedConfidence
        );

        const confidenceBoostPercentage = ((improvedConfidence - currentConfidence) / currentConfidence) * 100;

        setResults({
            weeklyTimeSaved,
            yearlyTimeSaved,
            currentConfidence,
            improvedConfidence,
            confidenceBoostPercentage
        });

        setShowResults(true);
    };

    const StatCard = ({ title, value, icon, color }) => (
        <div style={{
            backgroundColor: 'white',
            borderRadius: '16px',
            padding: '2rem',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            transition: 'all 0.3s ease-in-out',
            ':hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 15px 35px rgba(0, 0, 0, 0.15)'
            }
        }}>
            <div style={{
                fontSize: '3.5rem',
                color: color,
                marginBottom: '1rem',
                background: `linear-gradient(135deg, ${color}, ${color}88)`,
                WebkitBackgroundClip: 'text',
            }}>
                {icon}
            </div>
            <h3 style={{
                fontSize: '1.4rem',
                fontWeight: '600',
                marginBottom: '0.75rem',
                color: '#333'
            }}>
                {title}
            </h3>
            <p style={{
                fontSize: '2.5rem',
                fontWeight: 'bold',
                color: color,
                textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
            }}>
                {value}
            </p>
        </div>
    );

    const InputField = ({ label, value, onChange, error, errorMessage, type = "text", inputRef }) => (
        <div style={{ marginBottom: '2rem' }}>
            <label style={{ display: 'block', marginBottom: '0.75rem', fontWeight: 'bold', fontSize: '1.5rem' }}>
                {label} <span style={{ color: '#e44002' }}>*</span>
            </label>
            <input
                ref={inputRef}
                type={type}
                value={value}
                onChange={onChange}
                style={{
                    width: '100%',
                    padding: '0.75rem',
                    fontSize: '1.5rem',
                    border: `1px solid ${error ? '#e44002' : '#ccc'}`,
                    borderRadius: '4px',
                }}
                placeholder={type === "number" ? "Enter number of teachers" : ""}
            />
            {error && <p style={{ color: '#e44002', marginTop: '0.5rem' }}>{errorMessage}</p>}
        </div>
    );

    const ConfidenceInput = () => (
        <div style={{ marginBottom: '2rem' }}>
            <label style={{ display: 'block', marginBottom: '0.75rem', fontWeight: 'bold', fontSize: '1.5rem' }}>
                On a scale of 1-10, how confident is your staff with AI currently? <span style={{ color: '#e44002' }}>*</span>
            </label>
            <p style={{ marginBottom: '1rem', fontSize: '1.2rem', color: '#666' }}>
                (1 = No one knows how to use it at all, 10 = Everyone is using it proficiently)
            </p>
            <input
                type="range"
                min="1"
                max="10"
                value={currentConfidence}
                onChange={(e) => setCurrentConfidence(Number(e.target.value))}
                style={{ width: '100%', accentColor: '#4c49ea', height: '24px' }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem', fontSize: '1.2rem' }}>
                <span>1</span>
                <span style={{ fontWeight: 'bold', color: '#4c49ea' }}>Current: {currentConfidence}</span>
                <span>10</span>
            </div>
            {errors.confidence && <p style={{ color: '#e44002', marginTop: '0.5rem' }}>Please adjust the confidence level.</p>}
        </div>
    );

    const ConfidenceBoostVisual = ({ current, improved, boostPercentage }) => {
        const lowConfidenceTextStyle = current <= 2 ? {
            textShadow: `0px 0px 3px rgba(255,255,255,0.5)`,
        } : {};
    
        return (
            <div style={{
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '2.5rem',
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                marginBottom: '2rem'
            }}>
                <h3 style={{ 
                    fontSize: '1.75rem', 
                    fontWeight: '600', 
                    marginBottom: '2rem', 
                    color: '#333',
                    borderBottom: '2px solid #f0f0f0',
                    paddingBottom: '1rem'
                }}>
                    Projected Confidence Boost in Using AI for Teaching
                </h3>
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'flex-end', 
                    height: '250px',
                    margin: '2rem 0'
                }}>
                    <div style={{
                        width: '120px',
                        height: `${Math.max((current / 10) * 250, 50)}px`,
                        backgroundColor: '#4c49ea',
                        borderRadius: '8px 8px 0 0',
                        marginRight: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        padding: '1rem',
                        color: 'white',
                        fontWeight: 'bold',
                        boxShadow: '0 4px 10px rgba(76, 73, 234, 0.3)'
                    }}>
                        <div style={lowConfidenceTextStyle}>Current</div>
                        <div style={{...lowConfidenceTextStyle, fontSize: '1.5rem', marginTop: '0.5rem'}}>{current.toFixed(1)}/10</div>
                    </div>
                    <div style={{
                        width: '120px',
                        height: `${(improved / 10) * 250}px`,
                        backgroundColor: '#3ecd5e',
                        borderRadius: '8px 8px 0 0',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        padding: '1rem',
                        color: 'white',
                        fontWeight: 'bold',
                        boxShadow: '0 4px 10px rgba(62, 205, 94, 0.3)'
                    }}>
                        <div>After</div>
                        <div style={{fontSize: '1.5rem', marginTop: '0.5rem'}}>{improved.toFixed(1)}/10</div>
                    </div>
                </div>
                <p style={{ 
                    fontSize: '2.25rem', 
                    fontWeight: 'bold', 
                    color: '#3ecd5e', 
                    marginTop: '2rem',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
                }}>
                    {boostPercentage.toFixed(1)}% Boost in Confidence
                </p>
                <p style={{ 
                    fontSize: '1.25rem', 
                    marginTop: '1rem', 
                    color: '#666',
                    maxWidth: '80%',
                    margin: '1rem auto'
                }}>
                    Based on our data, we project a significant increase in your staff's confidence in using AI for teaching.
                </p>
            </div>
        );
    };


    return (
        <section className="course-section course-resources-section">
            <h2 className="course-sub-title">🏫📊 AI Impact Calculator for School Administrators</h2>
            <div style={{
                backgroundColor: 'white',
                color: '#333',
                padding: '2rem',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                fontSize: '1.5rem',
            }}>
                <p style={{ marginBottom: '2rem', fontSize: '1.6rem', fontWeight: 'bold', textAlign: 'center' }}>
                    Discover the potential impact of AI training on your school:
                </p>

                <InputField
                    label="How many teachers are in your school or district?"
                    value={teacherCount}
                    onChange={(e) => setTeacherCount(e.target.value)}
                    error={errors.teacherCount}
                    errorMessage="Please enter the number of teachers."
                    type="number"
                    inputRef={teacherInputRef}
                />

                <ConfidenceInput />

                <button
                    onClick={calculateResults}
                    style={{
                        backgroundColor: '#4c49ea',
                        color: 'white',
                        padding: '1rem 2rem',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        display: 'block',
                        margin: '2rem auto',
                        transition: 'background-color 0.3s'
                    }}
                >
                    See Potential Impact
                </button>
                <hr />
                {showResults && results && (
                    <div ref={resultsRef} style={{ marginTop: '0', paddingTop: '24px' }}> {/* Add padding top */}
                        <h2 ref={resultsTitleRef} className="course-sub-title noMarginTop noBoarderBottom">Projected School-Wide Impact of AI Training</h2>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '2rem', marginBottom: '2rem' }}>
                            <StatCard
                                title="Weekly Time Saved School-Wide"
                                value={`${results.weeklyTimeSaved.toFixed(1)} hours`}
                                icon="⏱️"
                                color="#4c49ea"
                            />
                            <StatCard
                                title="Yearly Time Saved School-Wide"
                                value={`${results.yearlyTimeSaved.toFixed(0)} hours`}
                                icon="📅"
                                color="#3ecd5e"
                            />
                            <StatCard
                                title="Increase in Student Engagement While Using Technology in the Classroom"
                                value="25.2%"
                                icon="📈"
                                color="#e44002"
                            />
                            <StatCard
                                title="Boost in Meeting Needs of Diverse Learners"
                                value="23.7%"
                                icon="🎯"
                                color="#952aff"
                            />
                        </div>

                        <ConfidenceBoostVisual
                            current={results.currentConfidence}
                            improved={results.improvedConfidence}
                            boostPercentage={results.confidenceBoostPercentage}
                        />
                        <PrintButton />
                        <div style={{ textAlign: 'center' }}>
                            <p style={{ marginBottom: '1.5rem', fontSize: '1.5rem', fontWeight: 'bold' }}>
                                Click below if you want the results that are above.
                            </p>
                            <button
                                style={{
                                    backgroundColor: '#e44002',
                                    color: 'white',
                                    padding: '1rem 2rem',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    transition: 'background-color 0.3s'
                                }}
                            >
                                Get Gruvy Education's AI Training
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default AICalculator;