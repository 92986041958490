import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/NewLibrary.png'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import NavNDCELprincipals from './NavNDCELprincipals';
import NavOfficeStaff from './NavOfficeStaff';
import Guide from '../images/Guide.png';
import Quill from '../images/Quil.jpg'
import Tool from '../images/toolsIcon.png'
import SchoolAI from '../images/SchoolAI.jpg'
import AIEDU from '../images/AIEDU.jpg'
import Newsletter from '../images/newsletter.png'
import Grammarly from '../images/Grammarly.png'
import OtterAI from '../images/OtterAI.jpg'

const OfficeStaff = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    return (

        <body>
            <div className="holder">
                <NavOfficeStaff module="home" />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Office Staff AI Training</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="resources" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">📦💡</span> Resources</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">🛠️📘</span> Hands-On Application Modules</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📞✉️</span> Contact Me</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section name='resources' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📦💡 Resources</h2>
                            <div className="tool-grid">
                                <a href="https://app.magicschool.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Magic School</h3>
                                    <p className="tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>
                                <a href="https://chat.openai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={ChatGPT} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT</h3>
                                    <p className="tool-text">ChatGPT direct link.</p>
                                </a>
                                <a href="https://gruvyeducation.com/static/media/A%20Schools%20Guide%20to%20Student%20Data%20Privacy%20With%20AI.baac2c59cfae4f9dcc92.pdf" target="_blank" class="tool-card">
                                    <div class="tool-icon">
                                        <img src={Guide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 class="tool-title">Gruvy Education's Guide to Data Privacy With AI</h3>
                                    <p class="tool-text">A school's guide to data privacy with AI.</p>
                                </a>
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT Prompt Library</h3>
                                    <p className="tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>

                                <a href="https://www.grammarly.com/browser/chrome" target="_blank" class="tool-card">
                                    <div class="tool-icon">
                                        <img src={Grammarly} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 class="tool-title">Grammarly for Chrome</h3>
                                    <p class="tool-text">Finish work faster with the Grammarly for Chrome extension, providing top AI writing assistance wherever you work online.</p>
                                </a>
                                <a href="https://otter.ai/" target="_blank" class="tool-card">
                                    <div class="tool-icon">
                                        <img src={OtterAI} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 class="tool-title">Otter.ai</h3>
                                    <p class="tool-text">Never take meeting notes again. Get transcripts, automated summaries, action items, and chat with Otter to get answers from your meetings.</p>
                                </a>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeItbaS5w-zvFLvM0lCIHCxsOpraOr3_fKRbsKRcGOV08L_kg/viewform?usp=sf_link" target="_blank" class="tool-card">
                                    <div class="tool-icon">
                                        <img src={Form} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 class="tool-title">End of Training Feedback Form</h3>
                                    <p class="tool-text">Thank you for your feedback.</p>
                                </a>
                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🛠️📘 Hands-On Application Modules</h2>
                            <div className="tool-grid">
                                <Link to="/officestaff/communication" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Communications} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Communication</h3>
                                </Link>
                                <Link to="/officestaff/contentcreation" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Content Creation</h3>
                                </Link>
                                <Link to="/officestaff/data" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Data and Organization</h3>
                                </Link>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default OfficeStaff;
