import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import Copiolet from '../images/copiolet.png'
import NavSCSC from './NavSCSC';
import GoogleSheetIcon from '../images/GoogleSheet.png'

const SCSCMiscellaneousNeeds = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }


    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };
        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    const prompts1 = [
        {
            id: 'student-contract',
            directions: "Copy and paste the prompt below into ChatGPT to generate a student contract focused on [Contract Type, e.g., attendance, phone, behavior]. Customize the contract by including specific expectations, consequences, and any positive reinforcement strategies. Ensure the contract is clear, concise, and accessible for students to understand.",
            text: `Act as a school principal. Draft a student-friendly contract for [Contract Type] that's easy for students to understand and follow. Include clear expectations, consequences for non-compliance, and positive reinforcement techniques to motivate adherence. Make sure the contract is straightforward and engaging for students.`.split('\n')
        },
    ];

    const prompts2 = [
        {
            id: 'trauma-support',
            directions: "Copy and paste the prompt below into ChatGPT to generate thoughtful answers to difficult questions surrounding the topic of when a student passes away. Focus on supporting students, staff, and families with a trauma-informed approach. Include potential questions that may arise and offer compassionate, supportive responses.",
            text: `Act as a school principal and generate thoughtful responses to difficult questions about supporting the school community after the loss of a student. Use a trauma-informed approach to provide guidance for students, staff, and families. Include sample questions and answers, and consider additional questions that may not be immediately obvious.`.split('\n')
        },
        {
            id: 'supportingStaff',
            directions: "Insert this prompt to outline approaches for providing support to school staff as they navigate their own grief while supporting their students. Include tips on self-care, professional development opportunities in grief counseling, and creating a culture of mutual support.",
            text: "Create a guide for school principals on supporting staff after a student's death, addressing the dual challenge of managing personal grief and professional responsibilities. Suggest self-care strategies, professional resources for dealing with trauma, and ways to foster a supportive school culture that encourages open communication and collective healing.".split('\n')
        },
        {
            id: 'engagingFamilies',
            directions: "Use this prompt to describe how school principals can effectively engage with and support families of the deceased student and the wider school community. Focus on sensitive communication, offering resources, and organizing communal support activities.",
            text: "Detail methods for school principals to engage and support families affected by a student's death, with an emphasis on compassionate communication, providing access to grief counseling, and facilitating community support mechanisms. Highlight the importance of personalizing support to meet the diverse needs of grieving families while maintaining a strong sense of community within the school.".split('\n')
        }
    ];

    const prompts3 = [
        {
            id: 'checklist-framework',
            directions: "Copy and paste the prompt below into ChatGPT to generate a checklist tailored to specific roles or situations within a school setting. Specify the type of checklist needed, such as for IEP meeting preparation or a safety/crisis plan for a specific student behavior.",
            text: `Act as a school principal. Create a checklist for [Checklist Type, e.g., an LEA representative in IEP meetings, a safety/crisis plan for a student]. Include key actions, considerations, and documentation required. Ensure the checklist is practical and easy to use, tailored to the specific needs of the role or situation.`.split('\n')
        },
    ];


    const prompts4 = [
        {
            id: 'observation-schedule',
            directions: "Copy and paste the prompt below into ChatGPT to develop an observation schedule for school administrators. Given the number of certified teachers and the instructional days available, create a feasible schedule for administrators to observe each teacher at least twice within the school year.",
            text: `Act as a school principal. Develop an observation schedule for administrators to observe 45 certified teachers at least twice during the 175 instructional days available this year. Consider workload, administrative duties, and the need for meaningful feedback in your scheduling. Provide a structured and balanced plan.`.split('\n')
        },
    ];

    const prompts13 = [
        {
            id: 'staff-meeting-agenda',
            directions: "Copy and paste the prompt below into ChatGPT to prepare a staff meeting agenda. Adjust the placeholders in square brackets to fit the specific context.",
            text: `Act as a [role e.g., principal]. You're preparing the agenda for the next staff meeting scheduled for [Meeting Date]. Draft an agenda that includes time slots for key areas such as [Topics]. Also, allocate a brief period for a Q&A session. Ensure the agenda is designed to fit within [Meeting Duration].`.split('\n')
        }
    ];

    const prompts14 = [
        {
            id: 'disciplinary-measures-strategy',
            directions: "Copy and paste the prompt below into ChatGPT to strategize disciplinary measures for specific situations. Adjust the placeholders in square brackets to fit the specific context.",
            text: `As a principal, you're facing a disciplinary issue involving [Involved Parties] related to [Specific Situation]. Formulate a strategy that outlines the disciplinary measures to be taken, considering any prior incidents, school policies, and legal guidelines. Make sure the strategy aligns with [School Policies] and includes a plan for communication with all involved parties. If necessary, suggest possible corrective actions or educational interventions that can be part of the resolution. List unique ways I can motivate the student to not do this again.`.split('\n')
        }
    ];

    const prompts15 = [
        {
            id: 'data-analysis',
            directions: "Copy and paste the prompt below into ChatGPT to analyze data. Adjust the placeholders in square brackets to fit the specific context.",
            text: `Analyze the attendance data in the attached Excel file, creating visualizations of the attendance rate trends and the distribution of absence reasons over time. Then, give me ideas on how I can improve attendance rates.`.split('\n')
        }
    ];

    const prompts16 = [
        {
            id: 'rfbAd',
            directions: "Copy and paste the prompt below into ChatGPT to write a Request for Bid Advertisement.",
            text: "Act as if you are an official at the ND Department of Public Instruction. You are seeking to contract snow removal services. Write a Request for Bid Advertisement with as much detail as you can, I will fill out the rest."
        },
        {
            id: 'scoringRubric',
            directions: "Copy and paste the prompt below into ChatGPT to create an objective rubric for scoring the bid proposals.",
            text: "Design an objective scoring rubric for the ND Department of Public Instruction to evaluate and compare bid proposals for snow removal services. The rubric should encompass key criteria such as service reliability, cost-effectiveness, company experience, and response times."
        },
        {
            id: 'responseLetters',
            directions: "Copy and paste the prompt below into ChatGPT to write letters to the RFP respondents.",
            text: "Please write letters to the RFP Respondents. Write one letter we will use for the award recipient and another letter for those we are not selecting for snow removal service."
        },
        {
            id: 'schoolBoardMemo',
            directions: "Copy and paste the prompt below into ChatGPT to write a memo to the school board.",
            text: "Write a memo to the board that outlines the snow removal services bid process and recommends that they award the bid to the recipient I selected. Explain the objectivity in this process and detail the steps."
        },
        {
            id: 'contractDraft',
            directions: "Copy and paste the prompt below into ChatGPT to draft a contract for the snow removal services.",
            text: "Prepare a contract template for ND DPI to use with 'XYZ Snow Removal,' detailing service expectations, payment terms, and duration, ensuring clear expectations and accountability."
        }
    ];

    const prompts11 = [
        {
            id: 'rfbAd',
            directions: "Copy and paste the prompt below into ChatGPT to write a Request for Bid Advertisement.",
            text: "Act as if you are the Superintendent of a school District in North Dakota and seeking to contract snow removal services for the four facilities in your district. Write a Request for Bid Advertisement with as much detail as you can, I will fill out the rest."
        },
        {
            id: 'scoringRubric',
            directions: "Copy and paste the prompt below into ChatGPT to create an objective rubric for scoring the bid proposals.",
            text: "Can you please create an objective rubric we can use to score the bid proposals for snow removal services?"
        },
        {
            id: 'responseLetters',
            directions: "Copy and paste the prompt below into ChatGPT to write letters to the RFP respondents.",
            text: "Please write letters to the RFP Respondents. Write one letter we will use for the award recipient and another letter for those we are not selecting for snow removal service."
        },
        {
            id: 'schoolBoardMemo',
            directions: "Copy and paste the prompt below into ChatGPT to write a memo to the school board.",
            text: "Write a memo to my school board that outlines the snow removal services bid process and recommends that they award the bid to the recipient I selected. Explain the objectivity in this process and detail the steps."
        },
        {
            id: 'contractDraft',
            directions: "Copy and paste the prompt below into ChatGPT to draft a contract for the snow removal services.",
            text: "My district is NDCEL Public Schools and we awarded the business to Copas Snow Removal. Please draft a contract for utilizing this service."
        }
    ];

    const prompts12 = [
        {
            id: 'rfp',
            directions: "Copy and paste the prompt below into ChatGPT to write a Request for Proposal. Fill in the variables in square brackets with specific details relevant to your school and project.",
            text: `As a school administrator at [School Name], I need to draft a Request for Proposal (RFP) for [Project or Service Description]. The RFP should be detailed and structured to attract qualified vendors or service providers. Please include the following sections in the RFP:\n\nIntroduction and Overview:\n- Briefly introduce [School Name] and the purpose of the RFP.\n- Overview of the project or service needed.\n\nScope of Work:\n- Detailed description of the project or services required.\n- Specific objectives and expected outcomes.\n- Any necessary timelines or milestones.\n\nVendor Qualifications and Requirements:\n- List the essential qualifications and experience required from vendors.\n- Any specific certifications or credentials needed.\n\nProposal Submission Guidelines:\n- Instructions for how and where to submit proposals.\n- Deadline for proposal submissions.\n- Required format and contents of the proposal (e.g., technical approach, budget breakdown, project timeline).\n\nEvaluation Criteria:\n- Criteria and process for how proposals will be evaluated.\n- Weightage given to different aspects of the proposal.\n\nBudget and Funding:\n- Outline available budget or budget constraints.\n- Information on any funding sources or grants.\n\nPoint of Contact:\n- Provide contact information for inquiries and proposal submissions.\n\nTerms and Conditions:\n- Legal and procedural terms governing the RFP process and subsequent agreements.\n\nEnsure the RFP is clear, comprehensive, and professionally formatted, with formal and precise language tailored to attract reputable and capable vendors.`.split('\n')
        },
    ];

    const prompts33 = [
        {
            id: 'mou',
            directions: "Copy and paste the prompt below into ChatGPT to draft a Memorandum of Understanding. Fill in the variables in square brackets with specific details relevant to your school and the partner organization.",
            text: `I am a school administrator working on drafting a Memorandum of Understanding (MOU) for a collaboration between our school, [School Name], and another organization, [Partner Organization Name]. The MOU should address the following key points:\n\nPurpose of the Partnership:\n[ Briefly describe the main objectives and goals of this partnership.]\n\nRoles and Responsibilities:\nFor [School Name]:\n- Outline specific responsibilities and commitments.\nFor [Partner Organization Name]:\n- Detail their expected contributions and roles.\n\nDuration of the Agreement:\n- State the start and end dates of the MOU, including any conditions for renewal.\n\nFinancial Arrangements (if applicable):\n- Describe any financial commitments or exchanges, including funding sources and budget allocations.\n\nConfidentiality and Data Sharing:\n- Clarify any data sharing agreements and confidentiality protocols, particularly concerning student information.\n\nDispute Resolution Mechanism:\n- Provide a brief outline of how disagreements or misunderstandings will be resolved.\n\nTermination Clause:\n- Include terms under which the MOU can be terminated by either party.\n\nSignatory Authority:\n- Specify who has the authority to sign the MOU on behalf of each organization.\n\nHelp me draft an MOU incorporating these points. The language should be formal yet accessible, ensuring clarity and mutual understanding. Additionally, I'd appreciate it if you could format the MOU in a standard business letter format, ready for review and signatures.`.split('\n')
        },
    ];


    return (

        <body>
            <div className="holder">
                <NavSCSC module="miscellaneous" />
                <header className="header-purple4 shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Using AI for Miscellaneous Needs</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <Link to="/scsc" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">⬅️🏡</span> Back to Home</div>
                            </Link>
                            <ScrollLink activeClass="active" to="william" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">🛠️🎁</span> Miscellaneous Needs Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">🛠️📘</span> Hands-On Application Modules</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section id='william' className="course-section course-activities-section">
                            <h2 className="course-sub-title">🛠️🎁 Miscellaneous Needs Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a>
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                    <a
                                        href={`#3`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        3
                                    </a>
                                    <a
                                        href={`#4`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        4
                                    </a>
                                    <a
                                        href={`#5`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        5
                                    </a>
                                    <a
                                        href={`#6`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        6
                                    </a>
                                    <a
                                        href={`#7`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        7
                                    </a>
                                    <a
                                        href={`#8`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        8
                                    </a>
                                    <a
                                        href={`#9`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        9
                                    </a>
                                    <a
                                        href={`#10`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        10
                                    </a>
                                    <a
                                        href={`#11`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        11
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Setting up your Magic School Account
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Click on the link in the 'Links Needed for Activity' section above. Then, click on 'Sign in with Google' or 'Create an account'.</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number ">After setting up your account, find the following tools within Magic School: Teacher Observations Tool, IEP Generator, BIP Generator.</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Streamlining Data Analysis with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/spreadsheets/d/1ct0p2rL3TA2WjUiVzidERaczheIoW9TBz-mHSKh5H18/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={GoogleSheetIcon} alt="Google Sheets" />
                                                    <span className="link-label">Google Sheet</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="15-1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open the Google Sheet found in the "Links Needed for Activity" section. Click on "File" in the top left corner, hover over "Download," and then click on "Microsoft Excel (.xlsx)."</p>
                                        </div>
                                        <div key="15-2" className="activity-question-container">
                                            <p className="poll-question question-number ">Open ChatGPT using the link in the "Links Needed for Activity" section. Inside of ChatGPT, click on the paper clip button in the prompt bar, select "Upload from computer," and choose your downloaded Excel file. Send the following prompt to ChatGPT with the attached file:</p>
                                            <CopyToClipboard textToCopy={prompts15[0].text.join('\n')} displayText={prompts15[0].text} />
                                        </div>
                                    </form>
                                </div>
                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Leveraging ChatGPT to Enhance Snow Removal Request for Bids (RFB) Campaign
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts11.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="4" id="4" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Drafting a Request for Proposal
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/1jN83gDImB5jHeiftNDQR1EPfp5uJJ06ZO7MHJ-YuBkU/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="ChatGPT" />
                                                    <span className="link-label">Prompt Doc</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts12.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                                <div key="5" id="5" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Drafting a Memorandum of Understanding
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/1NyA6ONZQupD-brWGx2bsYOalw4xsKLf7GEDtkTjpR4w/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="ChatGPT" />
                                                    <span className="link-label">Prompt Doc</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts33.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                                
                                <div key="6" id="6" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Crafting Effective Student Contracts with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open ChatGPT using the link provided in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts1.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="7" id="7" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Providing Trauma Support with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Navigate to ChatGPT using the link in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts2.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="8" id="8" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Developing Checklists for Educational Settings with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Go to ChatGPT via the link in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts3.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="9" id="9" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Crafting Observation Schedules with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Use the link in the "Links Needed for Activity" section to access ChatGPT.</p>
                                        </div>
                                        {prompts4.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="10" id="10" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Preparing Staff Meeting Agendas
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="13-1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts13.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="11" id="11" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Strategizing Disciplinary Measures for Specific Situations
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="14-1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts14.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🛠️📘 Hands-On Application Modules</h2>
                            <div className="tool-grid">
                                <Link to="/scsc/communication" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Communications} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Communication</h3>
                                    <p className="tool-text">Emails to parents, Social posts, Applying for grants</p>
                                </Link>
                                <Link to="/scsc/humancapitol" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Human Capital</h3>
                                    <p className="tool-text">Evaluation of employees, Documenting employees, Coaching employees.</p>
                                </Link>
                                <Link to="/scsc/miscellaneousneeds" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Miscellaneous Needs</h3>
                                    <p className="tool-text">Drafting student contracts, Crisis support, Checklist frameworks, Observation scheduling.</p>
                                </Link>
                                <Link to="/scsc/teachingandlearning" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={TeachingLearning} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Teaching and Learning</h3>
                                    <p className="tool-text">Leave equipted to bring AI tools to your staff.</p>
                                </Link>
                            </div>
                        </section>
                        
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default SCSCMiscellaneousNeeds;
