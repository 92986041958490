import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/NewLibrary.png'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import NavNDCELprincipals from './NavNDCELprincipals';
import NavSourcewell from './NavSourcewell';
import Guide from '../images/Guide.png';
import Quill from '../images/Quil.jpg'
import Tool from '../images/toolsIcon.png'
import SchoolAI from '../images/SchoolAI.jpg'
import AIEDU from '../images/AIEDU.jpg'
import Newsletter from '../images/newsletter.png'

const Sourcewell = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    return (

        <body>
            <div className="holder">
                <NavSourcewell module="home" />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Sourcewell Educator Summit</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="resources" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">📦💡</span> All Resources</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📚💬</span> Breakout Sessions</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📞✉️</span> Contact Me</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section name='resources' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📦💡 All Resources</h2>
                            <div className="tool-grid">
                                <a href="https://chat.openai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={ChatGPT} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT</h3>
                                    <p className="tool-text">ChatGPT direct link.</p>
                                </a>
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT Prompt Library</h3>
                                    <p className="tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                                <a href="https://gruvyeducation.com/aitools" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Tool} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Educator AI Tools Library</h3>
                                    <p className="tool-text">Collection of useful AI tools for educators</p>
                                </a>
                                <a href="https://app.magicschool.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Magic School</h3>
                                    <p className="tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>
                                <a href="https://schoolai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={SchoolAI} alt="SchoolAI" />
                                    </div>
                                    <h3 className="tool-title">SchoolAI</h3> 
                                    <p class="tool-text">Designed to safely integrate AI into the classroom for K-12 students, with built-in monitoring and controls for teachers.</p>
                                </a>
                                <a href="https://app.diffit.me/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Diffit} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Diffit</h3>
                                    <p className="tool-text">Generate “just right” instructional materials.</p>
                                </a>
                                <a href="https://quillbot.com/ai-content-detector" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Quill} alt="QuilBot" />
                                    </div>
                                    <h3 className="tool-title">QuillBot AI Detector</h3>
                                    <p className="tool-text">Free AI detector.</p>
                                </a>
                                <a href="https://gruvyeducation.com/static/media/A%20Schools%20Guide%20to%20Student%20Data%20Privacy%20With%20AI.baac2c59cfae4f9dcc92.pdf" target="_blank" class="tool-card">
                                    <div class="tool-icon">
                                        <img src={Guide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 class="tool-title">Gruvy Education's Guide to Data Privacy With AI</h3>
                                    <p class="tool-text">A school's guide to data privacy with AI.</p>
                                </a>
                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📚💬 Breakout Sessions</h2>
                            <div className="tool-grid">
                                <Link to="/sourcewell/breakout1" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Communications} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Breakout #1</h3>
                                    <p className="tool-text">Maximizing the Potential of AI to Enhance Efficiency as an Educator</p>
                                </Link>
                                <Link to="/sourcewell/breakout2" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Breakout #2</h3>
                                    <p className="tool-text">Ethical Integration of AI in the Classroom</p>
                                </Link>
                                <Link to="/sourcewell/breakout3" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Breakout #3</h3>
                                    <p className="tool-text">Crafting Assignments to Promote Critical Thinking and Discourage AI-Assisted Cheating</p>
                                </Link>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default Sourcewell;
