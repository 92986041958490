import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import ChatGPT from "../images/chatGPTicon.png"
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Doc from '../images/doc.png'
import NavVA from './NavVA';
import LastPass from '../images/LastPass.png'
import Gmail from '../images/Gmail.png'
import Calendar from '../images/Calendar.png'
import Hubspot from '../images/Hubspot.png'
import Trello from '../images/Trello.png'
import Folder from '../images/Folder.png'
import Stripe from '../images/Stripe.png'
import DocuSign from '../images/DocuSign.png'
import Drive from '../images/Drive.png'
import Instagram from '../images/Instagram.png'
import GoogleChat from '../images/GoogleChat.png'
import BeeHive from '../images/Beehiiv.jpg'

const MASSPSW = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    return (

        <body>
            <div className="holder">
                <NavVA module="home" />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Virtual Assistant Portal</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="links" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">🔗⭐</span> Important Links</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="tutorials" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📚🛠️</span> Resources and Tutorials</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="social" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📱🌐</span> Social Media Links</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="contacts" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📞✉️</span> Contact William</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section name='links' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🔗⭐ Important Links</h2>
                            <div className="tool-grid">
                                <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Gmail} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Gmail</h3>
                                    <p className="tool-text">Email Inbox.</p>
                                </a>
                                <a href="https://app.beehiiv.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BeeHive} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Beehiiv Newsletter Platform</h3>
                                    <p className="tool-text">Our newsletter platfrom.</p>
                                </a>
                                <a href="https://app.hubspot.com/contacts/46602990/objects/0-3/views/all/board" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Hubspot} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">HubSpot</h3>
                                    <p className="tool-text">William's Calendar.</p>
                                </a>
                                <a href="https://app.hubspot.com/contacts/46602990/objects/0-3/views/all/board" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Hubspot} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">HubSpot</h3>
                                    <p className="tool-text">William's Calendar.</p>
                                </a>
                                <a href="https://trello.com/invite/b/r9fEquaA/ATTIfdb873e0de0390ab465a6f4e16d7263e98EB7A93/virtual-assistant-project-board" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Trello} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Trello</h3>
                                    <p className="tool-text">Project Managment.</p>
                                </a>
                                <a href="https://dashboard.stripe.com/dashboard" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Stripe} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Stripe</h3>
                                    <p className="tool-text">Invoicing Software.</p>
                                </a>
                                <a href="https://account.docusign.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={DocuSign} alt="QuilBot" />
                                    </div>
                                    <h3 className="tool-title">DocuSign</h3>
                                    <p className="tool-text">Contracts Software.</p>
                                </a>
                                <a href="https://drive.google.com/drive/folders/1aejbX4-Z5ohOa-6WiOMLHjn3sRBvCttO?usp=sharing" target="_blank" class="tool-card">
                                    <div class="tool-icon">
                                        <img src={Drive} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 class="tool-title">Google Drive</h3>
                                    <p class="tool-text">Main Google Drive folder.</p>
                                </a>
                                <a href="https://drive.google.com/drive/folders/1MiHDACEdHVx7RApSY88rjC3fT3wQ7DAC?usp=drive_link" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Folder} alt="SchoolAI" />
                                    </div>
                                    <h3 className="tool-title">Daily Reports</h3>
                                    <p class="tool-text">Link to daily reports foulder.</p>
                                </a>
                                <a href="https://lastpass.com/vault/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={LastPass} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">LastPass</h3>
                                    <p className="tool-text">Password Managment.</p>
                                </a>
                                <a href="https://chatgpt.com/" target="_blank" class="tool-card">
                                    <div class="tool-icon">
                                        <img src={ChatGPT} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 class="tool-title">ChatGPT</h3>
                                    <p class="tool-text">ChatGPT Direct Link.</p>
                                </a>
                            </div>
                        </section>
                        <section name='tutorials' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📚🛠️ Resources and Tutorials</h2>
                            <div className="tool-grid">
                                <a href="https://drive.google.com/drive/folders/1xCVycMMPVktl69Z0hYWo4_wHi7MPwzNs?usp=sharing" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Folder} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">SOPs</h3>
                                    <p className="tool-text">SOPs Google Drive folder.</p>
                                </a>
                            </div>
                        </section>
                        <section name='social' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📱🌐 Social Media Links</h2>
                            <div className="tool-grid">
                                <a href="https://www.linkedin.com/in/william-grube-16200a177/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={LinkedIn} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">LinkedIn</h3>
                                    <p className="tool-text">LinkedIn direct link.</p>
                                </a>
                                <a href="https://x.com/home" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={X} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">X</h3>
                                    <p className="tool-text">X direct link.</p>
                                </a>
                                <a href="https://www.youtube.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">YouTube</h3>
                                    <p className="tool-text">YouTube direct link.</p>
                                </a>
                                <a href="https://www.facebook.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Facebook} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">Facebook</h3>
                                    <p className="tool-text">Facebook direct link.</p>
                                </a>
                                <a href="https://www.instagram.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Instagram} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">Instagram</h3>
                                    <p className="tool-text">Instagram direct link.</p>
                                </a>
                            </div>
                        </section>
                        <section name='contacts' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📞✉️ Contact William</h2>
                            <div className="tool-grid">
                            <a href="https://chat.google.com/room/AAAAWMr0y3g?cls=7" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={GoogleChat} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">Google Chat</h3>
                                    <p className="tool-text">Google Chat direct link.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1y7FZQ95uFkxweiM1xgySg7FNazZvzNjIRr-WuQhgGhY/edit?usp=sharing" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Doc} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">Contact Information</h3>
                                    <p className="tool-text">Document containing contact information.</p>
                                </a>
                            </div>
                        </section>
                        
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default MASSPSW;
