import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Nav from './Nav'
import { useState } from 'react';
import db, { auth } from '../Firebase';
import Footer from './Footer';
import { confirmPasswordReset } from 'firebase/auth';

const ResetPassword = () => {
    const [isResetPass, setIsResetPass] = useState(null)
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const mode = urlParams.get('mode');
        if (mode === 'resetPassword') {
            setIsResetPass(true);
        } else if (mode === 'verifyEmail') {
            setIsResetPass(false);
        }
    }, []);

    const handleNewPassword = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');

        if (newPassword !== confirmPassword) {
            setError("Passwords don't match.");
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const actionCode = urlParams.get('oobCode');

        try {
            await confirmPasswordReset(auth, actionCode, newPassword);
            setMessage('Password has been successfully reset. You can now log in.');
        } catch (err) {
            setError('Error in resetting password: ' + err.message);
        }
    };

    return (
        <body>
            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <section className="resetPasswordContainer">
                    {isResetPass ?
                        <>
                            <div className="title">Reset Password</div>
                            <div className="resetPasswordCard">
                                {message ? (
                                    <>
                                        <p className="success-message">{message}</p>
                                        <a href="https://www.gruvyeducation.com/training" className="training-link">Click Here To Go back to Training Portal</a>
                                    </>
                                ) : (
                                    <>
                                        <div className="formGroup">
                                            <label className='auth-label'>Enter New Password:</label>
                                            <input className="inputField" placeholder='Enter New Password' required type="password" name="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} /><br />
                                        </div>
                                        <div className="formGroup">
                                            <label className='auth-label'>Enter New Password Again:</label>
                                            <input className="inputField" placeholder='Re-enter New Password' required type="password" name="confirmPassword" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} /><br />
                                        </div>
                                        {error && <p className="error-message">{error}</p>}

                                        <button onClick={handleNewPassword} className="auth-btn">Reset Password</button>
                                    </>
                                )}
                            </div>
                        </>
                        :
                        <>
                            <div className="title">Welcome to Gruvy Education's AI in Education Training!</div>
                            <div className="resetPasswordCard">
                                <p className="success-message">Your Email Has Been Verified.</p>
                                <a href="https://www.gruvyeducation.com/training" className="training-link">Click Here To Go back to Training Portal</a>
                            </div>
                        </>
                    }

                </section>
                <Footer />
            </div>
        </body>
    );

}

export default ResetPassword;
