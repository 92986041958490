import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import Copiolet from '../images/copiolet.png'
import NavOfficeStaff from './NavOfficeStaff';
import GoogleSheetIcon from '../images/GoogleSheet.png'

const OfficeStaffData = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }


    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };
        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    const prompts1 = [
        {
            id: 'create-spreadsheet-template',
            directions: "Copy and paste the prompt below into ChatGPT to generate an Excel spreadsheet template. Specify the purpose of the spreadsheet and any key sections or data fields you need. This will help create a well-organized and functional template.",
            text: `Act as a [Position e.g., School Administrative Assistant, Office Manager]. You need to create an Excel spreadsheet template for [specific purpose, e.g., attendance tracking, budget planning, event scheduling]. The template should be well-organized and include the following sections or data fields: [list the key sections, columns, or data fields you want in the template]. Ensure the template is clear and easy to use, with any necessary formulas or formatting applied.`.split('\n')
        }
    ];

    const prompts2 = [
        {
            id: 'data-analysis',
            directions: "Copy and paste the prompt below into ChatGPT to analyze data. Adjust the placeholders in square brackets to fit the specific context.",
            text: `Analyze the attendance data in the attached Excel file, creating visualizations of the attendance rate trends and the distribution of absence reasons over time. Then, give me ideas on how I can improve attendance rates.`.split('\n')
        }
    ];

    const prompts3 = [
        {
            id: 'summarize-meeting-notes',
            directions: "To summarize meeting notes, you can either attach a file containing the notes by clicking the paper clip icon on the left side of the ChatGPT prompt bar, or you can paste the notes directly into the prompt. Copy and paste the prompt below into ChatGPT to generate a clear and concise summary of the meeting notes.",
            text: `Act as a [Position e.g., School Administrative Assistant, Office Manager]. You need to summarize the following meeting notes. The summary should be clear, concise, and capture the key points, decisions, and any action items. Make sure not to miss anything, analyze the notes from start to end. Focus on: [any specific instructions or focus areas for the summary]. Meeting notes: [If you’re pasting the notes, include them here, if not delete this].`.split('\n')
        }
    ];


    const prompts4 = [
        {
            id: 'manage-timesheets',
            directions: "Copy and paste the prompt below into ChatGPT to manage timesheets effectively. This prompt will help you create a timesheet template, review and approve submitted timesheets, calculate total hours and overtime, and address any discrepancies. Specify the tasks you need assistance with.",
            text: `Act as a School Administrative Assistant. You need to manage timesheets for school staff. This involves the following tasks: creating a simple and effective timesheet template, reviewing and approving submitted timesheets, calculating total hours worked, including overtime, and handling any discrepancies that arise. [Specify the task you need help with: e.g.,  create a timesheet template, calculate total hours, resolve discrepancies]. Here are the specific details and requirements: [specific instructions or details].`.split('\n')
        }
    ];

    const prompts5 = [
        {
            id: 'create-timeline',
            directions: "Copy and paste the prompt below into ChatGPT to create a timeline for a specific project or event. Specify the purpose of the timeline, key milestones, and any deadlines. This will help generate a clear and organized timeline that can be easily followed.",
            text: `Act as a [Position e.g., School Administrative Assistant, Office Manager]. You need to create a timeline for [specific project or event, e.g., school event planning, academic calendar, project deadline tracking]. The timeline should include key milestones, deadlines, and any important tasks or events. Make sure the timeline is clear, organized, and easy to follow. Include the following specific details or milestones: [list the key milestones, dates, and any other details you need in the timeline].`.split('\n')
        }
    ];

    const prompts6 = [
        {
            id: 'create-agenda',
            directions: "Copy and paste the prompt below into ChatGPT to create an agenda for a meeting or event. Specify the type of meeting or event, participants, and key topics. This will help generate a clear and organized agenda that covers all necessary points.",
            text: `Act as a [Position e.g., School Administrative Assistant, Office Manager]. You need to create an agenda for [specific meeting or event, e.g., staff meeting, parent-teacher conference, school board meeting]. The agenda should include the meeting date, time, location, and a list of key topics or items to be discussed. Ensure the agenda is clear, organized, and easy to follow. Include the following specific details or topics: [list the key topics, items, and any other details you need in the agenda].`.split('\n')
        }
    ];

    const prompts7 = [
        {
            id: 'nutrition-tidbits-website',
            directions: "Copy and paste the prompt below into ChatGPT to generate nutrition tidbits for the school website. Specify the type of information or focus areas you want to include. This will help create engaging and informative content that is easy for students and parents to understand.",
            text: `Act as a [Position e.g., School Administrative Assistant, Office Manager]. You need to generate nutrition tidbits for the school website. The content should be engaging, informative, and easy to understand for both students and parents. Include tips, facts, or advice on [specific focus areas, e.g., healthy eating, balanced diets, snack ideas]. Ensure the information is clear, practical, and relevant to the school community: [list any specific details or topics you want to include in the tidbits].`.split('\n')
        }
    ];

    return (

        <body>
            <div className="holder">
                <NavOfficeStaff module="teach" />
                <header className="header-purple4 shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Leveraging AI for Data Management and Organization</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <Link to="/officestaff" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">⬅️🏡</span> Back to Home</div>
                            </Link>
                            <ScrollLink activeClass="active" to="william" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">📊📁</span> Data and Organization Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">🛠️📘</span> Hands-On Application Modules</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section id='william' className="course-section course-activities-section">
                            <h2 className="course-sub-title">📊📁 Data and Organization Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a>
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                    <a
                                        href={`#3`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        3
                                    </a>
                                    <a
                                        href={`#4`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        4
                                    </a>
                                    <a
                                        href={`#5`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        5
                                    </a>
                                    <a
                                        href={`#6`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        6
                                    </a>
                                    <a
                                        href={`#7`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        7
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Creating a Spreadsheet
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open ChatGPT using the link provided in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts1.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Streamlining Data Analysis with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/spreadsheets/d/1ct0p2rL3TA2WjUiVzidERaczheIoW9TBz-mHSKh5H18/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={GoogleSheetIcon} alt="Google Sheets" />
                                                    <span className="link-label">Google Sheet</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="15-1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open the Google Sheet found in the "Links Needed for Activity" section. Click on "File" in the top left corner, hover over "Download," and then click on "Microsoft Excel (.xlsx)."</p>
                                        </div>
                                        <div key="15-2" className="activity-question-container">
                                            <p className="poll-question question-number ">Open ChatGPT using the link in the "Links Needed for Activity" section. Inside of ChatGPT, click on the paper clip button in the prompt bar, select "Upload from computer," and choose your downloaded Excel file. Send the following prompt to ChatGPT with the attached file:</p>
                                            <CopyToClipboard textToCopy={prompts2[0].text.join('\n')} displayText={prompts2[0].text} />
                                        </div>
                                    </form>
                                </div>
                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Summarizing Meeting Notes
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Go to ChatGPT via the link in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts3.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="4" id="4" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Managing Timesheets
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Use the link in the "Links Needed for Activity" section to access ChatGPT.</p>
                                        </div>
                                        {prompts4.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="5" id="5" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Creating Timelines
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="13-1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts5.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="6" id="6" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Creating Agendas
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="14-1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts6.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="7" id="7" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Generating Nutrition Tidbits for The School Website
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts7.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🛠️📘 Hands-On Application Modules</h2>
                            <div className="tool-grid">
                                <Link to="/officestaff/communication" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Communications} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Communication</h3>
                                </Link>
                                <Link to="/officestaff/contentcreation" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Content Creation</h3>
                                </Link>
                                <Link to="/officestaff/data" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Data and Organization</h3>
                                </Link>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default OfficeStaffData;
