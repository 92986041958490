import React, { useLayoutEffect } from 'react';
import Footer from './Footer';

import emailjs from "emailjs-com"
import { useNavigate } from "react-router-dom";
import db from '../Firebase'
import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import Nav from './Nav'
import { Helmet } from 'react-helmet';


const Home = () => {
    const [schools, setSchools] = useState([])
    const schoolsRef = collection(db, "School")
    const [searchTerm, setSearchTerm] = useState('');

    // Filter schools based on search term
    const filteredSchools = schools.filter((school) => {
        return school.Name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    useEffect(() => {
        const getSchools = async () => {
            const data = await getDocs(schoolsRef)
            setSchools(data.docs.map((doc) => ({
                ...doc.data(), id: doc.id
            })))
        }
        getSchools()
    }, [])

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const openNavOptions = () => {
        document.getElementById("barsBtn").style.display = "none"
        document.getElementById("navPopup").style.display = "flex"
    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
    }

    const scrollTo = (string, num) => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }
    const toTraining = (url, id) => {
        localStorage.setItem("schoolid", id)
        navigate(url)
    }
    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AI in Education Training | Gruvy Education</title>
                <link href="https://gruvyeducation.com/training" />
                <meta
                    name="keywords"
                    content="AI in Education, AI Education Training, Gruvy Education, AI Training, AI Teaching, AI in Schools"
                />
                <meta
                    name="description"
                    content="We provide comprehensive AI in Education training to empower educators and enhance teaching methods in the 21st century. Learn more about our training programs at Gruvy Education."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="AI in Education Training | Gruvy Education" />
                <meta property="og:description" content="We provide comprehensive AI in Education training to empower educators and enhance teaching methods in the 21st century. Learn more about our training programs at Gruvy Education." />
                <meta property="og:url" content="https://gruvyeducation.com/training" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>

                <section id='proposals' className="section-one bg-ghost-white">
                    <div className="title">Select Your School</div>

                    {/* Add Search Bar */}
                    <div className="search-container">
                        <input
                            type="text"
                            id="school-search"
                            className="search-input"
                            placeholder="Search for your school..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    <div className="container section-one-content">
                        <div className="grid">
                            {filteredSchools
                                .filter(school => school.trainingType !== "positionSpecific")
                                .map((school) => {
                                    return (
                                        <a
                                            key={school.id} // Add a unique key prop for each item in the list
                                            style={{ cursor: "pointer" }}
                                            onClick={() => toTraining(`/training/${school.id}`, school.id)}
                                            className="grid-item text-center bg-white"
                                        >
                                            <img src={`${school.icon}`} alt={`${school.Name} icon`} /> {/* Added alt attribute for accessibility */}
                                            <h3>{school.Name}</h3>
                                            <p className="text">AI in Education Training</p>
                                        </a>
                                    );
                                })}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </body>
    );
}

export default Home;
