import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import NavSourcewell from './NavSourcewell';
import Gamma from '../images/Gamma.png'

const SourcewellBreakout1 = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }


    const prompts2 = [
        {
            id: 'social-media-post',
            directions: "After creating the worksheet, use the prompt below in ChatGPT to modify it for a student with diverse learning needs.",
            text: `As a [grade] level [subject] teacher, I need to modify the following assignment for a student who needs [specific modification, e.g. simplified language, extended time] due to [reason, e.g. learning disability, English language learner status, or IEP requirements]. Be sure the modified assignment maintains the core learning objectives and covers this state standard: [State Standard] Here is the original assignment: [paste the original assignment].`.split('\n')
        },
    ];

    const prompts8 = [
        {
            id: 'role-action-context-format',
            directions: "Use the role-action-context-format prompt design framework and experiment with it in ChatGPT. Here is an example:",
            text: `As a teacher (role), create a rubric (action). The rubric is for a project where students had to create a poster about mitosis (context). Present the rubric in a table (format).`.split('\n')
        }
    ];

    return (

        <body>
            <div className="holder">
                <NavSourcewell module="communication" />
                <header className="header-purple2 shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Maximizing AI for Efficiency as an Educator</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <Link to="/sourcewell" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">⬅️🏡</span> Back to Home</div>
                            </Link>
                            <ScrollLink activeClass="active" to="william" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">🎯🏃</span> Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📞✉️</span> Contact Me</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section id='william' className="course-section course-activities-section">
                            <h2 className="course-sub-title">🎯🏃 Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a href="#1" className="activity-nav-item redBackground">1</a>
                                    <a href="#2" className="activity-nav-item redBackground">2</a>
                                    <a href="#3" className="activity-nav-item redBackground">3</a>
                                    <a href="#4" className="activity-nav-item redBackground">4</a>
                                    <a href="#5" className="activity-nav-item redBackground">5</a>
                                    <a href="#6" className="activity-nav-item redBackground">6</a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Using Magic School to Generate a Lesson Plan
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/tools/lesson-plan-generator" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Click the link in the "Links Needed for Activity" section to open the Magic School lesson plan generator. Use this tool to generate a lesson plan.</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Magic School Tour
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/tools" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow along with the instructor to learn how to use Magic School.</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Diffit Tutorial
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.diffit.me/#topic" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Diffit} alt="Diffit" />
                                                    <span className="link-label">Diffit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Open Diffit and click on "Teachers Start Here." Then, in the top right corner, click on "Log In." Create your free account.</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number">After logging in, choose your preferred method for feeding Diffit topic-related data: "Literally Anything" for textual input, "An Article" for web links, and "Any Text or Excerpt" for PDF uploads. Click "Generate Resources," a process that will take roughly 45 seconds.</p>
                                        </div>
                                        <div key="3" className="activity-question-container">
                                            <p className="poll-question question-number">Upon generation, you'll be presented with a variety of content: a reading passage, summary, key vocabulary, multiple-choice questions, short-answer questions, and open-ended prompts. Edit, add, or remove any of these elements.</p>
                                        </div>
                                        <div key="4" className="activity-question-container">
                                            <p className="poll-question question-number">Scroll to the bottom of the page and click on "Get Student Activities." Select an activity template, then click on "Get Activity" in the bottom right corner. Choose from the options "Open Google Slides," "Download PDF," or "Download PPTX."</p>
                                        </div>
                                        <div key="5" className="activity-question-container">
                                            <p className="poll-question question-number">Reflect on the content generated by Diffit in your activity and consider how it meets your needs.</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="4" id="4" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Gamma Tutorial
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://gamma.app/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Gamma} alt="Gamma" />
                                                    <span className="link-label">Gamma</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Click the link in the "Links Needed for Activity" section to launch Gamma. Then, click on "Sign up for free" to create an account (this will involve creating a workspace by pressing on "Create Workspace" and filling out the "Tell me about yourself" form). After you have created your account, press "Generate."</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number">To generate a presentation, ensure "Presentation" is selected at the top of the page. Describe the type of presentation you would like to create in the text box provided and click on "Generate Outline." An outline will be generated. You can then modify the outline as needed. When you are finished, click on "Continue."</p>
                                        </div>
                                        <div key="3" className="activity-question-container">
                                            <p className="poll-question question-number">Now, you can select a presentation theme on the right side of your screen. Choose the theme you prefer, then click on "Generate."</p>
                                        </div>
                                        <div key="4" className="activity-question-container">
                                            <p className="poll-question question-number">You can save the generation as a PowerPoint by clicking on "Share" at the top of your screen. Then select "Export," followed by "Export to PowerPoint."</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="5" id="5" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Using ChatGPT: Role, Action, Context, Format
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">

                                        {prompts8.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="6" id="6" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Using AI for Assignment Modification
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                            <a className="course-link-icon" href="https://app.magicschool.ai/tools/worksheet" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Click the Magic School link in the "Links Needed for Activity" section to open the Magic School worksheet generator. Use this tool to generate a worksheet.</p>
                                        </div>
                                        {prompts2.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📚💬 Breakout Sessions</h2>
                            <div className="tool-grid">
                                <Link to="/sourcewell/breakout1" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Communications} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Breakout #1</h3>
                                    <p className="tool-text">Maximizing the Potential of AI to Enhance Efficiency as an Educator</p>
                                </Link>
                                <Link to="/sourcewell/breakout2" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Breakout #2</h3>
                                    <p className="tool-text">Ethical Integration of AI in the Classroom</p>
                                </Link>
                                <Link to="/sourcewell/breakout3" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Breakout #3</h3>
                                    <p className="tool-text">Crafting Assignments to Promote Critical Thinking and Discourage AI-Assisted Cheating</p>
                                </Link>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default SourcewellBreakout1;
