import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Diffit from '../../images/diffit.png'
import ChatGPT from "../../images/chatGPTicon.png"
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from '../ScrollToTop';
import Footer from '../Footer';
import YouTube from "../../images/youtube-icon.png"
import Magic from "../../images/magic.jpg"
import Library from '../../images/NewLibrary.png'
import TeachAI from '../../images/TeachAI.jpg'
import NavBlueprint from './NavResourceCenter';
import Quill from '../../images/Quil.jpg'
import Tool from '../../images/toolsIcon.png'
import AIEDU from '../../images/AIEDU.jpg'
import Newsletter from '../../images/newsletter.png'
import UserGuide from '../../images/UserGuide.png'
import DataPrivacy from '../../images/DataPrivacy.png'
import KeynoteSpeech from '../../images/KeynoteSpeech.png'
import AdminCourse from '../../images/AdminCourse.png'
import Calculator from '../../images/Calculator.png'
import CallToAction from '../../images/CallToAction.png'
import Policy from '../../images/Policy.png'
import PDF from '../../files/A Schools Guide to Student Data Privacy With AI Rev 080624.pdf'
import HubVideo from '../../Video/NEW WELCOME.mp4'
import 'react-html5video/dist/styles.css'
import Thumbnail from '../../images/LeadThumbnail.png'
import Learn from '../../images/Learn.png'
import Form from '../../images/form.png'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, onAuthStateChanged } from "firebase/auth";
import { UserAuth } from '../../Context/authContext';
import { doc, setDoc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import db from '../../Firebase'
import AICalculator from './AICalculator';
import AIResourceCenterSteps from './AIResourceCenterStepsComponent';

const AIResourceCenterforSchoolLeaders = () => {
    const [allowVideo, setAllowVideo] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [authPassword, setAuthPassword] = useState('');
    const [signInStep, setSignInStep] = useState("1");
    const [shouldCreateAccount, setShouldCreateAccount] = useState(false);
    const [shouldSignIn, setShouldSignIn] = useState(false);
    const [passwordReset, setPasswordReset] = useState(false);
    const [emailNotTyped, setEmailNotTyped] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [error, setError] = useState('');
    const [progress, setProgress] = useState({});

    const { createUser, signIn, user, logout } = UserAuth();

    const navigate = useNavigate();

    const videoLoaded = () => {
        setIsVideoLoaded(true);
    };

    useEffect(() => {
        setAllowVideo(true);
    }, []);

    const openPDF = () => {
        window.open(PDF, '_blank');
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    //AUTHENTICATION
    const ensureUserInFirestore = async (email) => {
        const userRef = doc(db, "Lead Magnet", "users", "Accounts", email);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
            // If the user does not exist in Firestore, add them
            await setDoc(userRef, {
                email: email,
                createdAt: new Date(),
                isAccount: true,
            });

            // Optionally update the email map or other related data here
            console.log('User added to Firestore');
        } else {
            console.log('User already exists in Firestore');
        }
    };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // User is authenticated, ensure they are added to Firestore
                await ensureUserInFirestore(user.email);
            }
        });
    }, []);

    const checkIfUserExists = async (email) => {
        const auth = getAuth();
        try {
            await signInWithEmailAndPassword(auth, email, "dummyPassword");
            return true;
        } catch (error) {
            if (error.code === "auth/user-not-found") {
                return false;
            }
            if (error.code === "auth/wrong-password") {
                return true;
            } else {
                throw error;
            }
        }
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            if (signInStep === "1") {
                const userExists = await checkIfUserExists(userEmail);
                if (userExists) {
                    setShouldSignIn(true);
                    setShouldCreateAccount(false);
                    setSignInStep("2");
                } else {
                    setShouldCreateAccount(true);
                    setShouldSignIn(false);
                    setSignInStep("2");
                }
            } else if (signInStep === "2") {
                if (shouldSignIn) {
                    await signInWithEmailAndPassword(getAuth(), userEmail, authPassword);
                    await ensureUserInFirestore(userEmail); // Ensure the user is added to Firestore after sign-in
                } else if (shouldCreateAccount) {
                    if (password !== rePassword) {
                        setError("Passwords do not match.");
                        return;
                    }
                    await createUserWithEmailAndPassword(getAuth(), userEmail, password);
                    await signInWithEmailAndPassword(getAuth(), userEmail, password);
                    await ensureUserInFirestore(userEmail); // Ensure the user is added to Firestore after sign-up
                }
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const saveUserToFirestore = async (email) => {
        const userRef = doc(db, "Lead Magnet", "users", "Accounts", email);
        const emailMapRef = doc(db, "Lead Magnet", "users");

        try {
            await setDoc(userRef, {
                email: email,
                createdAt: new Date(),
                isAccount: true,
            });

            // Update the emailMap in the parent document
            await updateDoc(emailMapRef, {
                emailMap: arrayUnion(email.toLowerCase()),
            });
        } catch (error) {
            setError("Failed to save user data to Firestore: " + error.message);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!userEmail) {
            setEmailNotTyped(true);
            setInvalidEmail(false);
            return;
        }

        const auth = getAuth();

        try {
            await sendPasswordResetEmail(auth, userEmail);
            setPasswordReset(true);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (e) {
            console.log(e.message);
        }
    };

    //PROGRESS CHECK
    const loadUserProgress = async () => {
        if (user) {
            const progressRef = doc(db, "Lead Magnet", "users", "Accounts", user.email);
            const progressDoc = await getDoc(progressRef);
            if (progressDoc.exists()) {
                setProgress(progressDoc.data().progress || {});
            }
        }
    };

    useEffect(() => {
        loadUserProgress();
    }, [user]);

    const resources = [
        {
            name: "Introduction to AI Implementation for School Leaders",
            link: null, // We'll handle this differently
            img: Thumbnail,
            resourceName: "Intro_Video",
            description: "Watch this introductory video to understand the AI implementation process for school leaders.",
            step: 1,
            stepTitle: "Start Here",
            stepDescription: "Begin your AI journey with this introductory video."
        },
        {
            name: "AI Guide for School Leaders: ChatGPT Uses & Prompts",
            link: "https://chat.openai.com/",
            img: UserGuide,
            resourceName: "AI_Guide",
            description: "How to use AI specifically as a school leader. A handout that can save you 50+ hours.",
            step: 2,
            stepTitle: "Understanding AI Basics",
            stepDescription: "Begin your AI journey by learning the fundamental concepts and applications in education."
        },
        {
            name: "Gruvy Education's Guide to Data Privacy With AI",
            link: openPDF,
            img: DataPrivacy,
            resourceName: "Data_Privacy",
            description: "How to use AI while protecting student data. A school's guide to data privacy with AI.",
            step: 2,
            stepTitle: "Understanding AI Basics",
            stepDescription: "Begin your AI journey by learning the fundamental concepts and applications in education."
        },
        {
            name: "Transformative Keynote: AI Perspective Shift",
            link: "https://gruvyeducation.com/promptlibrary",
            img: KeynoteSpeech,
            resourceName: "AI_Perspective_Shift",
            description: "How to shift your staff's perspective on AI. Full keynote that is guaranteed to shift your staff's perspective on AI.",
            step: 3,
            stepTitle: "Shifting Perspectives on AI",
            stepDescription: "Learn how to change the mindset of your staff regarding AI in education."
        },
        {
            name: "AI Time-Saver & Burnout Risk Calculator",
            link: "https://app.magicschool.ai/",
            img: Calculator,
            resourceName: "Burnout_Calculator",
            description: "How to figure out where your staff is at compared to others.",
            step: 3,
            stepTitle: "Shifting Perspectives on AI",
            stepDescription: "Learn how to change the mindset of your staff regarding AI in education."
        },
        {
            name: "School Leader AI Training Course",
            link: "https://gruvyeducation.com/training/AI%20Training%20for%20School%20Administrators",
            img: AdminCourse,
            resourceName: "AI_Training_Course",
            description: "How to use AI as a school leader. Course that 2,500+ school leaders have leveraged.",
            step: 4,
            stepTitle: "AI Training for School Leaders",
            stepDescription: "Dive deep into AI applications specifically designed for school administration and leadership."
        },
        {
            name: "Foundational Policy Ideas for AI in Education",
            link: "https://www.teachai.org/policy",
            img: Policy,
            resourceName: "AI_Policy",
            description: "Developed by TeachAI to help education and policy leaders realize the potential benefits of AI in education while mitigating the risks.",
            step: 4,
            stepTitle: "AI Training for School Leaders",
            stepDescription: "Dive deep into AI applications specifically designed for school administration and leadership."
        },
        {
            name: "Learn about Gruvy Education's AI Training",
            link: "https://www.teachai.org/policy",
            img: Learn,
            resourceName: "AI_Training_Info",
            description: "Learn about the training that over 100 schools have adopted.",
            step: 5,
            stepTitle: "Exploring AI Training Options",
            stepDescription: "Discover various AI training programs available for educators and administrators."
        },
        {
            name: "Get AI Training for My Staff",
            link: "https://quillbot.com/ai-content-detector",
            img: CallToAction,
            resourceName: "AI_Training_Staff",
            description: "Apply to get Gruvy Education's AI Training for your staff. Recently turned applications back on. Will be shut off again.",
            step: 6,
            stepTitle: "Implementing AI Training",
            stepDescription: "Take action to bring AI training to your school and staff."
        }
    ];

    const handleResourceClick = async (resourceName) => {
        if (user) {
            try {
                const progressRef = doc(db, "Lead Magnet", "users", "Accounts", user.email);
                const now = new Date();
                const updatedProgress = {
                    ...progress,
                    [resourceName]: {
                        status: 'completed',
                        firstClicked: progress[resourceName]?.firstClicked || now,
                        lastClicked: now
                    }
                };

                await updateDoc(progressRef, { progress: updatedProgress });
                setProgress(updatedProgress);
                console.log('Progress saved successfully!');
            } catch (error) {
                console.error('Error saving progress:', error);
            }
        }
    };

    const getResourceStatus = (resourceName) => {
        if (progress[resourceName]?.status === "completed") {
            return {
                text: "Completed",
                className: "resource-completed",
                firstClicked: progress[resourceName].firstClicked,
                lastClicked: progress[resourceName].lastClicked
            };
        } else {
            return { text: "Incomplete", className: "resource-incomplete" };
        }
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleString();
    };

    return (
        <body>
            {!user ? (
                <div className="auth">
                    <form className="authCard">
                        <h6 className="authCardWords">To access the AI Resource Center for School Leaders, please enter your email:</h6>
                        <div className="formGroup">
                            <label className="auth-label">Email</label>
                            <input
                                type="email"
                                className="inputField"
                                onChange={(e) => setUserEmail(e.target.value)}
                            />
                        </div>
                        {shouldCreateAccount && (
                            <>
                                <div className="formGroup">
                                    <label className="auth-label">Create Password</label>
                                    <input
                                        type="password"
                                        className="inputField"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="formGroup">
                                    <label className="auth-label">Retype Password</label>
                                    <input
                                        type="password"
                                        className="inputField"
                                        onChange={(e) => setRePassword(e.target.value)}
                                    />
                                </div>
                            </>
                        )}
                        {shouldSignIn && (
                            <div className="formGroup">
                                <label className="auth-label">Password</label>
                                <input
                                    type="password"
                                    className="inputField"
                                    onChange={(e) => setAuthPassword(e.target.value)}
                                />
                            </div>
                        )}
                        <div className="formGroup">
                            <button onClick={handleSignIn} className="auth-btn">Submit</button>
                        </div>
                        {error && <p className="error">{error}</p>}
                        {emailNotTyped && <p className="error">Please enter your email to reset your password.</p>}
                        {passwordReset && <p className="success">Password reset link sent to your email.</p>}
                        {invalidEmail && <p className="error">Invalid Email.</p>}
                        <div className="formGroup extraButtons">
                            <button onClick={handleResetPassword} className="auth-extra-btn">Reset Password</button>
                            <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSf-bwMgt5fGFJ31yREXMCom7b26m-IvoCNNgQG6cwrQHkvAFQ/viewform?usp=sf_link"
                                target="_blank"
                                className="auth-extra-btn"
                            >
                                Need Access?
                            </a>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="holder">
                    <NavBlueprint module="home" />
                    <header className="shortHeader">
                        <div className="container header-content noBottomMargin">
                            <div className="header-text text-white text-center">
                            </div>
                        </div>
                    </header>
                    <ScrollToTop />
                    <section className="section-one bg-ghost-white lessPaddingTop">
                        <div className="title noMarginBottom breakout-title">AI Implementation Steps for School Leaders</div>
                        <div className="course-container">
                            <AIResourceCenterSteps 
                                resources={resources} 
                                videoSrc={HubVideo} 
                                videoThumbnail={Thumbnail}
                            />
                        </div>
                    </section>
                    <Footer />
                </div>
            )}
        </body >
    );
}

export default AIResourceCenterforSchoolLeaders;
