import React, { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Nav from './TrainNav'
import Logo from "../images/logo-no-background-train.png";
import db from '../Firebase';
import { Link, useHistory, useNavigate, useParams } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Footer from './Footer';
import Prompts from "../images/toolsIcon.png"
import Thumbnail from '../images/Day2Video.png'
import LoadingThumbnail from '../images/loadingthumb.png'
import ActivityThumbnail from '../images/ActivityThumbnail.png'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import Doc from '../images/doc.png'
import Auto from '../images/auto.jpg'
import { UserAuth } from '../Context/authContext';
import YouTube from "../images/youtube-icon.png"
import Elicit from '../images/Elicit.png'
import Semantic from '../images/Semantic.jpg'
import Plus from '../images/Plus.jpg'
import Scholarcy from '../images/Scholarcy.jpg'
import Form from '../images/form.png'
import Badge2 from '../images/badge2.png'
import Grammarly from "../images/Grammarly.png"
import Consensus from '../images/Consensus.png'
import MagicSchool from "../images/magic.jpg"
import Diffit from '../images/diffit.png'
import Google from '../images/google-icon.png'
import TeachAI from '../images/TeachAI.jpg'
import Guide from '../images/Guide.png'
import AIEDU from '../images/AIEDU.jpg'
import Library from '../images/NewLibrary.png'
import GoogleSheets from '../images/GoogleSheetIcon.png'
import StudentSide from '../images/StudentSide.png'
import Guide3 from '../images/Guide3.png'
import AIresistant from '../images/AIresistant.png';
import Gruvy from '../images/GruvyIcon.png';
import Newsletter from '../images/newsletter.png'
import GruvyPurple from '../images/WhiteIcon_While_PurpleBG.png'
import GruvyGreen from '../images/WhiteIcon_While_GreenBG.png'
import Perplexity from '../images/Perplexity.png'
import AnonymizeData from '../images/AnonymizeData.png'
import OverWelm from '../images/OverWelm.png'
import Magic1 from '../images/Magic1.png'
import GoogleSheet from '../images/GoogleSheetIcon.png'
import Gamma from '../images/Gamma.png'
import Practical from '../images/Practical.png'
import Guide1 from '../images/Guide1.png'
import DataAnalysis from '../images/DataAnalysis.png'
import Instagram from '../images/Instagram.png'
const DayTwoCourse = () => {

  // Firebase References
  const { schoolID } = useParams()
  const schoolDoc = doc(db, 'School', schoolID);
  const sessionDatesRef = collection(schoolDoc, "Session Dates");
  const sessionDatesDoc = doc(sessionDatesRef, "Session Dates");
  const dueDatesRef = collection(schoolDoc, "Due Dates");
  const dueDatesDoc = doc(dueDatesRef, "Due Dates");
  const emailsRef = collection(schoolDoc, "Emails");
  const linkDoc = doc(db, "Master Links", "NoAsync");
  const [trainingType, setTrainingType] = useState("")
  const [moduleTitles, setModuleTitles] = useState([]);

  // State Hooks for Dates and Times
  const [day1date, setDay1date] = useState('');
  const [comparableDay2date, setComparableDay2date] = useState('');
  const [day1time, setDay1time] = useState('');
  const [day1dueDate, setDay1dueDate] = useState('');
  const [day1location, setDay1location] = useState('')

  // State Hooks for School Info
  const [schoolPic, setSchoolPic] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [videoURL, setVideoURL] = useState(null);

  // Menu and UI States
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuVisibleByScroll, setMenuVisibleByScroll] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showBadge, setShowBadge] = useState(false);

  // Activity, Poll, and Question States
  let answeredPollsFromDb = {};
  let answeredQuestionsFromDb = {};
  const [contentData, setContentData] = useState({});
  const [answeredPolls, setAnsweredPolls] = useState({});
  const [pollAnswers, setPollAnswers] = useState({});
  const [activityAnswers, setActivityAnswers] = useState({});
  const [answeredActivities, setAnsweredActivities] = useState({});
  const [questionAnswers, setQuestionAnswers] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [activityVideoURLs, setActivityVideoURLs] = useState({});


  // Resource and Completion Flags
  const [isLectureComplete, setIsLectureComplete] = useState(false);
  const [clickedResources, setClickedResources] = useState({});
  const [isResourcesComplete, setIsResourcesComplete] = useState(false);
  const [areQuestionsComplete, setAreQuestionsComplete] = useState(false);
  const [completedActivities, setCompletedActivities] = useState(false);
  const [areAllActivitiesCompleteState, setAreAllActivitiesCompleteState] = useState(false);

  // Miscellaneous
  const imageMapping = {
    Prompts,
    Auto,
    ChatGPT,
    GPTZero,
    Eduaide,
    Doc,
    YouTube,
    Elicit,
    Semantic,
    Scholarcy,
    Consensus,
    Grammarly,
    Plus,
    MagicSchool,
    Form,
    Diffit,
    Google,
    TeachAI,
    Guide,
    AIEDU,
    Library,
    GoogleSheets,
    StudentSide,
    Guide3,
    AIresistant,
    Gruvy,
    Newsletter,
    GruvyGreen,
    GruvyPurple,
    AnonymizeData,
    Perplexity,
    OverWelm,
    Magic1,
    GoogleSheet,
    Gamma,
    Practical,
    Guide1,
    Instagram,
    DataAnalysis,
  };
  const [numberOfDays, setNumberOfDays] = useState(4);

  // Authentication and User
  const navigate = useNavigate();
  const { user, logout } = UserAuth();
  let userDoc;
  if (user && user.email) {
    userDoc = doc(emailsRef, user.email);
  } else {
    console.error("User or user email is undefined.");
  }
  useEffect(() => {
    if (!user) {
      navigate(`/training/${schoolID}`)
    }
  }, [user]);

  /**
   * =====================
   *    CHECK FUNCTIONS
   * =====================
   */


  const checkForResourceCompletion = () => {
    const totalResources = Object.keys(contentData || {}).filter(key => key.startsWith('Resource')).length;

    if (Object.keys(clickedResources).length === totalResources && totalResources > 0) {
      setIsResourcesComplete(true);
      console.log("TRUE, Object.keys(clickedResources).length: " + Object.keys(clickedResources).length + "\ntotalResources: " + totalResources)
    } else {
      setIsResourcesComplete(false);
      console.log("FALSE, Object.keys(clickedResources).length: " + Object.keys(clickedResources).length + "\ntotalResources: " + totalResources)

    }
  };

  const checkForLectureCompletion = () => {
    // Count the number of polls in the lecture
    const totalPolls = Object.keys(contentData?.Lecture || {}).filter(key => key.startsWith('Poll')).length;

    // Check if pollAnswers has answers for all polls
    const answeredPollsCount = Object.keys(pollAnswers).length;

    // Check for completion based on polls answered in DB or locally
    if ((Object.keys(answeredPollsFromDb).length === totalPolls || answeredPollsCount === totalPolls) && totalPolls > 0) {
      setIsLectureComplete(true);
    } else {
      setIsLectureComplete(false);
      console.log(Object.keys(answeredPollsFromDb).length);
    }
  };

  const checkForQuestionCompletion = () => {
    const totalQuestions = Object.keys(contentData['Closing Questions'] || {}).filter(key => key.startsWith('Question')).length;
    const answeredQuestionsCount = Object.keys(answeredQuestions).length;
    if (answeredQuestionsCount === totalQuestions && totalQuestions > 0) {
      setAreQuestionsComplete(true);
    } else {
      setAreQuestionsComplete(false);
    }
  };

  const checkForActivityCompletion = () => {
    console.log("ran")
    // Object to store the completion status of each activity
    let newCompletedActivities = {};

    // Loop through each activity
    for (const activityKey of Object.keys(contentData || {}).filter(key => key.startsWith('Activity'))) {
      const activityIndex = parseInt(activityKey.replace('Activity', '')) - 1;

      // Count the total number of questions in the current activity
      const totalQuestionsInActivity = Object.keys(contentData[activityKey] || {}).filter(key => key.startsWith('Question')).length;

      // Count the number of answered questions in the current activity
      const answeredQuestionsInActivity = Object.keys(activityAnswers || {}).filter(key => {
        const [activityIdx, _] = key.split('_').map(Number);
        return activityIdx === activityIndex;
      }).length;

      // Check if all questions in the current activity have been answered
      if (answeredQuestionsInActivity === totalQuestionsInActivity && totalQuestionsInActivity > 0) {
        newCompletedActivities[activityIndex] = true;
      } else {
        newCompletedActivities[activityIndex] = false;
      }
    }

    // Update the state
    setCompletedActivities(newCompletedActivities);
  };

  const areAllActivitiesComplete = () => {
    console.log('Checking all activities for completion...');

    // Check if contentData exists and completedActivities is properly initialized
    if (!contentData || typeof completedActivities !== 'object') {
      console.log('Data not ready. Exiting.');
      setAreAllActivitiesCompleteState(false);
      return;
    }

    // Get the maximum activity index from the dynamic keys
    const activityKeys = Object.keys(contentData).filter(key => key.startsWith('Activity'));
    const maxActivityIndex = activityKeys.length - 1;

    if (maxActivityIndex < 0) {
      console.log('No activities found. Exiting.');
      setAreAllActivitiesCompleteState(false);
      return;
    }

    console.log(`Max activity index found: ${maxActivityIndex}`);
    console.log('Current state of completedActivities:', completedActivities);

    // Check if each activity from 0 to maxActivityIndex is completed
    for (let i = 0; i <= maxActivityIndex; i++) {
      console.log(`Checking activity at index ${i}...`);

      if (!completedActivities.hasOwnProperty(i) || !completedActivities[i]) {
        console.log(`Activity at index ${i} is not completed.`);
        setAreAllActivitiesCompleteState(false);
        return;
      } else {
        console.log(`Activity at index ${i} is completed.`);
      }
    }

    console.log('All activities are completed.');
    setAreAllActivitiesCompleteState(true);
  };


  /**
 * =====================
 *    FETCH FUNCTIONS
 * =====================
 */

  const fetchPollAnswers = async () => {
    if (isEditing) return;
    try {
      if (user && user.email) {

        const dayOneCollectionRef = collection(userDoc, 'Day 2');
        console.log("dayOneCollectionRef: ", dayOneCollectionRef);

        const allPollKeys = Object.keys(contentData?.Lecture || {}).filter(key => key.startsWith('Poll')).sort();
        console.log("allPollKeys: ", allPollKeys);

        let fetchedPollAnswers = {};
        let shouldUpdateState = false;
        for (const pollKey of allPollKeys) {
          const pollIndex = parseInt(pollKey.replace('Poll', '')) - 1;
          console.log("pollKey: ", pollKey, "pollIndex: ", pollIndex);

          const pollDocRef = doc(dayOneCollectionRef, `Poll ${pollIndex + 1}`);
          console.log("pollDocRef: ", pollDocRef);

          const pollDocSnapshot = await getDoc(pollDocRef);
          console.log("pollDocSnapshot.exists(): ", pollDocSnapshot.exists());

          if (pollDocSnapshot.exists()) {
            fetchedPollAnswers[pollIndex] = pollDocSnapshot.data().answer;
            answeredPollsFromDb[pollIndex] = true;  // Indicate that this poll has been answered
            shouldUpdateState = true;
          }

        }

        if (shouldUpdateState && JSON.stringify(fetchedPollAnswers) !== JSON.stringify(pollAnswers)) {
          console.log("Setting poll answers: ", fetchedPollAnswers);
          setPollAnswers(fetchedPollAnswers);
          setAnsweredPolls(answeredPollsFromDb);
          checkForLectureCompletion();
        }

      }
    } catch (error) {
      console.error("Error fetching poll data: ", error);
    }
  };

  const fetchQuestionAnswers = async () => {
    try {
      if (user && user.email) {
        const dayOneCollectionRef = collection(userDoc, 'Day 2');
        const allQuestionKeys = Object.keys(contentData['Closing Questions'] || {}).filter(key => key.startsWith('Question')).sort();
        let fetchedQuestionAnswers = {};
        let shouldUpdateState = false;
        for (const questionKey of allQuestionKeys) {
          const questionIndex = parseInt(questionKey.replace('Question', '')) - 1;
          const questionDocRef = doc(dayOneCollectionRef, `Question ${questionIndex + 1}`);
          const questionDocSnapshot = await getDoc(questionDocRef);
          if (questionDocSnapshot.exists()) {
            fetchedQuestionAnswers[questionIndex] = questionDocSnapshot.data().answer;
            answeredQuestionsFromDb[questionIndex] = true;
            shouldUpdateState = true;
          }
        }
        if (shouldUpdateState) {
          setQuestionAnswers(fetchedQuestionAnswers);
          setAnsweredQuestions(answeredQuestionsFromDb);
          checkForQuestionCompletion();
        }
      }
    } catch (error) {
      console.error("Error fetching question data: ", error);
    }
  };

  const fetchClickedResources = async () => {
    try {
      if (user && user.email) {
        const dayOneCollectionRef = collection(userDoc, 'Day 2');
        const clickedResourcesDocRef = doc(dayOneCollectionRef, 'ClickedResources');

        const clickedResourcesSnapshot = await getDoc(clickedResourcesDocRef);
        if (clickedResourcesSnapshot.exists()) {
          setClickedResources(clickedResourcesSnapshot.data());
        }
        checkForResourceCompletion()
      }
    } catch (error) {
      console.error("Error fetching clicked resources: ", error);
    }
  };

  const fetchActivityAnswers = async () => {
    try {
      if (user && user.email) {
        const dayOneCollectionRef = collection(userDoc, 'Day 2');
        const allActivityKeys = Object.keys(contentData || {}).filter(key => key.startsWith('Activity')).sort();

        let fetchedActivityAnswers = {};
        let newAnsweredActivities = {};
        let shouldUpdateState = false;

        for (const activityKey of allActivityKeys) {
          const activityIndex = parseInt(activityKey.replace('Activity', '')) - 1;

          // Fetch the document for each Activity
          const activityDocRef = doc(dayOneCollectionRef, `Activity ${activityIndex + 1}`);
          const activityDocSnapshot = await getDoc(activityDocRef);

          if (activityDocSnapshot.exists()) {
            const activityData = activityDocSnapshot.data();

            for (const questionKey of Object.keys(contentData[activityKey] || {}).filter(key => key.startsWith('Question'))) {
              const questionIndex = parseInt(questionKey.replace('Question', '')) - 1;

              // Extract the answer from the fetched document
              const answer = activityData[`Question ${questionIndex + 1}`];

              if (answer !== undefined) {
                fetchedActivityAnswers[`${activityIndex}_${questionIndex}`] = answer;

                if (!newAnsweredActivities[activityIndex]) {
                  newAnsweredActivities[activityIndex] = {};
                }
                newAnsweredActivities[activityIndex][questionIndex] = true;

                shouldUpdateState = true;
              }
            }
          }
        }

        if (shouldUpdateState) {
          setActivityAnswers(fetchedActivityAnswers);
          setAnsweredActivities(newAnsweredActivities);
          areAllActivitiesComplete(checkForActivityCompletion());
        }
      }
    } catch (error) {
      console.error("Error fetching activity data: ", error);
    }
  };


  const fetchSessionDatesDoc = async () => {
    const sessionDatesDatesSnap = await getDoc(sessionDatesDoc);
    if (sessionDatesDatesSnap.exists()) {
      setDay1date(sessionDatesDatesSnap.data().Two)
      setDay1time(sessionDatesDatesSnap.data().TwoTime)
      setDay1location(sessionDatesDatesSnap.data().TwoLocation)
      try {
        setComparableDay2date(sessionDatesDatesSnap.data().Two.toDate());
      } catch {
        setDay1date(sessionDatesDatesSnap.data().Two)
        setDay1time(sessionDatesDatesSnap.data().TwoTime)
        setDay1location(sessionDatesDatesSnap.data().TwoLocation)
        setComparableDay2date("no")
      }
    } else {
      console.log('No such document exists!');
    }
  };

  const fetchDueDatesDoc = async () => {
    const dueDatesSnap = await getDoc(dueDatesDoc);
    if (dueDatesSnap.exists()) {
      setDay1dueDate(dueDatesSnap.data().Two)
    } else {
      console.log('No such document exists!');
    }
  };


  /**
 * =====================
 *    useEffect FUNCTIONS
 * =====================
 */

  useEffect(() => {
    checkForQuestionCompletion();
    areAllActivitiesComplete(checkForActivityCompletion());
    checkForResourceCompletion();
  }, [answeredQuestions, answeredActivities, clickedResources, activityAnswers]);

  useEffect(() => {
    // Only run if the user is not currently editing a poll
    if (!isEditing) {
      fetchPollAnswers();
      fetchQuestionAnswers();
      fetchActivityAnswers();

    }
    fetchClickedResources()
  }, [contentData, isEditing, isResourcesComplete, areQuestionsComplete]);

  useEffect(() => {
    const fetchSchoolDoc = async (id) => {
      const schoolDocSnap = await getDoc(schoolDoc);
      if (schoolDocSnap.exists()) {
        setSchoolName(schoolDocSnap.data().Name);
        setSchoolPic(schoolDocSnap.data().icon);
        setTrainingType(schoolDocSnap.data().trainingType);
        const titlesDocRef = doc(db, 'School', schoolID, 'Content', 'Titles');
        const titlesDocSnap = await getDoc(titlesDocRef);
        if (titlesDocSnap.exists()) {
          setModuleTitles(titlesDocSnap.data().titles);
        }
      } else {
        console.log('No such document exists!');
      }
    };
    fetchSchoolDoc()
  }, [])

  useEffect(() => {
    fetchSessionDatesDoc();
    fetchDueDatesDoc();
    const handleScroll = () => {
      const sectionOne = document.querySelector(".section-one");
      const sectionOneOffset = sectionOne.offsetTop;

      if (window.pageYOffset > sectionOneOffset) {
        setMenuVisibleByScroll(true);
      } else {
        setMenuVisibleByScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    const fetchVideoURL = async () => {
      if (!contentData?.Lecture?.Video) {
        return; // Don't fetch if contentData is not ready
      } else {
        const storage = getStorage();
        const videoRef = ref(storage, contentData.Lecture.Video); // Now using the dynamic path

        getDownloadURL(videoRef)
          .then((url) => {
            // Got the download URL, now you can use it in the video tag
            setVideoURL(url);
          })
          .catch((error) => {
            // Handle any errors here
            console.error("Error getting video URL: ", error);
          });
      }
    };
    fetchVideoURL();
  }, [contentData]);

  useEffect(() => {
    const fetchActivityVideoURLs = async () => {
      const activityKeys = Object.keys(contentData || {}).filter(key => key.startsWith('Activity'));

      // Initialize a temporary object to store URLs
      const tempActivityVideoURLs = {};

      const storage = getStorage();

      for (const activityKey of activityKeys) {
        const videoPath = contentData[activityKey].Video;
        const videoRef = ref(storage, videoPath);  // Replace this line as per your actual storage logic

        try {
          const url = await getDownloadURL(videoRef);
          tempActivityVideoURLs[activityKey] = url;
        } catch (error) {
          console.error("Error getting video URL for ", activityKey, error);
        }
      }

      setActivityVideoURLs(tempActivityVideoURLs);
    };

    fetchActivityVideoURLs();
  }, [contentData]);


  useEffect(() => {
    if (!schoolName) {
      return; // Exit if schoolName is not yet available
    }

    const fetchData = async () => {
      // Define the collection reference for 'Day 1'
      const dayOneCollectionRef = collection(db, 'School', schoolName, 'Content', 'Async', 'Day 2');

      // Fetch all documents inside 'Day 1' collection
      const querySnapshot = await getDocs(dayOneCollectionRef);

      let data = {};
      querySnapshot.forEach((doc) => {
        // Add each document to the data object
        data[doc.id] = doc.data();
      });

      setContentData(data);
    };

    fetchData();

  }, [schoolName]);

  useEffect(() => {
    const fetchData = async () => {
      if (!schoolName) {
        return
      }
      const docRef = doc(db, 'School', schoolName, 'Content', 'Async');

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setNumberOfDays(data.numberOfDays);  // Assuming 'numberOfDays' exists in your document
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, [schoolName]);

  /**
  * =====================
  *    HANDLING FUNCTIONS
  * =====================
  */

  const handleQuestionSubmit = (e, index) => {
    e.preventDefault();
    setAnsweredQuestions({ ...answeredQuestions, [index]: true });
    checkForQuestionCompletion();
    const questionDocumentName = `Question ${index + 1}`;
    const questionDocRef = doc(collection(userDoc, 'Day 2'), questionDocumentName);
    setDoc(questionDocRef, { answer: questionAnswers[index] }, { merge: true })
      .then(() => {
        setAnsweredQuestions(prevState => ({ ...prevState, [index]: true }));
        checkForQuestionCompletion();
      })
      .catch((error) => {
        console.error("Error updating the question document:", error);
      });
  };


  const handleEditQuestionResponse = (index) => {
    const newAnsweredQuestions = { ...answeredQuestions };
    delete newAnsweredQuestions[index];
    setAnsweredQuestions(newAnsweredQuestions);
  };

  const handleActivitySubmit = (activityIndex, questionIndex) => {
    // Validate if the user and email are available
    if (!user || !user.email) {
      console.error("User or user email is undefined.");
      return;
    }

    // Construct unique key for activity and question
    const activityDocumentName = `Activity ${activityIndex + 1}`;
    const questionField = `Question ${questionIndex + 1}`;

    // Create a reference for the specific activity document under 'Day 2' collection
    const activityDocRef = doc(collection(userDoc, 'Day 2'), activityDocumentName);

    // Write or update the field in the specific activity document
    setDoc(activityDocRef, {
      [questionField]: activityAnswers[`${activityIndex}_${questionIndex}`]
    }, { merge: true })
      .then(() => {
        // Function to check for lecture completion (if you have one)
        // checkForLectureCompletion();
      })
      .catch((error) => {
        console.error("Error updating the activity document:", error);
      })
      .finally(() => {
        // Update state to mark this activity's question as answered
        const newAnsweredActivities = { ...answeredActivities };
        newAnsweredActivities[activityIndex] = { ...(newAnsweredActivities[activityIndex] || {}), [questionIndex]: true };
        setAnsweredActivities(newAnsweredActivities);
      });
  };


  // Function to handle editing the response
  const handleEditActivityResponse = (activityIndex, questionIndex) => {
    // Create a new copy of the existing answeredActivities state
    const newAnsweredActivities = { ...answeredActivities };

    // Access the specific activity's answers using activityIndex
    const activityAnswers = newAnsweredActivities[activityIndex] || {};

    // Remove the answered status for the specific question within this activity
    delete activityAnswers[questionIndex];

    // If no more questions are answered within this activity, remove the activity from the state
    if (Object.keys(activityAnswers).length === 0) {
      delete newAnsweredActivities[activityIndex];
    } else {
      newAnsweredActivities[activityIndex] = activityAnswers;
    }

    // Update the state
    setAnsweredActivities(newAnsweredActivities);
  };


  const handlePollSubmit = (e, index) => {
    e.preventDefault();
    setAnsweredPolls({ ...answeredPolls, [index]: true });

    // Validate if the user and email are available
    if (!user || !user.email) {
      console.error("User or user email is undefined.");
      return;
    }

    // Construct poll document name
    const pollDocumentName = `Poll ${index + 1}`;

    // Create a reference for 'Day 2' collection under userDoc and a document for the specific poll
    const pollDocRef = doc(collection(userDoc, 'Day 2'), pollDocumentName);

    // Write or update the field in the specific poll document
    setDoc(pollDocRef, { answer: pollAnswers[index] }, { merge: true })
      .then(() => {
        // Function to check for lecture completion
        checkForLectureCompletion();
      })
      .catch((error) => {
        console.error("Error updating the poll document:", error);
      })
      .finally(() => {
        setIsEditing(false);
      });
  };

  const handleEditResponse = (index) => {
    // Remove the answered status for this poll
    setIsEditing(true);
    const newAnsweredPolls = { ...answeredPolls };
    delete newAnsweredPolls[index];
    setAnsweredPolls(newAnsweredPolls);
  };

  const handleResourceClick = (resourceKey) => {
    // Create a temporary updated clickedResources object
    const updatedClickedResources = { ...clickedResources, [resourceKey]: true };

    // Update local state
    setClickedResources(updatedClickedResources);

    // Update Firestore
    const resourceDocRef = doc(collection(userDoc, 'Day 2'), 'ClickedResources');

    setDoc(resourceDocRef, { [resourceKey]: true }, { merge: true })
      .then(() => {
        // Call checkForResourceCompletion with the updated clickedResources
        checkForResourceCompletion(updatedClickedResources);
      })
      .catch((error) => {
        console.error("Error updating the clicked resource document:", error);
      });
  };

  /**
   * =====================
   *    MISC FUNCTIONS
   * =====================
   */

  const isQuestionAnswered = (index) => {
    return !!answeredQuestions[index];
  };


  function boldQuotedText(text) {
    return text.replace(/"(.*?)"/g, '<strong>"$1"</strong>');
  }



  // Function to check if the activity was answered
  const isActivityAnswered = (activityIndex, questionIndex) => {
    return !!answeredActivities[activityIndex]?.[questionIndex];
  };



  const isPollAnswered = (index) => {
    return !!answeredPolls[index];
  };




  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };


  function formatDate(serializedTimestamp) {
    if (typeof serializedTimestamp === 'string') {
      return serializedTimestamp;
    }

    // Convert Firestore timestamp to JavaScript Date
    const date = new Date(serializedTimestamp.seconds * 1000);

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }
  function formatTime(serializedTimestamp, dueDate) {
    // If serializedTimestamp is the string "Asynchronous", use dueDate with formatDate()
    if (serializedTimestamp === 'Asynchronous') {
      return "Complete by " + formatDate(dueDate); // I assume formatDate() is a function you have or will define
    }

    if (typeof serializedTimestamp === 'string') {
      return serializedTimestamp;
    }

    // Convert Firestore timestamp to JavaScript Date object
    const date = new Date(serializedTimestamp.seconds * 1000);

    // Extract hours and minutes
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes; // prepend 0 if less than 10

    return `${hours}:${minutes} ${ampm}`;
  }

  const openBadge = () => {
    setShowBadge(true);
  };

  const closeBadge = () => {
    setShowBadge(false);
  };

  const shouldShowMenuOptions = menuVisible && menuVisibleByScroll;

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const day1dateZeroed = new Date(comparableDay2date);
  day1dateZeroed.setHours(0, 0, 0, 0);

  const displayTime = `${formatDate(day1date)}${day1date === 'Asynchronous' ? " | " : " at "}${formatTime(day1time, day1dueDate)}`;

  return (
    <body>
      {Object.keys(contentData).length === 0 ? (
        <div className="loader-wrapper">
          <div className="loader">
            <div className="spinner"></div>
            <div className="loading-text">Module 2...</div>
          </div>
        </div>
      ) : (
        <div className="holder" id='top'>
          <Nav url={schoolID} day='day2' moduleTitles={moduleTitles} numberOfDays={numberOfDays} type={trainingType} />
          <nav className="navbar">
            {/* <div className="topLinks">
                        <Link to={`/training/${schoolID}`} className="back">&larr; Back to training home</Link>
                    </div> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="container nav-content">
              <div className="brand-and-toggler">
                <Link to={`/training/${schoolID}`} className="navbar-brand-train">
                  <img src={Logo} className="logo extraMargin train-logo" alt="site logo" />
                </Link>
              </div>
            </div>
          </nav>
          <header className="header-purple2">
            <div className="container header-content noBottomMargin">
              <div className="header-text text-white text-center">
                <br />
                <br />
                <br />
                <br />
                <br />
                {/* <div className="school">{schoolName}</div> */}
                <br />
                {/* <div className="schoolImgContainer">
                                <img src={`${schoolPic}`} id="mainLogo" className="logo schoolLogo2" alt="site logo" />
                            </div> */}
                <br />
                <br />
                <div className="day">Module 2: </div>
                <l className='sessionName'>{contentData?.Lecture.Description}</l>
                {/* <div className="sessionDate">{formatDate(day1date)}{day1date === 'Asynchronous' ? " | " : " at "}{formatTime(day1time, day1dueDate)}</div> */}
                {/* <div className="btn-group">
                                <button onClick={scrollToBottom} className='black oneButton' type="button">Stuck? Click here to schedule a time for me to help you.</button>
                            </div> */}
              </div>
              {/* <div className="scrollDown text-white">
                            <div className="scrollIcon">&#8681;</div>
                            <div className="scrollText">Scroll Down for Training Materials</div>
                            <div className="scrollIcon">&#8681;</div>
                        </div> */}
              {/* <br /> */}
              <br />
              <br />
            </div>
          </header>
          <section className="section-one bg-ghost-white noPaddingTop">
            <button
              id="menu-button"
              className={menuVisibleByScroll ? "show-menu-button" : "hidden-menu-button"}
              onClick={toggleMenu}
            >
              ☰
            </button>
            <div
              id="menu-options"
              className={shouldShowMenuOptions ? "show-menu-options" : "hidden-menu-options"}
            >
              <ScrollLink className='cursorPointer' to="lecture-section" smooth={true} duration={50}>
                <span className="icon">🎓</span>Lecture
              </ScrollLink>
              <ScrollLink className='cursorPointer' to="resources-section" smooth={true} duration={50}>
                <span className="icon">📚</span>Resources
              </ScrollLink>
              <ScrollLink className='cursorPointer' to="activities-section" smooth={true} duration={50}>
                <span className="icon">🎨</span>Activities
              </ScrollLink>
              <ScrollLink className='cursorPointer' to="closing-questions-section" smooth={true} duration={50}>
                <span className="icon">❓</span>Closing Questions
              </ScrollLink>
              <ScrollLink className='cursorPointer' to="completion-summary-section" smooth={true} duration={50}>
                <span className="icon">✅</span>Completion Summary
              </ScrollLink>
              <ScrollLink className='cursorPointer' to="top" smooth={true} duration={50}>
                <span className="icon">⬆️</span>Scroll To Top
              </ScrollLink>
            </div>

            <div className="course-container">
              {day1location !== 'positionSpecific' && (
                <div className="directions">
                  {day1location === 'Asynchronous' ? (
                    <>
                      <p>Complete by {formatDate(day1dueDate)} @ 11:59 PM</p>
                      {(trainingType === "regular" || trainingType === "ndsu") && (
                        <>
                          <br />
                          <p>Completion of module 2 will earn you this university accredited <button onClick={openBadge} className='underline'>badge.</button></p>
                        </>
                      )}
                    </>
                  ) : (
                    currentDate < day1dateZeroed ? (
                      day1location.toLowerCase().includes('zoom') || day1location.toLowerCase().includes('google') ? (
                        <>
                          <p>Wait until {displayTime} to advance.</p>
                          <br />
                          <p>On {displayTime} please join the Zoom:</p>
                          <br />
                          <a className='sessionJoinLink' target='_blank' rel="noopener noreferrer" href={day1location}>Press here to join.</a>
                          <br />
                          <br />
                          <p>Then, please stay on this page and wait for further instructions.</p>
                        </>
                      ) : (
                        <>
                          <p>Wait until {displayTime} to advance.</p>
                          <br />
                          <p>I am traveling to your school!</p>
                        </>
                      )
                    ) : (
                      <>
                        <p>Complete by {formatDate(day1dueDate)} @ 11:59 PM</p>
                        {(trainingType === "regular" || trainingType === "ndsu") && (
                          <>
                            <br />
                            <p>Completion of module 2 will earn you this university accredited <button onClick={openBadge} className='underline'>badge.</button></p>
                          </>
                        )}
                        {trainingType === "oneOff" && (
                          <>
                            <br />
                            <p>↓ Start by watching the video below. ↓</p>
                          </>
                        )}
                      </>
                    )
                  )}
                </div>
              )}
              {showBadge && (
                <div className="badgePopup">
                  <span className="closeButton" onClick={closeBadge}>X</span>
                  <img className="badgeImgPopup" src={Badge2} alt="badge2" />
                  <p>You won't get the badge as soon as you're done.<br /><br /><strong>NDSU will send your earned badges to your email every Friday.</strong><br /><br />After you get it, make sure to download it.</p>
                </div>
              )}
              <div className="course-navbar">
                <ScrollLink to="lecture-section" smooth={true} duration={50} className="course-nav-item">
                  <div><span role="img" aria-label="Cap">🎓</span> Lecture</div>
                  <span className={`status-label ${isLectureComplete ? 'nav-completed' : 'nav-pending'}`}>{isLectureComplete ? 'Completed' : 'Incomplete'}</span>
                </ScrollLink>
                <ScrollLink to="resources-section" smooth={true} duration={50} className="course-nav-item">
                  <div><span role="img" aria-label="Book">📚</span> Resources</div>
                  <span className={`status-label ${isResourcesComplete ? 'nav-completed' : 'nav-pending'}`}>{isResourcesComplete ? 'Completed' : 'Incomplete'}</span>
                </ScrollLink>
                <ScrollLink to="activities-section" smooth={true} duration={50} className="course-nav-item">
                  <div><span role="img" aria-label="Palette">🎨</span> Activities</div>
                  <span className={`status-label ${areAllActivitiesCompleteState ? 'nav-completed' : 'nav-pending'}`}>
                    {areAllActivitiesCompleteState ? 'Completed' : 'Incomplete'}
                  </span>
                </ScrollLink>
                <ScrollLink to="closing-questions-section" smooth={true} duration={50} className="course-nav-item">
                  <div><span role="img" aria-label="Question Mark">❓</span> Closing Questions</div>
                  <span className={`status-label ${areQuestionsComplete ? 'nav-completed' : 'nav-pending'}`}>{areQuestionsComplete ? 'Completed' : 'Incomplete'}</span>
                </ScrollLink>
                <ScrollLink to="completion-summary-section" smooth={true} duration={50} className="course-nav-item">
                  <div><span role="img" aria-label="Check Mark">✅</span> Completion Summary</div>
                </ScrollLink>
              </div>
              <div id='lecture-section' className="lecture-part">
                {
                  videoURL ? (
                    <video controlsList="nodownload" controls className="course-video-content" poster={Thumbnail}>
                      <source src={videoURL} type="video/mp4" />
                    </video>
                  ) : (
                    <video controls className="course-video-content" poster={LoadingThumbnail}>
                    </video>  // Or any other placeholder
                  )
                }
              </div>

              <section className="course-section course-lecture-polls-section">
                {/* <div className="lecture-polls-wrapper course-content">
                  <div className="polls-part"> */}
                {/* <h3 className="course-sub-title">📊 Polls</h3> Changed from h2 to h3 */}
                <div className="course-content course-poll-content">
                  {Object.keys(contentData?.Lecture || {}).filter(key => key.startsWith('Poll')).sort().map((pollKey, index) => (
                    <div key={pollKey} className="poll-item poll-card">
                      <button
                        className="view-results-button"
                        onClick={() => {
                          const url = `/view-poll-results/${encodeURIComponent(schoolID)}/${encodeURIComponent("Day 2")}/${encodeURIComponent(pollKey)}/${encodeURIComponent(contentData.Lecture[pollKey])}/poll`;
                          window.open(url, '_blank');
                        }}
                      >
                        View Results
                      </button>
                      <h3 className="poll-label">📊 Poll {index + 1}</h3>
                      <p className='poll-question'>{contentData.Lecture[pollKey]}</p>
                      <form className="poll-form" onSubmit={(e) => handlePollSubmit(e, index)}>
                        <input
                          type="text"
                          className="poll-input"
                          placeholder="Type your answer here..."
                          disabled={isPollAnswered(index)}
                          value={pollAnswers[index] || ''}
                          onChange={(e) => setPollAnswers({ ...pollAnswers, [index]: e.target.value })}
                        />
                        <div className="course-button-container">
                          <button
                            type="submit"
                            className="poll-submit-button"
                            disabled={isPollAnswered(index) || !pollAnswers[index]}
                          >
                            Submit
                          </button>
                          {isPollAnswered(index) && (
                            <button type="button" className="poll-edit-button" onClick={() => handleEditResponse(index)}>Edit</button>
                          )}
                        </div>
                      </form>
                    </div>
                  ))}
                </div>
                {/* </div>
                </div> */}
              </section>
              <section id='resources-section' className="course-section course-resources-section">
                <h2 className="course-sub-title">📚 Resources</h2>
                <div className="tool-grid">
                  {Object.keys(contentData || {})
                    .filter(key => key.startsWith('Resource'))
                    .sort((a, b) => {
                      const numA = parseInt(a.replace('Resource', ''), 10);
                      const numB = parseInt(b.replace('Resource', ''), 10);
                      return numA - numB;
                    })
                    .map(resourceKey => {
                      const { Title, Description, Link, Picture } = contentData[resourceKey];
                      const ImgSrc = imageMapping[Picture];
                      const isResourceCompleted = clickedResources.hasOwnProperty(resourceKey);
                      return (
                        <a
                          href={Link}
                          target="_blank"
                          className={`tool-card ${isResourceCompleted ? 'resource-completed' : 'resource-incomplete'}`}
                          onClick={() => handleResourceClick(resourceKey)}
                        >
                          <div className="tool-icon">
                            <img src={ImgSrc} alt={Title} />
                          </div>
                          <h3 className="tool-title">{Title}</h3>
                          <p className="tool-text">{Description}</p>
                        </a>
                      );
                    })}
                </div>
              </section>
              <section id='activities-section' className="course-section course-activities-section">
                <h2 className="course-sub-title">🎲 Knowledge-to-Action Activities</h2>
                <div className="activities-wrapper">
                  {/* Navigation bar */}
                  <div className="activity-nav-bar">
                    {Object.keys(contentData || {})
                      .filter(key => key.startsWith('Activity'))
                      .sort((a, b) => {
                        const numA = parseInt(a.replace('Activity', ''), 10);
                        const numB = parseInt(b.replace('Activity', ''), 10);
                        return numA - numB;
                      })
                      .map((activityKey, index) => (
                        <a
                          href={`#${activityKey}`}
                          className={`activity-nav-item ${completedActivities[index] ? 'activity-nav-item-completed' : 'activity-nav-item-incomplete'}`}
                        >
                          {index + 1}
                        </a>
                      ))}
                  </div>
                  {Object.keys(contentData || {})
                    .filter(key => key.startsWith('Activity'))
                    .sort((a, b) => {
                      const numA = parseInt(a.replace('Activity', ''), 10);
                      const numB = parseInt(b.replace('Activity', ''), 10);
                      return numA - numB;
                    })
                    .map((activityKey, activityIndex) => (
                      <div key={activityKey} id={activityKey} className="course-content course-activity-content">
                        {/* Title */}
                        <h3 className="activity-title">
                          <span className="activity-number"></span>
                          {contentData[activityKey].Title}
                        </h3>

                        {/* Video */}
                        {
                          activityVideoURLs[activityKey] ? (
                            <video controlsList="nodownload" controls className="course-video-content" poster={ActivityThumbnail}>
                              <source src={activityVideoURLs[activityKey]} type="video/mp4" />
                            </video>
                          ) : (
                            <video controls className="course-video-content" poster={LoadingThumbnail}>
                            </video>  // Or any other placeholder
                          )
                        }

                        {/* Description */}
                        <p className='activity-description'>{contentData[activityKey].Description}</p>

                        {/* List of Links */}
                        <div className="links-section">
                          <div className="course-links-wrapper">
                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                            <div className="course-links-container">
                              {Object.keys(contentData[activityKey])
                                .filter(key => key.startsWith('Link') && !key.endsWith('pic'))
                                .sort((a, b) => parseInt(a.replace('Link', '')) - parseInt(b.replace('Link', '')))  // Sorting numerically
                                .map(linkKey => {
                                  const ImgSrc = imageMapping[contentData[activityKey][`${linkKey}pic`]];
                                  const LinkName = contentData[activityKey][`${linkKey}pic`];

                                  return (
                                    <a className="course-link-icon" href={contentData[activityKey][linkKey]} target="_blank" rel="noreferrer">
                                      <img className="link-icon" src={ImgSrc} alt={contentData[activityKey][`${linkKey}pic`]} />
                                      <span className="link-label">{LinkName}</span>
                                    </a>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        {/* Questions with Input Fields */}
                        <form className="activity-form">
                          {Object.keys(contentData[activityKey])
                            .filter(key => key.startsWith('Question'))
                            .sort()
                            .map((questionKey, questionIndex) => (
                              <div key={questionIndex} className="activity-question-container">
                                <p className="poll-question question-number" dangerouslySetInnerHTML={{ __html: boldQuotedText(contentData[activityKey][questionKey]) }}></p>
                                <div className='activity-input-holder'>
                                  <textarea
                                    className="activity-input"
                                    placeholder="Place your answer here..."
                                    disabled={isActivityAnswered(activityIndex, questionIndex)}
                                    value={activityAnswers[`${activityIndex}_${questionIndex}`] || ''}
                                    onChange={(e) => setActivityAnswers({ ...activityAnswers, [`${activityIndex}_${questionIndex}`]: e.target.value })}
                                  />
                                  <div className="course-button-container">
                                    <button
                                      type="button"
                                      className="poll-submit-button"
                                      disabled={isActivityAnswered(activityIndex, questionIndex) || !activityAnswers[`${activityIndex}_${questionIndex}`]}
                                      onClick={() => handleActivitySubmit(activityIndex, questionIndex)}
                                    >
                                      Submit
                                    </button>
                                    {isActivityAnswered(activityIndex, questionIndex) && (
                                      <button type="button" className="poll-edit-button" onClick={() => handleEditActivityResponse(activityIndex, questionIndex)}>
                                        Edit
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </form>
                      </div>
                    ))}
                </div>
              </section>
              <section id='closing-questions-section' className="course-section course-closing-questions-section">
                <h2 className="course-sub-title">❓ Closing Questions</h2>
                <div className="course-content course-closing-questions-content">
                  {Object.keys(contentData['Closing Questions'] || {}).filter(key => key.startsWith('Question')).sort().map((questionKey, index) => (
                    <div key={questionKey} className="poll-item poll-card">
                      <button
                        className="view-results-button"
                        onClick={() => {
                          const url = `/view-poll-results/${encodeURIComponent(schoolID)}/${encodeURIComponent("Day 2")}/${encodeURIComponent(questionKey)}/${encodeURIComponent(contentData["Closing Questions"][questionKey])}/closingQuestion`;
                          window.open(url, '_blank');
                        }}
                      >
                        View Results
                      </button>
                      <h3 className="poll-label">❓ Question {index + 1}</h3>
                      <p className='poll-question'>{contentData['Closing Questions'][questionKey]}</p>
                      <form className="poll-form" onSubmit={(e) => handleQuestionSubmit(e, index)}>
                        <textarea
                          type="text"
                          className="activity-input"
                          placeholder="Type your answer here..."
                          disabled={isQuestionAnswered(index)}
                          value={questionAnswers[index] || ''}
                          onChange={(e) => setQuestionAnswers({ ...questionAnswers, [index]: e.target.value })}
                        />
                        <div className="course-button-container">
                          <button
                            type="submit"
                            className="poll-submit-button"
                            disabled={isQuestionAnswered(index) || !questionAnswers[index]}
                          >
                            Submit
                          </button>
                          {isQuestionAnswered(index) && (
                            <button type="button" className="poll-edit-button" onClick={() => handleEditQuestionResponse(index)}>Edit</button>
                          )}
                        </div>
                      </form>
                    </div>
                  ))}
                </div>
              </section>
              <section id='completion-summary-section' className="course-completion-section">
                <h2 className="course-completion-title">🎉 Day 2 Completion Summary</h2>
                <div className="course-completion-content">
                  <p className="completion-intro">You've completed the following:</p>
                  <ul className="completion-list">
                    <li className="completion-item">
                      <span className="completion-label">Lecture</span>
                      <ScrollLink to="lecture-section" smooth={true} duration={50} className={`completion-status ${isLectureComplete ? 'completed' : 'go-to-section'}`}>
                        {isLectureComplete ? 'Completed' : '⬆️ Incomplete, Go to Section ⬆️'}
                      </ScrollLink>
                    </li>
                    <li className="completion-item">
                      <span className="completion-label">Resources</span>
                      <ScrollLink to="resources-section" smooth={true} duration={50} className={`completion-status ${isResourcesComplete ? 'completed' : 'go-to-section'}`}>
                        {isResourcesComplete ? 'Completed' : '⬆️ Incomplete, Go to Section ⬆️'}
                      </ScrollLink>
                    </li>
                    {Object.keys(contentData || {})
                      .filter(key => key.startsWith('Activity'))
                      .sort((a, b) => {
                        const numA = parseInt(a.replace('Activity', ''), 10);
                        const numB = parseInt(b.replace('Activity', ''), 10);
                        return numA - numB;
                      })
                      .map((activityKey, index) => (
                        <li key={activityKey} className="completion-item">
                          <span className="completion-label">Activity {index + 1}</span>
                          <ScrollLink to={activityKey} smooth={true} duration={50} className={`completion-status ${completedActivities[index] ? 'completed' : 'go-to-section'}`}>
                            {completedActivities[index] ? 'Completed' : '⬆️ Incomplete, Go to Section ⬆️'}
                          </ScrollLink>
                        </li>
                      ))}
                    <li className="completion-item">
                      <span className="completion-label">Closing Questions</span>
                      <ScrollLink to="closing-questions-section" smooth={true} duration={50} className={`completion-status ${areQuestionsComplete ? 'completed' : 'go-to-section'}`}>
                        {areQuestionsComplete ? 'Completed' : '⬆️ Incomplete, Go to Section ⬆️'}
                      </ScrollLink>
                    </li>
                  </ul>
                  <div className="completion-buttons">
                    <a href="#contact" className="contact-button">
                      Contact Support
                    </a>
                    <Link to={`/training/${schoolID}/day3`} type="button" className="next-day-button">
                      Continue to Next Day ➥
                    </Link>
                  </div>
                </div>
              </section>

            </div>
          </section>
          <Footer id="contact" />
        </div>
      )}
    </body>

  );
};

export default DayTwoCourse;
