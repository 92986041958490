import React, { useEffect, useState } from 'react';

const GetStarted = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (!showButton && window.pageYOffset > 250) {
        setShowButton(true);
      } else if (showButton && window.pageYOffset <= 250) {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, [showButton]);

  const handleClick = () => {
    // Opens the link in a new tab
    window.open('https://gruvyeducation.com/trainingportalapplication', '_blank');
  };

  return (
    showButton && (
      <button className="tesimonials-floating-btn" onClick={handleClick}>
        ✓ Get Started
      </button>
    )
  );
};

export default GetStarted;
