import React, { useLayoutEffect } from 'react';
import Footer from './Footer';
import Logo from "../images/logo-no-background.png";
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import db from '../Firebase'
import Doc from '../images/doc.png'
import { useState } from 'react';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { useEffect } from 'react';
import Nav from './Nav'
import { ScrollLink } from 'react-scroll';

const PromptLibrary = () => {
    const [title, setTitle] = useState('All Prompts');
    const [selectedCategory, setSelectedCategory] = useState('All Prompts');
    const [prompts, setPrompts] = useState([])
    const promptsRef = collection(db, "Prompts")
    const [categories, setCategories] = useState(['All Prompts']); // Initialize with default 'All Prompts'

    useEffect(() => {
        const getCategories = async () => {
            const categoriesDoc = await getDoc(doc(db, "Prompts", "000categories"));
            if (categoriesDoc.exists()) {
                const categoriesData = categoriesDoc.data();
                // Extract categories and sort them alphabetically
                const sortedCategories = Object.keys(categoriesData.AllCategories).sort((a, b) => a.localeCompare(b));
                // Prepend 'All Prompts' to the sorted categories
                setCategories(['All Prompts', ...sortedCategories]);
            }
        };


        const getPrompts = async () => {
            const data = await getDocs(promptsRef);
            setPrompts(data.docs
                .filter(doc => doc.id !== "000categories") // Exclude the categories document
                .map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }))
            );
        };

        getCategories();
        getPrompts();
    }, []);


    const Dropdown = ({ categories, setTitle, setSelectedCategory, title }) => {
        const [isActive, setIsActive] = useState(false);

        return (
            <>
                <label htmlFor="touch">
                    <span className={`dropSpan ${isActive ? "active" : ""}`} onClick={() => setIsActive(!isActive)}>
                        {title}
                    </span>
                </label>
                <input type="checkbox" id="touch" />
                <ul className="slide">
                    {categories.map((option, index) => (
                        <li key={index} className='dropLi' onClick={() => {
                            setTitle(option);
                            setSelectedCategory(option);
                            setIsActive(false); // Close dropdown after selection
                        }}>
                            <div className='dropA'>{option}</div>
                        </li>
                    ))}
                </ul>
            </>
        );
    };

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const openNavOptions = () => {
        document.getElementById("barsBtn").style.display = "none"
        document.getElementById("navPopup").style.display = "flex"
    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
    }

    const scrollTo = (string, num) => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }

    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ChatGPT Prompt Library for Educators | Gruvy Education</title>
                <link href="https://gruvyeducation.com/promptlibrary" />
                <meta
                    name="keywords"
                    content="Gruvy Education, AI in Education, AI Tools, ChatGPT, Prompt Library, AI for Educators, AI Teaching Resources, ChatGPT Prompts for Teachers"
                />
                <meta
                    name="description"
                    content="Explore Gruvy Education's ChatGPT Prompt Library and discover innovative ways to engage students in an AI-driven learning environment."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="ChatGPT Prompt Library for Educators | Gruvy Education" />
                <meta property="og:description" content="Explore Gruvy Education's ChatGPT Prompt Library and discover innovative ways to engage students in an AI-driven learning environment." />
                <meta property="og:url" content="https://gruvyeducation.com/promptlibrary" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <section className="section-one bg-ghost-white">
                    <div className="title">Educator Prompt Library</div>
                    <Dropdown categories={categories} setTitle={setTitle} setSelectedCategory={setSelectedCategory} title={title} />
                    <div className="promptButtons">
                        <a target='_blank' href="https://app.tango.us/app/workflow/How-to-use-Gruvy-Education-s-AI-in-Education-Training-Documents-6f9a9301f7b04913b467c31337738fe3" className="promptButton">How to Use Documents</a>
                        <a target='_blank' href="https://docs.google.com/forms/d/e/1FAIpQLSffr0L0RTAako2hDYur4UAlit7_fzsoorGFaAILA7AsF8cALA/viewform?usp=sf_link" className="promptButton">Request Prompt to be Added</a>
                        <a target='_blank' href="https://chat.openai.com/" className="promptButton">Open ChatGPT</a>

                    </div>
                    <div className="subTitle2 subTitle3">{title}</div>
                    <div className="container section-one-content">
                        <div className="grid">
                            {
                                prompts
                                    .filter(prompt => prompt.Category === selectedCategory || selectedCategory == 'All Prompts')
                                    .sort((a, b) => {
                                        if (a.Category < b.Category) return -1;
                                        if (a.Category > b.Category) return 1;
                                        return 0;
                                    })
                                    .map((prompt) => {
                                        return (
                                            <a href={prompt.Link} target="_blank" className="grid-item text-center bg-white">
                                                <img src={Doc} />
                                                <h3>{prompt.Title}</h3>
                                                <p className="text">{prompt.Category}</p>
                                            </a>
                                        );
                                    })
                            }
                        </div>
                    </div>
                </section>
                <section className="section-two" >
                    <h2 className='pollsTitle' id='about'>Learn more about Gruvy Education</h2>
                    <div className="container section-two-content">
                        <div className="accordion">
                            <Link to="/testimonials" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Testimonials</span>
                                </div>
                            </Link>
                            <Link to="/about" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">About Us</span>
                                </div>
                            </Link>
                            <a target="_blank" href="https://usemotion.com/meet/william-grube/meeting?d=30" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Schedule a Virtual Meeting</span>
                                </div>
                            </a>
                            <ScrollLink to="contact" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Send us a message</span>
                                </div>
                            </ScrollLink>
                        </div>
                        <div className="info text-center">
                            <div className="section-title">
                                <h2>Join our Facebook Group</h2>
                            </div>
                            <p className="text">Join our community of educators staying ahead of the curve with AI.</p>
                            <a target='_blank' href="https://www.facebook.com/groups/630420622459086/" className="btn">Join</a>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </body>
    );
}

export default PromptLibrary;
