import React from 'react'
import { useNavigate, Outlet } from 'react-router'
import TrainingAuth from "../Components/TrainingAuth"
import { UserAuth } from '../Context/authContext'

export default function protectedRoute() {

    const {user} = UserAuth()
    return user || localStorage.getItem('schoolid') == null ? <Outlet/> : <TrainingAuth/> 
}
