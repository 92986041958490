import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase auth
import emailjs from 'emailjs-com';

const GoogleApplication = () => {
    const [emailSent, setEmailSent] = useState(false); // Flag to prevent multiple sends

    useEffect(() => {
        const auth = getAuth();

        // Listen for auth state change to get the user once they are authenticated
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && user.email && !emailSent) {
                const email = user.email;

                // Prepare data for EmailJS
                const serviceID = 'service_1g4hajp';
                const templateID = 'template_kh8okno';
                const userID = 'user_HAMTDSUiL200UN9RQyPHI';

                const emailData = {
                    user_email: email, // Logged-in user's email
                };

                // Send the email in the background
                emailjs.send(serviceID, templateID, emailData, userID)
                    .then(() => {
                        setEmailSent(true); // Set the flag to true after email is sent
                        // Redirect to the Google Form immediately after sending
                        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link';
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        // Redirect even if there's an error, without showing anything to the user
                        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link';
                    });
            } else if (!user) {
                // If no user is logged in, redirect to the form immediately
                window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link';
            }
        });

        // Cleanup the subscription to avoid memory leaks
        return () => unsubscribe();
    }, [emailSent]); // Include the emailSent state in the dependency array

    return null; // No UI is rendered
};

export default GoogleApplication;
