import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './CanjoFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavCanjo from './NavCanjo2';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import Guide3 from '../../images/Guide3.png';
import Guide1 from '../../images/Guide1.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import OverWelm from '../../images/OverWelm.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'
import Custom from '../../images/Custom.png'
import Policy from '../../images/Policy.png'
import Practical from '../../images/Practical.png'
import DataAnalysis from '../../images/DataAnalysis.png'
import Magic1 from '../../images/Magic1.png'
import Magic2 from '../../images/Magic2.png'
import SchoolAI from '../../images/SchoolAI.jpg'
import Abe from '../../images/Abe.png'
const CanjoSession2 = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavCanjo module="home" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">Engaging Students with AI Tools</div>
                        </div>
                    </header>
                    <div className="course-container">
                        <div className="MoASSP-course-navbar">
                            <ScrollLink
                                activeClass="active"
                                to="william"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">🗺️🚀</span> Session Roadmap
                                </div>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">📞🎤</span> Contact William
                                </div>
                            </ScrollLink>

                        </div>
                        <section id="william" className="MoASSP-course-section MoASSP-activities-section">
                            <h2 className="MoASSP-course-sub-title">🗺️🚀 Roadmap</h2>
                            <div className="MoASSP-activities-wrapper">
                                {/* Navigation bar */}
                                <div className="MoASSP-activity-nav-bar">
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="1" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">1</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="2" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">2</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="3" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">3</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="4" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">4</ScrollLink>
                                </div>
                                {/* Activity 1 */}
                                <div key="1" id="1" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 1: </span>
                                        How to Use AI Responsibly with Students
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/1px7ZjoCuOA-CpwdQl2pC_QOhiXem-o3M/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={StudentGuide} alt="AI Classroom Use" />
                                                    <span className="MoASSP-link-label">AI Classroom Use: Guidelines for Students</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/1Chyf1e2BrPwGHod7HNFucM6agBKHQVzq/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={TeacherChecklist} alt="Teacher's Checklist" />
                                                    <span className="MoASSP-link-label">AI in Education: Teacher's Checklist for Success</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={DataGuide} alt="Data Privacy Guide" />
                                                    <span className="MoASSP-link-label">Gruvy Education's Guide to Data Privacy With AI</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Explore the student responsible use resources in the 'Links Needed for Activity' section.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="2" id="2" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 2: </span>
                                        How to Use AI with Students: MagicStudent
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://app.magicschool.ai/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Magic} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">MagicSchool</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/1pzzLJeGkwmN1JQTRVhGXiVoareH6EufTozjtZ6O5I98/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Magic2} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Simple Guide: How to Use MagicSchool's MagicStudent</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the "How to Use MagicSchool's MagicStudent" guide in the "Links Needed for Activity" section. Let's read through it and test it out.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="3" id="3" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 3: </span>
                                        Creating an AI-Integrated In-Class Activity with AI
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/1vfR2w7mAysWTXXCqHlJJ-ZIJACWPhlF0NbsDErnR2To/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Doc} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Prompt Document</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Using the prompt document and ChatGPT, create an AI-integrated in-class activity. In the first prompt of the document, replace the "[specific chat bot]" variable with the MagicStudent tool you plan to use in the classroom. For example, if you want to use an "Abe Lincoln Chatbot," enter "Abe Lincoln Chatbot" in place of "[specific chat bot]" in the prompt. Follow all steps listed in the prompt document.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="4" id="4" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 4: </span>
                                        Exploring SchoolAI
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://app.schoolai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={SchoolAI} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">SchoolAI</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://app.schoolai.com/space?code=BPQ5" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Abe} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Abe</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Click on the "Abe" link in the "Links Needed for Activity" section to see the student side of SchoolAI and interact with Abe Lincoln! Explore the student side by chatting with Abe Lincoln.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Click on the "SchoolAI" link in the "Links Needed for Activity" section to access the teacher side of SchoolAI. Once there, create an account.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>3.</strong> "Spaces" are personalized chatbots you can send to students. As a teacher, when you create a space, students can chat one-on-one with the chatbot of your choosing. Press the big "Find a Space" button to explore the thousands of spaces. Choose a space that piques your interest.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>4.</strong> After you find a space you would like your students to chat with, select the space by clicking on it. On the right side of your screen, you will see a preview box. Test out the chatbot before you launch it to students. When ready, press the "Launch" button.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>5.</strong> Paste the link into a new tab on your browser. Interact with the student side of the chatbot you just launched by asking it something your students might ask.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>6.</strong> Return to the teacher side of SchoolAI to review the interaction. In "Recent Sessions," click on the chatbot you just launched to view the chat history.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>7.</strong> Finally, click out of the chat history by clicking on the "X" in the top right corner. You will see an emoji indicating student progress: a smiley face for students on track and a frowny face for those using it inappropriately.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default CanjoSession2;
