import React, { useEffect, useLayoutEffect, useState } from 'react';
import NavMoASSPOct28 from './NavMoASSPOct28';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Footer from '../Footer';

const MoASSPOct28AboutGruvyEducationBreakout = () => {
    const [email, setEmail] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('');

        // Simple email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        // Open the application form in a new tab
        window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link',
            '_blank'
        );

        // Prepare data for EmailJS
        const serviceID = 'service_1g4hajp';
        const userID = 'user_HAMTDSUiL200UN9RQyPHI'; // Your EmailJS user/public key

        // Send email to the user
        const emailToUser = emailjs.send(
            serviceID,
            'template_noz4kkb', // Template ID for the user
            { user_email: email },
            userID
        );

        // Send email to the site owner
        const emailToOwner = emailjs.send(
            serviceID,
            'template_8l7zltk', // Template ID for you
            { user_email: email },
            userID
        );

        // Wait for both emails to be sent
        Promise.all([emailToUser, emailToOwner])
            .then(() => {
                setMessageSent(true);
                setEmail('');
            })
            .catch((error) => {
                console.error('Error:', error);
                setErrorMessage('An error occurred. Please try again later.');
            });
    };


    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className='holder'>
                <NavMoASSPOct28 module="about" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">About Gruvy Education's<br />AI Training</div>
                        </div>
                    </header>
                    <section className="MoASSP-section">
                        <div className="MoASSP-container MoASSP-container-about">
                            <blockquote className="MoASSP-testimonial noMarginTop">
                                "AI is the next frontier; sitting it out is not an option. This is the best training out there. We highly recommend Gruvy Education for their professionalism, knowledge, and ability to inspire educators."
                                <cite>- Superintendent, Harvey Public Schools</cite>
                            </blockquote>
                            <h2 className="MoASSP-section-title">Training Overview</h2>
                            <div className="MoASSP-modules">
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Duration:</h3>
                                    <p className="MoASSP-module-text">
                                        The training session duration range from 1 to 4 hours, offered both in-person or virtually. The base model consists of a 3 to 4-hour (half-day) training session. Additionally, flexible options include a 1-hour kick-off keynote, with the rest of the training delivered asynchronously.
                                    </p>
                                </div>
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Online Training Portal:</h3>
                                    <p className="MoASSP-module-text">
                                        The online training portal provides access to Gruvy Education's resources for one year. It supports both live training sessions and asynchronous learning, offering flexibility to meet various learning needs.
                                    </p>
                                </div>
                                
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Continuing Education:</h3>
                                    <p className="MoASSP-module-text">
                                        Participants of the training have the opportunity to receive 10 hours of continuing education credits, along with four university-accredited badges through North Dakota State University (NDSU). There is also an option to enroll for additional credit through NDSU. <b>For continued support, participants will have access to monthly webinars.</b>
                                    </p>
                                </div>
                            </div>

                            {/* <h2 className="MoASSP-section-title">Key Stats</h2>
                            <div className="MoASSP-stats">
                                <div className="MoASSP-stat">
                                    <h3 className="MoASSP-stat-number">3.23 hours</h3>
                                    <p className="MoASSP-stat-text">
                                        Saved every week by teachers on preparation, content creation, and administrative tasks—over 100 hours a year per teacher!
                                    </p>
                                </div>
                                <div className="MoASSP-stat">
                                    <h3 className="MoASSP-stat-number">24.93%</h3>
                                    <p className="MoASSP-stat-text">
                                        Increase in student engagement while using technology in lessons after using AI in the classroom.
                                    </p>
                                </div>
                                <div className="MoASSP-stat">
                                    <h3 className="MoASSP-stat-number">25.87%</h3>
                                    <p className="MoASSP-stat-text">
                                        Increase in confidence in meeting the needs of diverse learners after taking the AI training.
                                    </p>
                                </div>
                                <div className="MoASSP-stat">
                                    <h3 className="MoASSP-stat-number">100+</h3>
                                    <p className="MoASSP-stat-text">
                                        Schools have adopted the training. The training is recommended by the Minnesota Association of Secondary School Principals and several other state level organizations.
                                    </p>
                                </div>
                            </div> */}
                            <h2 className="MoASSP-section-title">Training Content</h2>
                            <div className="MoASSP-modules">
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Module 1: AI Overview and Shifting Perspectives</h3>
                                    <p className="MoASSP-module-text">
                                        Module 1 will shift participants' perspectives from fearing AI to leveraging it as a powerful tool. Key takeaways include understanding AI's presence and importance in education, learning how to capitalize on AI's benefits while mitigating potential risks, and engaging in hands-on demos that allow for experimentation with AI without fear.
                                    </p>
                                    <blockquote className="MoASSP-module-testimonial">
                                        "I was probably the most pessimistic person when it came to the in-service today. You changed that. The material was presented in the most understandable way. Thank you for that. I plan to utilize this starting next week in my class."
                                        <cite>- AI Training Participant, Alexander Public Schools</cite>
                                    </blockquote>
                                </div>
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Module 2: Tackling Student Cheating</h3>
                                    <p className="MoASSP-module-text">
                                        Module 2 focuses on using AI tools to create AI-resistant assignments and reduce cheating. Key takeaways include learning how to effectively use ChatGPT and Magic School, designing assignments that promote critical thinking beyond AI's capabilities, and exploring AI text detection tools. This module is applicable to everyone, offering essential tools and providing high school teachers with crucial strategies to ensure students continue learning effectively in the AI era.
                                    </p>
                                    <blockquote className="MoASSP-module-testimonial">
                                        "I really felt as though AI was not applicable to me as an early elementary teacher. I thought it would be a workshop helping high school teachers prevent students from using it on assignments. Wow, I was wrong! I can't wait to use AI tools to help enhance my teaching and students' experiences!"
                                        <cite>- AI Training Participant at Deuel School District</cite>
                                    </blockquote>
                                </div>
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Module 3: Responsible Student Use</h3>
                                    <p className="MoASSP-module-text">
                                        Module 3 focuses on how to use AI in the classroom to boost student engagement and critical thinking. Key takeaways include an introduction to Magic Student, the student side of Magic School, strategies for implementing AI to enhance student engagement, and fostering responsible AI usage among students.Teachers who complete the training report seeing a <b>24.93%</b> increase in student engagement when using AI in lessons.
                                    </p>
                                    <blockquote className="MoASSP-module-testimonial">
                                        "The most beneficial way that AI is now used in our district is how it has helped increase student engagement in the classroom."
                                        <cite>- Brian Christopherson, Superintendent, New Salem-Almont School District</cite>
                                    </blockquote>
                                </div>
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Module 4: Enhancing Teaching Efficiency with AI</h3>
                                    <p className="MoASSP-module-text">
                                        Module 4 focuses on using AI to save time and improve the student learning experience. Key takeaways include learning how teachers who complete the training save an average of <b>3.23</b> hours per week on preparation and administrative tasks, resulting in over 100 hours saved per year per teacher. Additionally, after completing the AI training, teachers' confidence in meeting the needs of diverse learners increases by about <b>25.87%.</b> This module also covers strategies for modifying assignments to accommodate diverse learning needs.
                                    </p>
                                    <blockquote className="MoASSP-module-testimonial">
                                        “This is the best and most valuable PD course I have taken since I began teaching.”
                                        <cite>- AI Training Participant, Kidder County School</cite>
                                    </blockquote>
                                </div>
                            </div>

                            <h2 className="MoASSP-section-title">Bonus Resources and Support</h2>
                            <ul className="MoASSP-resources-list">
                                <li>1-year access to Gruvy Education’s online training portal.</li>
                                <li>Recordings of live training sessions for easy review.</li>
                                <li>Interactive polls, discussions, and bite-sized, on-demand activities with immediate application to teaching practice.</li>
                                <li>Comprehensive resource libraries, including a student data privacy guide, responsible student-use guide, and framework for designing AI prompts.</li>
                                <li>Over 50 video tutorials demonstrating how to use AI as a tool, featuring strategies like creating rubrics in minutes to save hours of work.</li>
                                <li>Periodic virtual webinars and regular updates on new AI tools and resources.</li>
                                <li>Detailed reports for administrators to track staff progress.</li>
                                <li>Bonus role-specific courses for administration, special education, ESL teachers, counselors, music teachers, sports coaches, and physical education teachers.</li>
                                <li>Opportunity to complete the training for 10+ hours of continuing education.</li>
                                <li>Earn four university-accredited badges through North Dakota State University.</li>
                                <li>Option to enroll for credit through NDSU.</li>
                            </ul>
                            <h3 className="MoASSP-section-title">Pricing</h3>
                            <ul className="MoASSP-resources-list">
                                <li><strong>In-Person Training - Out-of-State Schools</strong> (All states except MN, ND, SD): Base Price: $6,000 + $30 per educator. Travel expenses are additional and include flights and accommodations.</li>
                                <li><strong>In-Person Training - In-State Schools</strong> (Minnesota, North Dakota, South Dakota): Base Price: $4,000 + $30 per educator. Travel expenses are $0.67 per mile from Fargo, ND.</li>
                                <li><strong>Virtual Training</strong>: $3,000 + $30 per educator.</li>
                                <li><strong>Asynchronous Access</strong>: $2,000 + $30 per educator.</li>
                            </ul>
                            <h2 className="MoASSP-section-title">Testimonials from Leaders</h2>

                            <blockquote className="MoASSP-testimonial">
                                "William is well-spoken and can captivate any audience with useful tools to enhance their teaching practice and lessen their workload while at the same time creating better opportunities for student learning. His training portal in addition to his live presentation reinforces the skills/tools that we are trying to build instead of the traditional sit 'n get and quickly forget. 10/10 would recommend his training."
                                <cite>- Superintendent from Adrian Public Schools</cite>
                            </blockquote>

                            <blockquote className="MoASSP-testimonial">
                                "I have been in numerous AI trainings over the last year. This was by far the best one I have been a part of. It is organized and has supporting resources and tangible strategies for making effective use of AI tools. Everyone talks about AI being bad and ways that schools will combat it. On that pathway, education falls behind and has to catch up to this new tech once they realize it isn’t going away. This presentation begins the work of finding ways to effectively incorporate AI into what we do instead of demonizing it."
                                <cite>- MS/HS Principal from Royalton Public Schools</cite>
                            </blockquote>

                            <blockquote className="MoASSP-testimonial">
                                "Since integrating AI into our school operations, we’ve seen a remarkable improvement in both efficiency and communication. The training empowered our staff to embrace new tools that streamline routine tasks, allowing them to focus more on teaching and addressing student needs. The AI support has minimized communication breakdowns, which are often at the heart of operational challenges, and has brought more clarity and consistency across the board. This AI training has truly transformed the way we work, improving the overall flow of our school."
                                <cite>- Superintendent at Alexander Public Schools</cite>
                            </blockquote>

                            <blockquote className="MoASSP-testimonial">
                                "Every person on my staff benefitted from this training. We had no idea the multitude of ways that AI could be used for education, including making an educator's job easier! So many people came up to thank me for the training."
                                <cite>- Principal at John R. Beatty Elementary School</cite>
                            </blockquote>

                            <blockquote className="MoASSP-testimonial">
                                "William Grube's AI training was one of the best trainings our staff have received. He clearly explained AI concepts and how our staff could use AI effectively in our classrooms. William presented a lot of material in a relatively short amount of time but it was not overwhelming. Our staff left his training excited about the possible uses of AI."
                                <cite>- Elementary School Principal from Luverne Public Schools</cite>
                            </blockquote>

                            <blockquote className="MoASSP-testimonial">
                                "Having our entire staff trained with Gruvy Education, now has all our staff talking the same language and sharing findings and tips and skills they have developed within our school."
                                <cite>- Superintendent from Wyndmere Public School</cite>
                            </blockquote>

                            <blockquote className="MoASSP-testimonial">
                                "The training through Gruvy Education showed our staff the possibilities of AI and helped lessen the fears many have associated with it. They are shown ways that AI can be used to support learning in a positive way as well as ways they can use it to improve efficiency in their day-to-day planning. It opens staff's eyes to the positive possibilities of AI and lessens the fears many have associated with it."
                                <cite>- Director of Teaching and Learning at Albertville School District</cite>
                            </blockquote>

                            <blockquote className="MoASSP-testimonial">
                                "This particular training provided our staff with a hands-on approach to using AI, and they left the training with a toolbox of resources and the 'know-how' to use it."
                                <cite>- Superintendent from Deuel School District</cite>
                            </blockquote>

                            <blockquote className="MoASSP-testimonial">
                                "If you are not getting your staff ready for AI and where it is headed, you are behind. ChatGPT has increased my productivity greatly over the last two school years, and the resources your staff will gain will help them improve proficiency and increase classroom productivity."
                                <cite>- High School Principal from Napoleon Public School</cite>
                            </blockquote>

                            <blockquote className="MoASSP-testimonial">
                                "William is by far one of the best trainers and presenters we have had during my 20 years in education. Very knowledgeable, presented well, and efficient. In the education world time is very valuable and finding tools and resources that can help give back some of that time to our professionals is critical. Very glad we utilized this training for our staff. It was very impactful for our staff. As a leader you are always looking for ways to help push your staff forward and this training most certainly did this for all staff. Older, veteran staff and young staff all took away something that they could use to make their teaching load a little easier. I still hear staff talking about how they are using it now regularly within their teaching day."
                                <cite>- Director at Southeast Region Career and Technology Center</cite>
                            </blockquote>

                            <div className="MoASSP-contact-section" id="apply">
                                <h2 className="MoASSP-section-title">
                                    Interested in Bringing Gruvy Education to Your School?
                                </h2>
                                <p className="MoASSP-text">Enter your email below to apply.</p>
                                {!messageSent ? (
                                    <>
                                        <form className="MoASSP-contact-form" onSubmit={handleSubmit}>
                                            <input
                                                type="email"
                                                className="MoASSP-input"
                                                placeholder="Enter your email to apply."
                                                aria-label="Enter your email to apply."
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <button type="submit" className="MoASSP-submit-button">
                                                Apply
                                            </button>
                                        </form>
                                        {errorMessage && (
                                            <p className="MoASSP-error-message">{errorMessage}. Please contact william.grube@gruvyeducation.com directly.</p>
                                        )}
                                    </>
                                ) : (
                                    <p className="MoASSP-success-message">
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            style={{ color: '#005d41', marginRight: '10px' }}
                                            className='yayFontAwesome'
                                        />
                                        Thank you for your interest! We'll be in touch soon.
                                    </p>
                                )}
                            </div>
                        </div>
                    </section>
                </section>
                <footer id="contact" className="MoASSP-footer">
                    <div className="MoASSP-footer-container">
                        <div className="MoASSP-footer-section">
                            <h4 className="MoASSP-footer-heading">About Gruvy Education</h4>
                            <p className="MoASSP-footer-text">
                                We deliver AI training to schools and major educational associations nationwide.
                            </p>
                        </div>
                        <div className="MoASSP-footer-section">
                            <h4 className="MoASSP-footer-heading">Contact</h4>
                            <a className="MoASSP-footer-link" href="mailto:william.grube@gruvyeducation.com">
                                Email: william.grube@gruvyeducation.com
                            </a>
                            <a className="MoASSP-footer-link" href="tel:507-476-8096">
                                Phone: 507-476-8096
                            </a>
                        </div>
                        <div className="MoASSP-footer-section">
                            <h4 className="MoASSP-footer-heading">Follow Us</h4>
                            <a
                                className="MoASSP-footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://twitter.com/Gruvy_edu"
                            >
                                Twitter
                            </a>
                            <a
                                className="MoASSP-footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.linkedin.com/in/william-grube-16200a177/"
                            >
                                LinkedIn
                            </a>
                            <a
                                className="MoASSP-footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/profile.php?id=100092009478609"
                            >
                                Facebook
                            </a>
                        </div>
                    </div>
                    <div className="MoASSP-footer-bottom">
                        <p className="MoASSP-footer-copyright">
                            &copy; {new Date().getFullYear()} Gruvy Education | All rights reserved.
                        </p>
                    </div>
                </footer>
            </div>
        </body>
    );
};

export default MoASSPOct28AboutGruvyEducationBreakout;
