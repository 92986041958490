import { useState, useEffect } from 'react'
import React, { useLayoutEffect } from 'react';
import Footer from './Footer';

import emailjs from "emailjs-com"
import { Helmet } from 'react-helmet';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import Logo from "../images/logo-no-background.png" 

import Nav from './Nav'
import db from '../Firebase'
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom';
import { ScrollLink } from 'react-scroll';

const fetch = require('node-fetch');
const GroupProjectGenerator = () => {
    const [userName, setUserName] = useState('')
    const [users, setUsers] = useState([])
    const [step2ran, setSet2ran] = useState(false)
    const [userEmail, setUserEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [topic, setTopic] = useState('');
    const [grade, setGrade] = useState('Pre-School');
    const [isLoading, setIsLoading] = useState(false);
    const [countDown, setCountDown] = useState(60);
    const [ideasResponse, setIdeasResponse] = useState(null)
    const [ideaChoice, setIdeaChoice] = useState('')
    const [activityResponse, setActivityResponse] = useState(null)
    const [activityResponseArray, setActivityResponseArray] = useState(null)
    const [dots, setDots] = useState('')
    const [docDetails, setDocDetails] = useState(null)
    const [step, setStep] = useState('step1')
    const [ideas, setIdeas] = useState([])
    const [haveActivityResponse, setHaveActivityResponse] = useState(false)
    const [errorOccured, setErrorOccured] = useState(false)
    const usersRef = collection(db, "AI Tools Users")
    const errorRef = collection(db, "AI Tool Error")
    const toolDoc = doc(db, 'AI Tools', 'KFGqGJoDyH3pHPAmx9yh')

    const onStarClick = async (newRating) => {
        const ratingsRef = collection(db, 'Ratings');
        await addDoc(ratingsRef, {
            Rating: newRating,
            Name: userName,
            Email: userEmail,
            AIToolName: docDetails.Name, // replace with your tool's name
            Response: activityResponseArray, // assuming this is the response to be saved
        });
    }

    useEffect(() => {
        const fetchToolData = async () => {
            const toolDocSnapshot = await getDoc(toolDoc);
            if (toolDocSnapshot.exists()) {
                // the document exists
                const toolData = toolDocSnapshot.data()
                setDocDetails(toolData)
            } else {
                // the document does not exist
                console.log("No such document!");
            }
        }
        fetchToolData()
    }, [])



    useEffect(() => {
        const getusers = async () => {
            const data = await getDocs(usersRef)
            setUsers(data.docs.map((doc) => ({
                ...doc.data(), id: doc.id
            })))
        }
        getusers()
    }, [])

    const resetForm = () => {
        // Reset each field state here
        setUserName("");
        setUserEmail("");
        setSubject("");
        setTopic("");
        setGrade("Pre-School");
        setCountDown(120)
        // Also reset the form step
        setStep("step1");
        window.location.reload();
    };


    async function downloadAsWord(e) {
        e.preventDefault();
        let htmlContent = activityResponseArray.map((para) => {
            return `<p className="activityTxt white">${para}<br /></p>`;
        }).join('');

        // Create a Blob object from HTML content
        const converted = htmlDocx.asBlob(`<html><body>${htmlContent}</body></html>`);

        // Trigger download
        saveAs(converted, 'document.docx');
    };

    const prompt1 = "Listing the ideas as '1.', '2.', an '3.'. As a " + grade + " " + subject + " teacher, I need 3 ideas for group projects related to the topic of " + topic + ". Please provide a list of engaging, collaborative project ideas where students must work in groups. These project ideas should encourage teamwork, critical thinking, creativity, and in-depth understanding of the subject matter. Ensure the ideas are tailored to the topic of " + topic + " and promote active learning."

    const prompt2 = "Explain the detailed steps and guidelines for implementing this group project: \"" + ideaChoice + "\" in my " + grade + " " + subject + " classroom. Be sure to include all necessary materials, preparation, instructions for students, and ideas for assessment or reflection. Also give me steps to understand how to successfully integrate this activity into my lesson plan to promote critical thinking, creativity, and active learning among my students."

    useEffect(() => {
        let intervalId;
        // Start the countdown when isLoading is true
        if (isLoading && countDown > 0) {
            intervalId = setInterval(() => {
                setCountDown(countDown => countDown - 1);
            }, 1000);
            dots == '' ? setDots('.') : void (0)
            dots == '.' ? setDots('..') : void (0)
            dots == '..' ? setDots('...') : void (0)
            dots == '...' ? setDots('....') : void (0)
            dots == '....' ? setDots('') : void (0)
        } else if (!isLoading && countDown !== 60 && step == 'step1') {
            // Reset the countdown when isLoading is false
            setCountDown(60);
        } else if (isLoading && countDown === 0) {
            // If isLoading is true and countDown reaches 0, stop the loading
            setIsLoading(false);
        }

        // Clean up function
        return () => clearInterval(intervalId);
    }, [isLoading, countDown]);

    useEffect(() => {
        const runAsync = async () => {
            if (ideaChoice !== null && step == 'step2' && haveActivityResponse == false && step2ran == false) {
                await getMessages2();
                setHaveActivityResponse(true)
                setStep('step3');
            }
        };
        runAsync();
    }, [ideaChoice]);

    const pickIdea = (idea) => {
        setCountDown(120);
        scrollTo("aiToolsForm", 1)
        setIsLoading(true);
        setIdeaChoice(idea);
    }

    const handleFormSubmit = async e => {
        e.preventDefault();
        const userEmailWithId = users.map(user => ({ id: user.id, email: user.Email })); // Create an array of objects with id and email
        const foundUser = userEmailWithId.find(user => user.email === userEmail);
const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const year = now.getFullYear();
        const dateLastUsed = `${month}/${day}/${year}`; // Find the user object with the matching email
        window.scrollTo(0, 0)
        setIsLoading(true);

        const toolDocSnapshot = await getDoc(toolDoc);
        if (toolDocSnapshot.exists()) {
            const toolData = toolDocSnapshot.data();
            const currentUses = toolData.Uses;
            const updatedUses = currentUses + 1;
            await updateDoc(toolDoc, { Uses: updatedUses });
        }

        if (foundUser) {
            // If the user was found, update the 'TotalUses' field
            const userDocRef = doc(db, 'AI Tools Users', foundUser.id);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.data();
            const currentTotalUses = userData.AIGroupProjectUses || 0;
            const updatedTotalUses = currentTotalUses + 1;
            await updateDoc(userDocRef, { AIGroupProjectUses: updatedTotalUses });
        } else {
            // If the user was not found, add a new document
            await addDoc(usersRef, {
                Name: userName,
                Email: userEmail,
                AIGroupProjectUses: 1
            });
        }

        await getMessages1(); // remove this and replace with your actual request
        setStep('step2');
        setCountDown(60);
    };

    const getMessages2 = async () => {
        // console.log(prompt2)
        const options = {
            method: "POST",
            body: JSON.stringify({
                message: prompt2
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }
        try {
            const response = await fetch("https://us-central1-teachingduringtheriseofai.cloudfunctions.net/app/completion", options)
            // const response = await fetch("http://localhost:3000/completion", options)
            const data = await response.json()
            setActivityResponse(data.choices[0].message)
            setSet2ran(true)
        } catch (error) {
            console.error(error)
            setErrorOccured(true)
            const toolDocSnapshot = await getDoc(toolDoc);
            if (toolDocSnapshot.exists()) {
                const currentErrors = docDetails.Errors
                const updatedErrors = currentErrors + 1
                await updateDoc(toolDoc, { Errors: updatedErrors })
            }
            await addDoc(errorRef, {
                Name: userName,
                Email: userEmail,
                Error: error,
                timeStamp: Timestamp.fromDate(new Date()),
            })
        }
    }


    useEffect(() => {
        if (activityResponse) {
            // console.log(activityResponse.content)
            setActivityResponseArray(activityResponse.content.toString().split(/\n/))
            setIsLoading(false);
        }
    }, [activityResponse])

    // useEffect(() => {
    //     console.log(activityResponseArray); // Log the updated value of ideas
    // }, [activityResponseArray]);

    const getMessages1 = async () => {
        const options = {
            method: "POST",
            body: JSON.stringify({
                message: prompt1
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }
        try {
            const response = await fetch("https://us-central1-teachingduringtheriseofai.cloudfunctions.net/app/completion", options)
            // const response = await fetch("http://localhost:3000/completion", options)
            const data = await response.json()
            setIdeasResponse(data.choices[0].message)
        } catch (error) {
            console.error(error)
            setErrorOccured(true)
            const toolDocSnapshot = await getDoc(toolDoc);
            if (toolDocSnapshot.exists()) {
                const currentErrors = docDetails.Errors
                const updatedErrors = currentErrors + 1
                await updateDoc(toolDoc, { Errors: updatedErrors })
            }
            await addDoc(errorRef, {
                Name: userName,
                Email: userEmail,
                Error: error,
                timeStamp: Timestamp.fromDate(new Date()),
            })
        }
    }

    useEffect(() => {
        if (ideasResponse) {
            setIdeas(ideasResponse.content.toString().split(/\d+\.\s/));
            setIsLoading(false);
        }
    }, [ideasResponse]);

    // useEffect(() => {
    //     console.log(ideas); // Log the updated value of ideas
    // }, [ideas]);


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
    }

    const scrollTo = (string, num) => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }
    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AI Tool: Group Project Generator | Gruvy Education</title>
                <link   href="https://gruvyeducation.com/aitools/groupprojectgenerator" />
                <meta
                    name="keywords"
                    content="AI in education, Gruvy Education, AI Tools, Group Project Generator, AI for educators, Educational AI tools, Group project planning, Artificial Intelligence in teaching"
                />
                <meta
                    name="description"
                    content="Create effective group projects with Gruvy Education's Group Project Generator. See how our AI tools can enhance the learning process and group dynamics in your classroom."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="AI Tool: Group Project Generator | Gruvy Education" />
                <meta property="og:description" content="Create effective group projects with Gruvy Education's Group Project Generator. See how our AI tools can enhance the learning process and group dynamics in your classroom." />
                <meta property="og:url" content="https://gruvyeducation.com/aitools/groupprojectgenerator" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <section className="section-one bg-ghost-white">
                    <div className="title">Group Project Generator</div>
                    <div className="container section-one-content">
                        <div className="aiToolsFormContainer container">
                            <div className="buttonContainer backButtonContainer">
                                <Link to='/aitools' className="backBtn">⬅ Back to AI Tools</Link>
                            </div>
                            <div className="buttonContainer">
                                <input className="resetButton" type="button" onClick={resetForm} value="Reset" />
                            </div>
                            <form className="aiToolsForm" onSubmit={handleFormSubmit} id='aiToolsForm'>
                                {!errorOccured && !isLoading && step == 'step1' &&
                                    <div className='aiToolsFormContent'>
                                        <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>Complete the form to develop a group project.</h2>

                                        <label className="aiToolsFormLabel" htmlFor="name">Name:</label><br />
                                        <input className="aiToolsFormInput" placeholder='First Last' required type="text" id="name" name="name" value={userName} onChange={e => setUserName(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="email">Email:</label><br />
                                        <input className="aiToolsFormInput" placeholder='Enter Email' required type="text" id="email" name="email" value={userEmail} onChange={e => setUserEmail(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="subject">Subject:</label><br />
                                        <input className="aiToolsFormInput" placeholder='e.g. Math' required type="text" id="subject" name="subject" value={subject} onChange={e => setSubject(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="topic">Topic:</label><br />
                                        <input className="aiToolsFormInput" placeholder='e.g. Algebra' required type="text" id="topic" name="topic" value={topic} onChange={e => setTopic(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="grade">Grade Level:</label><br />
                                        <select required className="aiToolsFormSelect" id="grade" name="grade" value={grade} onChange={e => setGrade(e.target.value)}>
                                            <option value="Pre-School">Pre-School</option>
                                            <option value="Kindergarden">Kindergarden</option>
                                            <option value="1st-Grade">1st-Grade</option>
                                            <option value="2nd-Grade">2nd-Grade</option>
                                            <option value="3rd-Grade">3rd-Grade</option>
                                            <option value="4th-Grade">4th-Grade</option>
                                            <option value="5th-Grade">5th-Grade</option>
                                            <option value="6th-Grade">6th-Grade</option>
                                            <option value="7th-Grade">7th-Grade</option>
                                            <option value="8th-Grade">8th-Grade</option>
                                            <option value="9th-Grade">9th-Grade</option>
                                            <option value="10th-Grade">10th-Grade</option>
                                            <option value="11th-Grade">11th-Grade</option>
                                            <option value="12th-Grade">12th-Grade</option>
                                            <option value="College Freshman">College Freshman</option>
                                            <option value="College Sophmore">College Sophmore</option>
                                            <option value="College Junior">College Junior</option>
                                            <option value="College Senior">College Senior</option>
                                            <option value="College Masters Course">Masters Course</option>
                                            <option value="College Doctorate Course">Doctorate Course</option>
                                        </select>
                                        <br />
                                        <input className="aiToolsFormButton" type="submit" value="Generate Activities" />
                                    </div>}
                                {
                                    !errorOccured && !isLoading && step == 'step2' &&
                                    <div className='aiToolsFormContent'>
                                        <h2 className='text-center facebookTitle white step2txt'>Explore the ideas below for you {grade.toLowerCase()} {subject.toLowerCase()} classroom, each one leads to a full activity once clicked. If these don't appeal to you, hit 'Get Different Ideas' to generate a fresh list of options.</h2>

                                        {ideas.map((idea, index) => {
                                            return idea != "" ? <button key={index} onClick={() => pickIdea(idea)} className="aiToolsFormButton1">{idea}</button> : void (0)
                                        })}

                                        <button onClick={handleFormSubmit} className="aiToolsFormButton">Get Different Ideas</button>
                                    </div>
                                }
                                {
                                    !errorOccured && !isLoading && step == 'step3' &&
                                    <div className='aiToolsFormContent'>
                                        <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>Group project for your {grade.toLowerCase()} {subject.toLowerCase()} classroom:</h2>

                                        {activityResponseArray.map((para, index) => {
                                            return <p key={index} className="activityTxt white">{para}<br /></p>
                                        })}
                                        <div className="rateContainer">
                                            <label className='rateLabel'>Rate:</label>
                                            <ReactStars
                                                count={5}
                                                onChange={onStarClick}
                                                size={24}
                                                activeColor="#ffd700"
                                                style={{ marginTop: '8px' }}
                                            />
                                        </div>

                                        <button className="aiToolsFormButton" onClick={downloadAsWord}>Download as Word Document</button>
                                        <button className="aiToolsFormButton1" onClick={resetForm}>Create a New Activity (Note: You will lose the current one if not downloaded)</button>

                                        {/* <button className="aiToolsFormButton" onClick={downloadAsPdf}>Download as PDF</button> */}
                                    </div>

                                }
                                {isLoading && <div className='loadingTxt step2txt'>Creating a group project for your {grade.toLowerCase()} {subject.toLowerCase()} classroom{dots} <br /><br /> {countDown} Seconds Left</div>}
                                {errorOccured && <div className='loadingTxt'>Oops! We're experiencing some difficulty :( <br /><br /> We're sorry for the inconvenience and we're looking into it. Please try refreshing the page or come back a little later. Rest assured, our team is actively investigating this issue. If the problem continues, don't hesitate to reach out for assistance.</div>}
                            </form>
                        </div>
                    </div>
                </section>
                <section className="section-two" >
                    <h2 className='pollsTitle' id='about'>Learn more about Gruvy Education</h2>
                    <div className="container section-two-content">
                        <div className="accordion">
                            <Link to="/testimonials" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Testimonials</span>
                                </div>
                            </Link>
                            <Link to="/about" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">About Us</span>
                                </div>
                            </Link>
                            <a target="_blank" href="https://usemotion.com/meet/william-grube/meeting?d=30" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Schedule a Virtual Meeting</span>
                                </div>
                            </a>
                            <ScrollLink to="contact" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Send us a message</span>
                                </div>
                            </ScrollLink>
                        </div>
                        <div className="info text-center">
                            <div className="section-title">
                                <h2>Join our Facebook Group</h2>
                            </div>
                            <p className="text">Join our community of educators staying ahead of the curve with AI.</p>
                            <a target='_blank' href="https://www.facebook.com/groups/630420622459086/" className="btn">Join</a>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </body>
    );
}

export default GroupProjectGenerator;
