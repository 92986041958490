import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './MoASSPFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavNDCEL from './NavNDCEL2';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import YouTube from '../../images/youtube-icon.png'
import PowerSchool from '../../images/PowerSchool.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'
import Policy from '../../images/Policy.png'
const NDCELBreakout2 = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    const prompts1 = [
        {
            id: 'difficult-parent-email',
            directions: "Copy and paste the prompt below into ChatGPT to draft a response to a difficult parent email. Fill in the variables in square brackets with specific details relevant to your position and the issue at hand.",
            text: `Act as a [Position e.g., Principal, Teacher]. You've received an email from a parent who is visibly upset or angry about [Issue]. Please draft a tactful, empathetic, and professional email that acknowledges the parent's feelings, clarifies any misunderstandings, and outlines steps the school is taking or will take to address the issue. Also, if appropriate, suggest a follow-up meeting or phone call to discuss the matter further. Include the following points: [what you would like to include].`.split('\n')
        },
        {
            id: 'parental-communication-email',
            directions: "Copy and paste the prompt below into ChatGPT to draft a response to a parent's email.",
            text: `Act as a [position]. You've received an email from a parent concerning their child. The email discusses [Concern Topic]. Please draft a balanced, reassuring, and informative email that addresses this topic. Include: [what you would like to include].`.split('\n')
        },
        {
            id: 'professional-email',
            directions: "Copy and paste the prompt below into ChatGPT to draft an email to a colleague.",
            text: `Act as a [position]. You want to send an email to a colleague. The email discusses [Concern Topic]. Please draft a balanced, reassuring, and informative email that addresses this topic. Include: [what you would like to include].`.split('\n')
        },
    ];


    const prompts3 = [
        {
            id: 'improvment-plan',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in creating an improvement plan for a technology coach.",
            text: `Act as if you are an high school school principal. You are struggling with your technology coach. Not only do they have a sarcastic and offputting personality, you haven't seen any results from their work over the last year and half. Please write an improvement plan and suggest activities for this improvement plan.`.split('\n')
        },
        {
            id: 'tech-coach-improvement-plan',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in coaching a teacher on their instruction in AP Physics.",
            text: `Act as if you are a high school principal coaching a teacher on their instruction. Though you lack the content expertise of AP Physics, you recognize that the teacher struggles with alignment between what they teach and what the students learn. The teacher has a wide variety of engaging lessons and lab activities, but you are unable to see a structure that is based on the standard the teacher needs to teach and evidence-based outcomes demonstrating student proficiency of those standards. Provide me with some notes to include in the teacher's evaluations or things I can say to the teacher in our coaching conversations.`.split('\n')
        },
        {
            id: 'role-play-registrar-conversation',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in generating kindly worded suggestions for improvement.",
            text: `Please provide a gently worded, constructive suggestion for a school staff member to enhance their [specific area needing improvement, e.g., 'classroom management techniques', 'student engagement methods', 'use of educational technology']. The feedback should be supportive and aimed at fostering professional growth.`.split('\n')
        },
    ];


    const prompts4 = [
        {
            id: 'Behavior',
            directions: "Open ChatGPT using the link in the \"Links Needed for Activity\" section. Inside of ChatGPT, click on the paper clip button in the prompt bar, select \"Upload from computer,\" and choose your downloaded Excel file. Send the following prompt to ChatGPT with the attached file:",
            text: `Act as a school data analyst. Attached is a set of student feedback for a teacher. Create a detailed report of the results for the teacher. Include five compliments and three action points for improvement. Use positive language. Be clear and use simple wording.`.split('\n')
        },
    ];

    const prompts5 = [
        {
            id: 'PowerSchoolDataAnalysis',
            directions:
                'After completing the data anonymization steps, open ChatGPT and use the following prompt with your anonymized data:',
            text: `Analyze the student data in the attached Excel file. Identify significant trends over time, highlight areas of concern, and suggest actionable interventions. Use the Anonymized IDs to reference students. Provide visualizations to support your analysis.`.split(
                '\n'
            ),
        },
    ];

    const prompts6 = [
        {
            id: 'PolicyReviewSummary',
            directions: 'Copy and paste the following prompt into ChatGPT after uploading your policy document:',
            text: `Act as a school leader reviewing this district policy manual. For each section, provide a concise summary of the key points. Then, suggest improvements or updates to the policy where applicable. Present your response by first quoting the original section, and then providing the revised version, clearly indicating the changes. This will help in editing the policy efficiently: [Paste policy here].`.split('\n'),
        },
        {
            id: 'PolicySectionEdit',
            directions: 'Use the following dynamic prompt to focus on a specific section you want to edit. Replace the placeholders with your details:',
            text: `Focus on Section [Section Name or Number] of the policy document. I want to [describe what you want to achieve, e.g., "update this section to include guidelines on remote learning policies"]. Provide the original text and then present a revised version incorporating these changes.`.split('\n'),
        },
    ];

    const prompts7 = [
        {
            id: 'CanvasWriting',
            directions:
                'Use the following prompt in ChatGPT Canvas to start your writing task. Replace the placeholders with your specific details:',
            text: `Act as a school superintendent. Write an email to all district staff announcing a district-wide initiative to improve student engagement through technology integration. Make it clear, motivating, and include actionable steps for staff to participate in training sessions. Provide details about the timeline, expectations, and available support resources for staff.`.split('\n'),
        },
    ];




    const prompts2 = [
        {
            id: 'role-action-context-format',
            directions: "Use the role-action-context-format prompt design framework and experiment with it in ChatGPT. Here is an example:",
            text: `Act as a school principal. (Role) Draft a student-friendly contract for a 'Classroom Device Care Agreement' that students can understand and follow. (Action) Include clear expectations, consequences for non-compliance, and positive reinforcement techniques to motivate adherence. (Context) Make sure the contract is straightforward and engaging for students. (Format)`.split('\n')
        }
    ];


    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavNDCEL module="home" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">Taking AI to the Next Level</div>
                        </div>
                    </header>
                    <div className="course-container">
                        <div className="MoASSP-course-navbar">
                            <ScrollLink
                                activeClass="active"
                                to="william"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">🏃‍♂️🎨</span> Activities
                                </div>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="resources"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Resources">📦💡</span> Resources
                                </div>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">📞🎤</span> Contact William
                                </div>
                            </ScrollLink>
                            <Link
                                target="_blank"
                                to="/ndcel2aboutgruvyeducationbreakout"
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">❓🎓</span> About Gruvy Education
                                </div>
                            </Link>
                        </div>
                        <section id="william" className="MoASSP-course-section MoASSP-activities-section">
                            <h2 className="MoASSP-course-sub-title">🏃‍♂️🎨 Activities</h2>
                            <div className="MoASSP-activities-wrapper">
                                {/* Navigation bar */}
                                <div className="MoASSP-activity-nav-bar">
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="1" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">1</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="2" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">2</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="3" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">3</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="4" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">4</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="5" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">5</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="6" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">6</ScrollLink>
                                </div>
                                {/* Activity 1 */}
                                <div key="1" id="1" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 1: </span>
                                        Role, Action, Context, Format
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={PromptDesign} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Guide</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        {prompts2.map((prompt, index) => (
                                            <div key={prompt.id} className="MoASSP-activity-step">
                                                <p className="MoASSP-activity-direction">
                                                    <strong>{index + 1}.</strong> {prompt.directions}
                                                </p>
                                                <CopyToClipboard
                                                    textToCopy={prompt.text.join('\n')}
                                                    displayText={prompt.text}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div key="2" id="2" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 2: </span>
                                        Streamlining Data Analysis with ChatGPT to Turn Student Feedback into a Teacher Report
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/spreadsheets/d/1-luAv9zp-SDpN6-y3CdMy868d43JkBa2uoMyhbnnZxE/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={GoogleSheetIcon} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Google Sheet</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Anonymize} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Anonymize Data</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div key="7-1" className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the Google Sheet found in the "Links Needed for Activity" section. Click on "File" in the top left corner, hover over "Download," and then click on "Microsoft Excel (.xlsx)."
                                            </p>
                                        </div>
                                        {prompts4.map((prompt, index) => (
                                            <div key={prompt.id} className="MoASSP-activity-step">
                                                <p className="MoASSP-activity-direction">
                                                    <strong>{index + 2}.</strong> {prompt.directions}
                                                </p>
                                                <CopyToClipboard
                                                    textToCopy={prompt.text.join('\n')}
                                                    displayText={prompt.text}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div key="3" id="3" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 3: </span>
                                        Anonymizing PowerSchool Data for ChatGPT Analysis and Re-Mapping Student IDs
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        className="MoASSP-link-icon-img"
                                                        src={ChatGPT}
                                                        alt="ChatGPT"
                                                    />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://www.powerschool.com/sign-in/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={PowerSchool} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Power School</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/spreadsheets/d/1Z_8OUAW4rNQtlDxHbpCe64xIK7qdi-or3eSZfyKL2S4/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={GoogleSheetIcon} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Mock Data</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Anonymize} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Anonymize Data</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        {/* Step 1 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> <strong>Log in to PowerSchool</strong> with your administrator credentials.
                                            </p>
                                        </div>
                                        {/* Step 2 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Navigate to the <strong>"Reports"</strong> or <strong>"Data Export"</strong> section in the main menu.
                                            </p>
                                        </div>
                                        {/* Step 3 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>3.</strong> Select <strong>"Student Data"</strong> or the specific dataset you wish to export (e.g., attendance records, grades, behavior incidents).
                                            </p>
                                        </div>
                                        {/* Step 4 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>4.</strong> Choose the <strong>fields</strong> you want to include in the export:
                                                <br />
                                                &emsp; • Student ID
                                                <br />
                                                &emsp; • Data relevant to your analysis (e.g., incident dates, types, grades)
                                                <br />
                                                &emsp; • Avoid selecting any Personally Identifiable Information (PII) like names or addresses
                                            </p>
                                        </div>
                                        {/* Step 5 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>5.</strong> Click on <strong>"Export"</strong> and choose the file format as <strong>CSV</strong> or <strong>Excel (.xlsx)</strong>. Save the file to a secure location on your computer.
                                            </p>
                                        </div>
                                        {/* Step 6 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>6.</strong> <strong>Open the exported file</strong> using Excel or a compatible spreadsheet program.
                                            </p>
                                        </div>
                                        {/* Step 7 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>7.</strong> Insert a new column at the beginning of the dataset and title it <strong>"Anonymized ID"</strong>.
                                            </p>
                                        </div>
                                        {/* Step 8 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>8.</strong> Assign a unique anonymized ID to each student (e.g., Student001, Student002).
                                            </p>
                                        </div>
                                        {/* Step 9 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>9.</strong> Create a secure mapping file:
                                                <br />
                                                &emsp; • Open a new Excel file.
                                                <br />
                                                &emsp; • Create two columns: <strong>"Anonymized ID"</strong> and <strong>"Original Student ID"</strong>.
                                                <br />
                                                &emsp; • Copy the Anonymized IDs and their corresponding Original Student IDs into this file.
                                                <br />
                                                &emsp; • Save this mapping file in a secure, password-protected folder accessible only to authorized personnel.
                                            </p>
                                        </div>
                                        {/* Step 10 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>10.</strong> Remove PII from your dataset:
                                                <br />
                                                &emsp; • Delete any columns containing PII, such as names, addresses, or birthdates.
                                                <br />
                                                &emsp; • Ensure only the Anonymized ID is used to identify students.
                                            </p>
                                        </div>
                                        {/* Step 11 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>11.</strong> Save the anonymized dataset; this is the file you'll use with ChatGPT.
                                            </p>
                                        </div>
                                        {/* Step 12 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>12.</strong> Open ChatGPT using the link in the "Links Needed for Activity" section.
                                            </p>
                                        </div>
                                        {/* Step 13 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>13.</strong> Inside ChatGPT, click on the <strong>paper clip icon</strong> (📎) on the left side of the prompt bar at the bottom.
                                            </p>
                                        </div>
                                        {/* Step 14 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>14.</strong> Select <strong>"Upload from computer"</strong> and choose your <strong>anonymized Excel file</strong>.
                                            </p>
                                        </div>
                                        {/* Step 15 */}
                                        {prompts5.map((prompt, index) => (
                                            <div key={prompt.id} className="MoASSP-activity-step">
                                                <p className="MoASSP-activity-direction">
                                                    <strong>15.</strong> {prompt.directions}
                                                </p>
                                                <CopyToClipboard
                                                    textToCopy={prompt.text.join('\n')}
                                                    displayText={prompt.text}
                                                />
                                            </div>
                                        ))}
                                        {/* Step 16 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>16.</strong> Review the analysis provided by ChatGPT and take notes on key insights.
                                            </p>
                                        </div>
                                        {/* Step 17 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>17.</strong> Map the results back to the original students using the secure mapping file you created earlier.
                                            </p>
                                        </div>
                                        {/* Step 18 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>18.</strong> Develop actionable plans based on the insights, ensuring you comply with all privacy laws and school policies.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="4" id="4" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 4: </span>
                                        Simplify District Policy Review with AI
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        className="MoASSP-link-icon-img"
                                                        src={ChatGPT}
                                                        alt="ChatGPT"
                                                    />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://ndlegis.gov/cencode/t15-1c06.pdf"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        className="MoASSP-link-icon-img"
                                                        src={Policy}
                                                        alt="ChatGPT"
                                                    />
                                                    <span className="MoASSP-link-label">ND Century Code</span>
                                                </a>
                                                {/* Add any other necessary links here */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        {/* Step 1 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open your district policy document and save it in a text-compatible format (e.g., <strong>.docx</strong>, <strong>.txt</strong>, or <strong>.pdf</strong>).
                                            </p>
                                        </div>
                                        {/* Step 2 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Open <strong>ChatGPT</strong> using the link in the "Links Needed for Activity" section, click on the <strong>paperclip icon</strong> (📎) in the prompt bar, and upload your policy document.
                                            </p>
                                        </div>
                                        {/* Step 3 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>3.</strong> Use the first prompt to get an overall summary and suggested improvements:
                                            </p>
                                            <CopyToClipboard
                                                textToCopy={prompts6[0].text.join('\n')}
                                                displayText={prompts6[0].text}
                                            />
                                        </div>
                                        {/* Step 4 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>4.</strong> Review ChatGPT's response, which will include summaries, suggested improvements, and both original and revised sections for each part of the policy. To focus on a specific section and make custom edits, use the second dynamic prompt. Replace the placeholders with your details:
                                            </p>
                                            <CopyToClipboard
                                                textToCopy={prompts6[1].text.join('\n')}
                                                displayText={prompts6[1].text}
                                            />
                                        </div>
                                        {/* Step 5 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>5.</strong> Use the revised sections provided by ChatGPT to update your policy document efficiently.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="5" id="5" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 5: </span>
                                        Enhancing Your Writing with ChatGPT Canvas
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://chat.openai.com/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        className="MoASSP-link-icon-img"
                                                        src={ChatGPT}
                                                        alt="ChatGPT"
                                                    />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://www.youtube.com/watch?v=bMabbQGRoTk"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        className="MoASSP-link-icon-img"
                                                        src={YouTube}
                                                        alt="YouTube"
                                                    />
                                                    <span className="MoASSP-link-label">Tutorial</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        {/* Step 1 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Ensure you have a <strong>paid ChatGPT account</strong> (Plus or Enterprise), as the Canvas feature is available only to paid users.
                                            </p>
                                        </div>
                                        {/* Step 2 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Open <strong>ChatGPT</strong> using the link in the "Links Needed for Activity" section and log in to your account.
                                            </p>
                                        </div>
                                        {/* Step 3 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>3.</strong> In the model selector dropdown at the top, select <strong>"GPT-4o with canvas"</strong> to switch to the Canvas interface.
                                            </p>
                                        </div>
                                        {/* Step 4 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>4.</strong> Use the following prompt to begin your writing task:
                                            </p>
                                            <CopyToClipboard
                                                textToCopy={prompts7[0].text.join('\n')}
                                                displayText={prompts7[0].text}
                                            />
                                        </div>
                                        {/* Step 5 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>5.</strong> ChatGPT Canvas will generate a draft for you. Use the Canvas features to enhance your document:
                                                <br />
                                                &emsp; • Select text to access editing options like suggesting edits, adjusting length, adjusting reading level, and adding final polish.
                                            </p>
                                        </div>
                                        {/* Step 6 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>6.</strong> Experiment with the features:
                                                <br />
                                                &emsp; • Adjust the tone or reading level to suit your audience.
                                                <br />
                                                &emsp; • Use "Suggest Edits" to improve clarity and professionalism.
                                                <br />
                                                &emsp; • Use "Adjust Length" to make the communication shorter or longer as needed.
                                            </p>
                                        </div>
                                        {/* Step 7 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>7.</strong> Review the final version, and when satisfied, copy the text for use in your communications.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="6" id="6" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Activity 6: </span>
                                        Streamlining Task Delegation with ChatGPT
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a
                                                    className="MoASSP-link-icon"
                                                    href="https://docs.google.com/document/d/1jB_5rMS7OyA3-X6DQdbXPxOoT4ue9Rx_iiw4Zx7VVoY/edit?usp=sharing"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        className="MoASSP-link-icon-img"
                                                        src={Doc} // Ensure you have this image imported
                                                        alt="SOP for Creating SOPs"
                                                    />
                                                    <span className="MoASSP-link-label">SOP for Creating SOPs</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        {/* Step 1 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                As a school leader, effectively delegating tasks is crucial for managing your time and resources. One of the most impactful ways I've discovered to streamline this process is by using ChatGPT to create clear and concise Standard Operating Procedures (SOPs). This method has been incredibly beneficial in ensuring that tasks are communicated efficiently and that everyone on the team is on the same page.
                                            </p>
                                        </div>
                                        {/* Step 2 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                I cannot recommend this approach enough. It has transformed how I delegate tasks, making the process quicker and more effective. By converting video explanations into well-formatted SOPs, you can save time and provide your staff with easy-to-follow instructions.
                                            </p>
                                        </div>
                                        {/* Step 3 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                Here are some example scenarios where you can apply this method:
                                                <br />
                                                &emsp; • Creating SOPs for new administrative procedures.
                                                <br />
                                                &emsp; • Documenting steps for using school management software.
                                                <br />
                                                &emsp; • Providing clear instructions for emergency protocols.
                                                <br />
                                                &emsp; • Outlining processes for student enrollment or attendance tracking.
                                                <br />
                                                &emsp; • Training new staff on existing procedures.
                                            </p>
                                        </div>
                                        {/* Step 4 */}
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                To learn how to implement this method, please refer to the SOP provided in the "Links Needed for Activity" section above.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section name='resources' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">📦💡Resources</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://drive.google.com/file/d/1sMVqVShJOi539RIix7d68dW2J9cQJY6V/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">ChatGPT Guide for School Leaders: ChatGPT Uses & Prompts</h3>
                                    <p className="MoASSP-tool-text">30+ ways to use ChatGPT as a School Leader.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={PromptDesign} alt="ChatGPT" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Framework for Designing ChatGPT Prompts</h3>
                                    <p className="MoASSP-tool-text">A guide on how school leaders should design prompts for ChatGPT.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gruvy Education's Guide to Student Data Privacy With AI</h3>
                                    <p className="MoASSP-tool-text">A school's guide to student data privacy with AI.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Anonymize} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Anonymizing Student Data for Use with ChatGPT: Guide for School Leaders </h3>
                                    <p className="MoASSP-tool-text">A school leader's guide for anonymizing student data for use with ChatGPT.</p>
                                </a>
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Educator ChatGPT Prompt Library</h3>
                                    <p className="MoASSP-tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guide2} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Guide for Navigating AI Tools as an Educator</h3>
                                    <p className="MoASSP-tool-text">A guide outlining popular educator-specific AI tools, what they do, and whether they are free or their cost.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1M2Dejn-OC27POEfaHSPsgVqSfc_eeCb6/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIresistant} alt="Magic School" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Creating AI-Resistant Curriculum: A Guide for Educators</h3>
                                    <p className="MoASSP-tool-text">A guide to keeping students learning in the AI era by crafting AI-resistant curriculum.</p>
                                </a>
                                <a href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Newsletter} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI School Leadership Minute</h3>
                                    <p className="MoASSP-tool-text">My "cut the fluff, only give me what I need" AI newsletter for school leaders.</p>
                                </a>
                                <ScrollLink activeClass="active" to="rupak-section" spy={true} smooth={true} offset={-70} duration={50} className="MoASSP-tool-card cursorPointer">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Guidance} alt="SchoolAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Guidance and Responsible Use Resources</h3>
                                    <p className="MoASSP-tool-text">Resources for AI policy, responsible AI use by students, and more.</p>
                                </ScrollLink>
                                <a href="https://drive.google.com/drive/folders/1embys9TFeGJ8jRXX8njetkCllJG0QHSl?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Folder} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Resource Toolkit for Teachers</h3>
                                    <p className="MoASSP-tool-text">Resources to help teachers use AI as a tool.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1-s6CdrvtxNlC3sH20q0cEMdrfvFvyS3C9KxrwTHLfw4/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={PurpleLogo} alt="AI Newsletter" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Learn about Gruvy Education's AI Training</h3>
                                    <p className="MoASSP-tool-text">Learn about the AI training trusted by over 100 schools.</p>
                                </a>
                                <a href="https://gruvyeducation.com/trainingportalapplication" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={GreenLogo} alt="Gruvy Education Training" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Apply to Talk to William About Brining AI Training for Your Staff</h3>
                                    <p className="MoASSP-tool-text">Submit your application to bring the AI training trusted by over 100 schools to your staff.</p>
                                </a>
                            </div>
                        </section>
                        <section name='rupak-section' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">🤖📚 AI Guidance</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://www.teachai.org/" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={TeachAIpolicy} alt="TeachAI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">TeachAI | AI Guidance for Schools</h3>
                                    <p className="MoASSP-tool-text">TeachAI provides free AI policy resources and guidance for schools.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1Chyf1e2BrPwGHod7HNFucM6agBKHQVzq/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={StudentGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI in Education: Teacher's Checklist for Success</h3>
                                    <p className="MoASSP-tool-text">A practical checklist for teachers to effectively implement AI tools in the classroom.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1px7ZjoCuOA-CpwdQl2pC_QOhiXem-o3M/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={TeacherChecklist} alt="AI Guidelines for Students" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI Classroom Use: Guidelines for Students</h3>
                                    <p className="MoASSP-tool-text">An easy-to-understand graphic for students on using AI responsibly.</p>
                                </a>
                                <a href="https://www.aiforeducation.io/ai-resources-main" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={AIEDU} alt="AIEDU" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">AI for Education | Downloadable Resources</h3>
                                    <p className="MoASSP-tool-text">Free AI resources for your school.</p>
                                </a>
                                <a href="https://drive.google.com/file/d/1h7TP4nB5OHuWFE9IJn4bxKzjNgKHRL8g/view?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={DataGuide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Gruvy Education's Guide to Data Privacy With AI</h3>
                                    <p className="MoASSP-tool-text">A school's guide to student data privacy with AI.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1QSBcXziz3HIyB5A6lH5o7JIsf1R7FZ5q1nWodrkI49s/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Anonymize} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Anonymizing Student Data for Use with ChatGPT: Guide for School Leaders </h3>
                                    <p className="MoASSP-tool-text">A school leader's guide for anonymizing student data for use with ChatGPT.</p>
                                </a>
                            </div>
                        </section>
                        <section name='NextSteps' className="MoASSP-course-section">
                            <h2 className="MoASSP-course-sub-title">✅ Next Steps</h2>
                            <div className="MoASSP-tool-grid">
                                <a href="https://docs.google.com/document/d/1-s6CdrvtxNlC3sH20q0cEMdrfvFvyS3C9KxrwTHLfw4/edit?usp=sharing" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Logo} alt="Gruvy Education Training" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Learn More About Gruvy Education's AI Training</h3>
                                    <p className="MoASSP-tool-text">Click here to learn more about the training 100+ schools and state-level organizations have adopted.</p>
                                </a>
                                <a href="https://gruvyeducation.com/trainingportalapplication" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Form} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Apply to Bring Gruvy Education's AI Training to Your Educators</h3>
                                    <p className="MoASSP-tool-text">Apply to join the 100+ schools participating in our AI in Education Training.</p>
                                </a>
                                <a href="https://usemotion.com/meet/william-grube/meeting?d=30" target="_blank" className="MoASSP-tool-card">
                                    <div className="MoASSP-tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="MoASSP-tool-title">Schedule a Meeting with William</h3>
                                    <p className="MoASSP-tool-text">We will talk where your staff is at with AI, what the training involves, and it it is a good fit.</p>
                                </a>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div >
        </body >
    );
}

export default NDCELBreakout2;
