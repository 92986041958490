import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import NavSCSC from './NavSCSC';
import Sheet from '../images/GoogleSheet.png'

const SCSCCommunication = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    const prompts1 = [
        {
            id: 'difficult-parent-email',
            directions: "Copy and paste the prompt below into ChatGPT to draft a response to a difficult parent email. Fill in the variables in square brackets with specific details relevant to your position and the issue at hand.",
            text: `Act as a [Position e.g., Principal, Teacher]. You've received an email from a parent who is visibly upset or angry about [Issue]. Please draft a tactful, empathetic, and professional email that acknowledges the parent's feelings, clarifies any misunderstandings, and outlines steps the school is taking or will take to address the issue. Also, if appropriate, suggest a follow-up meeting or phone call to discuss the matter further. Include the following points: [what you would like to include].`.split('\n')
        },
        {
            id: 'parental-communication-email',
            directions: "Copy and paste the prompt below into ChatGPT to draft a response to a parent's email.",
            text: `Act as a [position]. You've received an email from a parent concerning their child. The email discusses [Concern Topic]. Please draft a balanced, reassuring, and informative email that addresses this topic. Include: [what you would like to include].`.split('\n')
        },
        {
            id: 'professional-email',
            directions: "Copy and paste the prompt below into ChatGPT to draft an email to a colleague.",
            text: `Act as a [position]. You want to send an email to a colleague. The email discusses [Concern Topic]. Please draft a balanced, reassuring, and informative email that addresses this topic. Include: [what you would like to include].`.split('\n')
        },
    ];


    const prompts2 = [
        {
            id: 'social-media-post',
            directions: "Copy and paste the prompt below into ChatGPT to generate a social media post as a school administrator. Replace the placeholders in square brackets with the specific details for your post.",
            text: `As a school administrator, create a social media post about [Topic or Event]. Make sure to include details such as [Specific Details to Include], and tailor the message to connect with the school community. Use a tone that is [Tone, e.g., enthusiastic, supportive, informative]. Remember to make the post suitable for platforms like Facebook, Twitter, or Instagram, keeping it concise and visually appealing. Include relevant hashtags or emojis if appropriate.`.split('\n')
        },
    ];

    const prompts3 = [
        {
            id: 'grant',
            directions: "Use the prompt below in ChatGPT to craft a detailed and persuasive grant proposal. Replace the placeholders in square brackets with information specific to your grant application and organization's activities. Be sure to emphasize how your activities align with the grant's priorities, particularly any areas the grant specifies as of special interest or 'bonus points.'",
            text: `As a grant writer for a K-12 school, craft a grant proposal response to the following question: [Question or statement from the grant application]. Highlight [Activities] that underscore our mission and detail how they align with the grant's priorities, especially focusing on [specific "bonus point" or emphasis area].`.split('\n')
        },
    ];


    const prompts4 = [
        {
            id: 'table-information-summary',
            directions: "Open ChatGPT using the link in the \"Links Needed for Activity\" section. Inside ChatGPT, click on the paper clip button in the prompt bar, select \"Upload from computer,\" and choose your downloaded Excel file. Send the following prompt to ChatGPT with the attached file:",
            text: `The table shows results from [Describe the table of information]. No personal information is included. Tell me [what information you want to determine]. Write a summary paragraph so I can present it to [intended audience].`.split('\n')
        }
    ];

    const prompts5 = [
        {
            id: 'professional-development-guide',
            directions: "Use this prompt to create a comprehensive professional development guide tailored for your staff. Fill in the placeholders with the specific position, professional development topic, and time frame. Ensure the guide is detailed with learning objectives, resources, milestones, and assessment methods, and consider staff individualization options. Format the guide as a PowerPoint presentation, including speaker notes for clarity on each point.",
            text: "As the [position], you want to create a step-by-step professional development guide for your staff focusing on [professional development topic]. Please include the learning objectives, recommended resources, milestones, and assessment methods. Ensure that your guide can be completed within [time frame]. Also, include options for staff to tailor the program to their individual needs or strengths. Present it as a PowerPoint. At the end of each slide, include speaker notes that clarify each bullet point.".split('\n')
        }
    ];

    const prompts6 = [
        {
            id: 'teacher-evaluation',
            directions: "Copy and paste the prompt below into ChatGPT to generate a teacher evaluation as a principal. Replace the placeholders in square brackets with specific details for your evaluation.",
            text: `As a principal, write a teacher evaluation focusing on [State Observation]. Use a [Tone, e.g., positive, coaching, friendly, casual, professional] tone to provide constructive feedback. If applicable, provide examples to help the teacher understand and improve in the [Observation Domain]. Keep your feedback concise, aiming for 2-5 sentences.`.split('\n')
        },
    ];

    const prompts7 = [
        {
            id: 'newsletter-generation',
            directions: "Copy and paste the prompt below into ChatGPT to generate a newsletter. Adjust the placeholders in square brackets to fit the context and audience.",
            text: `Act as a [Position e.g., Principal, Teacher]. You are creating a newsletter for [Audience e.g., Parents, Students, Staff]. Please draft a comprehensive, engaging, and informative newsletter that addresses these topics. Include: [specific details or sections you would like to include].`.split('\n')
        }
    ];

    const prompts8 = [
        {
            id: 'role-action-context-format',
            directions: "Use the role-action-context-format prompt design framework and experiment with it in ChatGPT. Here is an example:",
            text: `Act as a school principal. (Role) Draft a student-friendly contract for a 'Classroom Device Care Agreement' that students can understand and follow. (Action) Include clear expectations, consequences for non-compliance, and positive reinforcement techniques to motivate adherence. (Context) Make sure the contract is straightforward and engaging for students. (Format)`.split('\n')
        }
    ];

    const prompts9 = [
        {
            id: 'recommendation-letter',
            directions: "Copy and paste the prompt below into ChatGPT to draft a letter of recommendation. Adjust the placeholders in square brackets to fit the specific details of the individual and the context.",
            text: `Act as a [Position e.g., Principal, Teacher, Manager]. You are writing a letter of recommendation for a student who is applying for [Position/Opportunity]. Highlight their strengths, achievements, and qualities that make them a suitable candidate. Include specific examples of their work, character, and impact. Ensure the letter is professional, detailed, and compelling. [Additional specific details or sections to include]`.split('\n')
        }
    ];

    const prompts16 = [
        {
            id: 'generalFundExplanation',
            directions: "Copy and paste the prompt below into ChatGPT to gain an understanding of the General Fund aspect from a presentation on North Dakota School Funding.",
            text: "Understand this slide from a presentation on ND school funding: General Fund: A school can levy up to 70 mills in the general fund without a vote of the people. The state assumes a general fund levy of 60 mills meaning that the equivalent revenue generated from 60 mills is deducted from the school’s state foundation aid payment. Miscellaneous Fund Levy: Capped at 12 Mills and can be levied by vote of the school board. This levy consolidated several smaller levies such as the technology and transportation levy. These dollars are co-mingled in the general fund. This is not subject to the 12% increase per year. Special Reserve Fund Levy: Districts can levy up to 3 mills per year in the Special Reserve Fund. By a vote of the school board, these dollars can be transferred into the general fund with certain limitations. This has a limit of 15 mills. NDCC 57-19."
        },
        {
            id: 'perPupilPaymentDetails',
            directions: "Use this prompt in ChatGPT to understand the details of the current per pupil payment in North Dakota's school funding for the 2023/2024 school year.",
            text: "Also, take into account and understand this information: The current per-pupil payment of $10,646 represents the funding necessary for the 2023/2024 school year to provide an adequate education for all ND students. The value of 60 mills and 75% of in-lieu taxes from each school district are deducted from $10,646 to provide the local school district’s contribution toward adequate funding. Beyond this funding, school districts are able to levy an additional 10 general fund mills and 12 mills miscellaneous mills to account for any other needs as determined by the local school district."
        },
        {
            id: 'schoolFundingSimplified',
            directions: "Paste this prompt into ChatGPT to get a simplified explanation of North Dakota school funding, specifically for someone unfamiliar with the topic.",
            text: "Using the information above, explain this to someone who doesn't understand school funding at all. Reference the 60 mill deduct, 10 mills of discretionary, plus the 12 mills of miscellaneous levying authority in less than five sentences."
        },
    ]

    return (

        <body>
            <div className="holder">
                <NavSCSC module="communication" />
                <header className="header-purple3 shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Using AI for Communication</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <Link to="/scsc" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">⬅️🏡</span> Back to Home</div>
                            </Link>
                            <ScrollLink activeClass="active" to="william" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">🗣️📢</span> Communication Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">🛠️📘</span> Hands-On Application Modules</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section id='william' className="course-section course-activities-section">
                            <h2 className="course-sub-title">🗣️📢 Communication Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a href="#1" className="activity-nav-item redBackground">1</a>
                                    <a href="#2" className="activity-nav-item redBackground">2</a>
                                    <a href="#3" className="activity-nav-item redBackground">3</a>
                                    <a href="#4" className="activity-nav-item redBackground">4</a>
                                    <a href="#5" className="activity-nav-item redBackground">5</a>
                                    <a href="#6" className="activity-nav-item redBackground">6</a>
                                    <a href="#7" className="activity-nav-item redBackground">7</a>
                                    <a href="#8" className="activity-nav-item redBackground">8</a>
                                    <a href="#9" className="activity-nav-item redBackground">9</a>
                                    <a href="#10" className="activity-nav-item redBackground">10</a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing Chat GPT for Communications via Email
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts1.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Role, Action, Context, Format
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        {prompts8.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>

                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Creating Engaging Newsletters with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="7-1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts7.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="4" id="4" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Writing Effective Teacher Evaluations with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Access ChatGPT using the link in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts6.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="5" id="5" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Generating Social Media Posts with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts2.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="6" id="6" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Using ChatGPT to Explain Data From a File
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/spreadsheets/d/1AEuqz0fS_gM6ISfpC92PJdhDn1Y5iXQt4ikyDYb6QbI/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Sheet} alt="Doc" />
                                                    <span className="link-label">Sheet</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open the Google Sheet found in the "Links Needed for Activity" section. Click on "File" in the top left corner, hover over "Download," and then click on "Microsoft Excel (.xlsx)." Alternatively, feel free to use a preexisting Excel file that you have and would like to use for this activity.</p>
                                        </div>
                                        {prompts4.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="7" id="7" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Generating Professional Development PowerPoint's
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts5.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="8" id="8" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Writing Grants
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts3.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                                <div key="9" id="9" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Leveraging ChatGPT To Explaining Something Complex in Understandable Terms
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts16.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="10" id="10" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Writing a Letter of Recommendation
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="9-1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts9.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🛠️📘 Hands-On Application Modules</h2>
                            <div className="tool-grid">
                                <Link to="/scsc/communication" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Communications} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Communication</h3>
                                    <p className="tool-text">Emails to parents, Social posts, Applying for grants</p>
                                </Link>
                                <Link to="/scsc/humancapitol" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Human Capital</h3>
                                    <p className="tool-text">Evaluation of employees, Documenting employees, Coaching employees.</p>
                                </Link>
                                <Link to="/scsc/miscellaneousneeds" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Miscellaneous Needs</h3>
                                    <p className="tool-text">Drafting student contracts, Crisis support, Checklist frameworks, Observation scheduling.</p>
                                </Link>
                                <Link to="/scsc/teachingandlearning" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={TeachingLearning} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Teaching and Learning</h3>
                                    <p className="tool-text">Leave equipted to bring AI tools to your staff.</p>
                                </Link>
                            </div>
                        </section>
                        
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default SCSCCommunication;
