import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { useNavigate } from 'react-router-dom';
import Nav from './Nav'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/Magic.png"
import Library from '../images/NewLibrary.png'
import Tool from '../images/toolsIcon.png'
import Explore from '../images/Explore.png'
import Doc from '../images/doc.png'
import Form from '../images/form.png'
import SchoolAI from '../images/SchoolAI.jpg'
import Abe from '../images/Abe.png'
import Newsletter from '../images/newsletter.png'


const MCC = () => {

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    const prompts1 = [
        {
            id: 'lesson_plan',
            directions: "Copy and paste the prompt below into ChatGPT to create a Lesson Plan Template. Fill in the variables in square brackets with specific details relevant to your subject, grade, and topic.",
            text: `Design a lesson plan for [subject] at the [grade] level, focusing on [topic]. Here is some more contect on where we are at in the unit: [Describe what the students have already learned that is relevant to this topic]. Include: [anything you want to make sure the lesson plan includes]. `.split('\n')
        },
    ];

    const prompts2 = [
        {
            id: 'ideas',
            directions: "Copy and paste the prompt below into ChatGPT to create in-class activity ideas.",
            text: "As a [grade] grade [subject] teacher, I need ideas for activities related to the topic of [specific topic] to engage my students in active learning. Please generate a list of activities that promote critical thinking, creativity, and deep understanding of the subject matter. Ensure these ideas are tailored to the topic of [specific topic].".split('\n')
        },
        {
            id: 'formulateIdea',
            directions: "Copy and paste the prompt below into ChatGPT to formulate one of the generated ideas.",
            text: "Explain the detailed steps and guidelines for implementing [Activity] in my classroom. Include any necessary materials, preparation, instructions for students that [grade] graders can easily understand, and ideas for assessment or reflection.".split('\n')
        },
    ];

    const prompts4 = [
        {
            id: 'explanation',
            directions: "Copy and paste the prompt below into ChatGPT to create an AI-resistant local environment-based question.",
            text: `As a [grade] grade [subject] teacher, I will provide my grade level and topic. Your task is to create a Local Environment-Based Question tailored to my students that will require them to engage with their immediate environment, such as the classroom, the school grounds, or the outdoors. The objective is to make learning more experiential and observational. Design a Local Environment-Based Question for a [grade] grade [topic] assignment that makes students interact physically with their surroundings.`.split('\n')
        },
    ];

    const prompts3 = [
        {
            id: 'explanation',
            directions: "Copy and paste the prompt below into ChatGPT to explain a topic in simple terms.",
            text: `Create a simplified explanation of [complex topic] as if you were teaching it to a [specific grade level] class. Use analogies related to [everyday item or familiar concept] to make the concept easier to understand, and include a fun activity or experiment that can be done with common classroom materials to reinforce the lesson.`.split('\n')
        },
    ];

    return (

        <body>
            <div className="holder">
                <Nav />
                <header className="header-purple2 shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <div className="directions ">
                        <p> Check out the content below that we'll cover.</p>
                    </div> */}
                    <div className="title noMarginBottom">AI Tools for MCC Educators</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="AITools" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">🛠️</span> AI Tools</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="Library" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📚</span> Libraries</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="activities-section" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">🎨</span> Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="AILinks" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Globe">🌐</span> Socials</div>
                            </ScrollLink>

                            <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Contact">📱</span> Contact</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                        <section name='AITools' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🛠️ AI Tools</h2>
                            <div className="tool-grid">
                                <a href="https://chat.openai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={ChatGPT} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT</h3>
                                    <p className="tool-text">ChatGPT direct link.</p>
                                </a>
                                <a href="https://gptzero.me/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={GPTZero} alt="GPTZero" />
                                    </div>
                                    <h3 className="tool-title">GPTZero</h3>
                                    <p className="tool-text">AI Detection Tool</p>
                                </a>
                                <a href="https://app.magicschool.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Magic School</h3>
                                    <p className="tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>
                                <a href="https://schoolai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={SchoolAI} alt="SchoolAI" />
                                    </div>
                                    <h3 className="tool-title">SchoolAI</h3>
                                    <p className="tool-text">The all-in-one AI platform for your classroom. Designed for K12 students with built-in monitoring & controls for teachers.</p>
                                </a>
                                <a href="https://beta.diffit.me/#topic" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Diffit} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Diffit</h3>
                                    <p className="tool-text">Generate “just right” resources for anything.</p>
                                </a>
                                <a href="https://autoclassmate.io/tools/ai-powered-lesson-plan-generator/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Auto} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Auto Classmate</h3>
                                    <p className="tool-text">Automated lesson planning aligned with state standards.</p>
                                </a>
                            </div>
                        </section>
                        <section name='Library' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📚 Libraries</h2>
                            <div className="tool-grid">
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT Prompt Library</h3>
                                    <p className="tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                                <a href="https://gruvyeducation.com/aitools" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Tool} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Educator AI Tools Library</h3>
                                    <p className="tool-text">Collection of useful AI tools for educators</p>
                                </a>

                            </div>
                        </section>
                        <section id='activities-section' className="course-section course-activities-section">
                            <h2 className="course-sub-title">🎨 Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a>
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                    <a
                                        href={`#3`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        3
                                    </a>
                                    <a
                                        href={`#4`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        4
                                    </a>
                                    <a
                                        href={`#5`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        5
                                    </a>
                                    <a
                                        href={`#6`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        6
                                    </a>
                                    <a
                                        href={`#7`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        7
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Lesson Plan Template Creation with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="chatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts1.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                {/* <div key="2" id="2" className="course-content course-activity-content">
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Create a Local Environment-Based Question using ChatGPT
                                    </h3>
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="chatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/18hTXw4Y2QS43asIMkkw77QoeeD6sAy3Pl9JtUKovd9g/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="chatGPT" />
                                                    <span className="link-label">Prompt</span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts4.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}
                                    </form>
                                </div> */}
                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Using ChatGPT to Help Explain a Complex Topic in Simple Terms
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts3.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Role, Action, Context, Format
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="chatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Use the role-action-format prompt design and experiment with it in ChatGPT to understand how it works. For example, "As a teacher (role), create a rubric (action). The rubric is for a project where students had to create a poster about mitosis (context). Present the rubric in a table (format)."</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="4" id="4" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Using Magic School's Multiple Choice Assessments Tool
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/tools/mc-assessment" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow along with the instructor to create a multiple-choice assessment using Magic School's Multiple Choice Assessments Tool.</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="5" id="5" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Learning How To Use Diffit
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://beta.diffit.me/#topic" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Diffit} alt="Diffit" />
                                                    <span className="link-label">Diffit</span>
                                                </a>
                                                <a className="course-link-icon" href="https://youtu.be/nc0IyEkcSPI?si=7C2q1FN4OrB-MP1K" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={YouTube} alt="YouTube" />
                                                    <span className="link-label">Tutorial</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow along with the tutorial above to learn how to use Diffit.</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="6" id="6" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Learning How to Use SchoolAI
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.schoolai.com/space?code=BPQ5" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Abe} alt="Talk to Abraham Lincoln" />
                                                    <span className="link-label">Honest Abe</span>
                                                </a>
                                                <a className="course-link-icon" href="https://app.schoolai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={SchoolAI} alt="Learn How to Use SchoolAI" />
                                                    <span className="link-label">SchoolAI</span>
                                                </a>
                                                <a className="course-link-icon" href="https://youtu.be/bxGCPEU_N5c?si=OdDM94cnckhHERuT" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={YouTube} alt="YouTube" />
                                                    <span className="link-label">Tutorial</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Click the 'Honest Abe' link above to interact with Abraham Lincoln and explore the student side of SchoolAI.</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number">Follow the tutorial linked in the "Links Needed for Activity" section above to learn how to use SchoolAI. Access the teacher side of the platform by clicking on the "SchoolAI" button.</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="7" id="7" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Learn How to Use GPTZero, an AI Detection Tool
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://gptzero.me/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={GPTZero} alt="GPTZero" />
                                                    <span className="link-label">GPTZero</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">First, ask ChatGPT to summarize your favorite book. You can use this prompt:</p>
                                            <CopyToClipboard textToCopy="Write me a summary for [favorite book]." displayText="Write me a summary for [favorite book]." />
                                        </div>

                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Next, put that summary into GPTZero and press on "Check Origin".</p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='AILinks' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🌐 Socials</h2>
                            <div className="tool-grid">
                                <a href="https://gruvy-ai-tips.beehiiv.com/subscribe" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Newsletter} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Educator Exclusive AI Newsletter</h3>
                                    <p className="tool-text">Join our community of educators staying ahead of the curve with AI.</p>
                                </a>
                                <a href="https://twitter.com/Gruvy_edu" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={X} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">X</h3>
                                </a>
                                <a href="https://www.youtube.com/@GruvyEducation/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">YouTube</h3>
                                </a>
                                <a href="https://www.linkedin.com/company/gruvy-education/about/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={LinkedIn} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">LinkedIn</h3>
                                </a>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default MCC;
