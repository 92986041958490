import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import NavSourcewell from './NavSourcewell';
import US from '../images/usLogo.png'
import SchoolAI from '../images/SchoolAI.jpg'
import Guide from '../images/Guide.png'
import Abe from '../images/Abe.png'
import Khan from '../images/Khan.png'

const SourcewellBreakout2 = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    const prompts11 = [
        {
            id: 'student-behavior-plan',
            directions: "Copy and paste the prompt below into ChatGPT to develop a student behavior plan. Adjust the placeholders in square brackets to fit the specific context.",
            text: `As the principal, you need to develop a behavior plan for a student who has shown [Behavioral Issues]. Create a comprehensive plan that includes clear objectives, behavior expectations, monitoring methods, and potential interventions or rewards. Make sure the plan aligns with [School Policies] and is designed to be implemented over a [Timeframe]. Also, consider how to involve teachers, parents, and other staff in the process.`.split('\n')
        }
    ];

    const prompts16 = [
        {
            id: '1',
            directions: "Using the ChatGPT and the prompt below, create a list of ideas for AI-integrated in-class activities. Replace the \"[specific chatbot]\" variable with the chatbot you would like to use from SchoolAI. For example, if you want to use the Abe Lincoln SchoolAI chatbot, enter \"Abe Lincoln Chat Bot\" in the [specific chatbot] variable of the prompt. After generating your list of ideas, move to the next step to expand on the idea you like best.",
            text: "As a [grade] grade [subject] teacher, I need ideas for activities related to the topic of [specific topic] to engage my students in active learning while directly using [specific chatbot]. Please generate a list of activities that promote critical thinking, creativity, and deep understanding of the subject matter, while integrating [specific chatbot] as a learning tool. Ensure these ideas are tailored to the topic of [specific topic], encouraging students to think critically and creatively as they learn and participate simultaneously with the support of AI.".split('\n')
        },
        {
            id: '2',
            directions: "Send a follow-up prompt to expand on an idea and to formulate an AI-Integrated In-Class Activity.",
            text: "Explain the detailed steps and guidelines for implementing [Activity] in my classroom. Include any necessary materials, preparation, instructions for students, and ideas for assessment or reflection. Help me understand how to successfully integrate this activity into my lesson plan to promote critical thinking, creativity, and active learning among my students.".split('\n')
        },
    ];

    return (
        <body>
            <div className="holder">
                <NavSourcewell module="human" />
                <header className="shortHeader header-purple">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />

                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Ethical Use of AI in the Classroom</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <Link to="/sourcewell" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">⬅️🏡</span> Back to Home</div>
                            </Link>
                            <ScrollLink activeClass="active" to="resources" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">📦💡</span> Resources</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="william" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">🎯🏃</span> Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📞✉️</span> Contact Me</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section name='resources' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📦💡 Resources</h2>
                            <div className="tool-grid">
                                <a href="https://docs.google.com/document/d/1vmj03DQSovVUm0bTOsPgUH5yJCISilepKbAtzRW_JNc/edit?usp=sharing" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Doc} alt="Pre-Activity Guidelines for Using AI in the Classroom" />
                                    </div>
                                    <h3 className="tool-title">Pre-Activity Guidelines for Using AI in the Classroom</h3>
                                    <p className="tool-text">A succinct guide for educators on responsible AI usage.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/17plpqHScRAei2lvkrJmE1Tgvmz530eb0BHVnL3f1_lc/edit?usp=sharing" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Doc} alt="AI Classroom Use: Guidelines for Students" />
                                    </div>
                                    <h3 className="tool-title">AI Classroom Use: Guidelines for Students</h3>
                                    <p className="tool-text">An easy-to-understand graphic for students on using AI responsibly.</p>
                                </a>
                                <a href="https://docs.google.com/document/d/1wvBOr8CyALglw5RBVp0VICiu5Xb169fBBlHg_4I4MbU/edit?usp=sharing" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Doc} alt="AI in Education: Teacher's Checklist for Success" />
                                    </div>
                                    <h3 className="tool-title">AI in Education: Teacher's Checklist for Success</h3>
                                    <p className="tool-text">A practical checklist for teachers to effectively implement AI tools in the classroom.</p>
                                </a>
                                <a href="https://tech.ed.gov/ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={US} alt="U.S. Department of Education on AI Safety" />
                                    </div>
                                    <h3 className="tool-title">U.S. Department of Education on AI Safety</h3>
                                    <p className="tool-text">U.S. Department of Education analysis on AI's impact on education and AI safety.</p>
                                </a>
                                <a href="https://gruvyeducation.com/static/media/A%20Schools%20Guide%20to%20Student%20Data%20Privacy%20With%20AI.baac2c59cfae4f9dcc92.pdf" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Guide} alt="Gruvy Education's Guide to Data Privacy With AI" />
                                    </div>
                                    <h3 className="tool-title">Gruvy Education's Guide to Data Privacy With AI</h3>
                                    <p className="tool-text">A school's guide to data privacy with AI.</p>
                                </a>
                                <a href="https://schoolai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={SchoolAI} alt="SchoolAI" />
                                    </div>
                                    <h3 className="tool-title">SchoolAI</h3>
                                    <p class="tool-text">Designed to safely integrate AI into the classroom for K-12 students, with built-in monitoring and controls for teachers.</p>
                                </a>
                                <a href="https://chat.openai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={ChatGPT} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT</h3>
                                    <p className="tool-text">ChatGPT direct link.</p>
                                </a>
                                <a href="https://app.magicschool.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Magic School</h3>
                                    <p className="tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>
                            </div>
                        </section>
                        <section id='william' className="course-section course-activities-section">
                            <h2 className="course-sub-title">🎯🏃 Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a>
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                    <a
                                        href={`#3`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        3
                                    </a>
                                    <a
                                        href={`#4`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        4
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Exploring SchoolAI
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.schoolai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={SchoolAI} alt="ChatGPT" />
                                                    <span className="link-label">SchoolAI</span>
                                                </a>
                                                <a className="course-link-icon" href="https://app.schoolai.com/space?code=BTVF" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Abe} alt="ChatGPT" />
                                                    <span className="link-label">Abe</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Click on the "Abe" link in the "Links Needed for Activity" section to see the student side of SchoolAI and interact with Abe Lincoln! Explore the student side by chatting with Abe Lincoln.</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number">Click on the "SchoolAI" link in the "Links Needed for Activity" section to access the teacher side of SchoolAI. Once there, create an account.</p>
                                        </div>
                                        <div key="3" className="activity-question-container">
                                            <p className="poll-question question-number">"Spaces" are personalized chatbots you can send to students. As a teacher, when you create a space, students can chat one-on-one with the chatbot of your choosing. Press the big "Find a Space" button to explore the thousands of spaces. Once there, choose a space that piques your interest.</p>
                                        </div>
                                        <div key="4" className="activity-question-container">
                                            <p className="poll-question question-number">After you find a space you would like your students to chat with, select the space by clicking on it. On the right side of your screen, you will see a preview box. This is a spot for you to test out the chatbot before you launch it to students. Feel free to play around with it. When you are ready to launch, press the "Launch" button. The link that is generated is the link you send to students.</p>
                                        </div>
                                        <div key="5" className="activity-question-container">
                                            <p className="poll-question question-number">Next, paste the link into a new tab on your browser. Interact with the student side of the chatbot you just launched by asking it something your students might ask.</p>
                                        </div>
                                        <div key="6" className="activity-question-container">
                                            <p className="poll-question question-number">Now, return to the teacher side of SchoolAI to review the interaction. Switch back to the correct tab in your browser that has the teacher side of SchoolAI open. Click on the logo in the top left corner to return to the home page. Scroll down to the "Recent Sessions" section. Then, click on the chatbot that you just launched. You will see that your space has 1 participant (yourself). Click on the name to pull up the chat history. You will be able to see the chat history of every single student that joins your spaces.</p>
                                        </div>
                                        <div key="7" className="activity-question-container">
                                            <p className="poll-question question-number">Finally, click out of the chat history by clicking on the "X" in the top right corner. You will see where it lists the participants, it will have a smiley face emoji for students who are on track and a frowny face for students who may be using it inappropriately.</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Creating an AI-Integrated In-Class Activity with AI
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="11-1" className="activity-question-container">
                                            <p className="poll-question question-number ">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts16.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number ">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Exploring MagicStudent | The Student Side of Magic School
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/tools" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="MagicSchool" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Instructions */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Open the link in the "Links Needed for Activity" section. On the top of Magic School's home page, you will see a button for "MagicStudent." This is where you can explore which tools you would like to send to your students. Click on the "MagicStudent" button. This page is for you to test out the student tools, not for your students. Explore the library of AI tools for students.</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number">List 3 to 5 tools that you can see yourself having your students use on an assignment.</p>
                                        </div>
                                        <div key="3" className="activity-question-container">
                                            <p className="poll-question question-number">From the 3 to 5 tools you listed above, pick one and click on it. When you click on it, you will be able to test out the tool. Again, this page is for you as the teacher to test out the tool, not for the student. Use the tool, then review the output.</p>
                                        </div>
                                        <div key="4" className="activity-question-container">
                                            <p className="poll-question question-number">Now that you have tested out a student tool, we are going to explore how to launch a tool or multiple tools to your students. On the left side of your screen, you will see an option for "Launch to Students." Click on "Launch to Students."</p>
                                        </div>
                                        <div key="5" className="activity-question-container">
                                            <p className="poll-question question-number">You have now accessed your student room dashboard. On this page, a list of the student rooms you create will be listed. To launch your first room, click on the "Launch New Room" button in the top right corner. Create a Room Name, set the grade level, and set the maximum number of students that can join the room. Then, click on "Next" in the top right corner.</p>
                                        </div>
                                        <div key="6" className="activity-question-container">
                                            <p className="poll-question question-number">You will then be brought to a page where you select the tool or tools you would like to share with students (you can share multiple tools). Select the tool or tools you would like to send to students.</p>
                                        </div>
                                        <div key="7" className="activity-question-container">
                                            <p className="poll-question question-number">After you have selected the tools you would like to use, click on "Next" in the top right corner. The page you see now allows you to further customize the tools you are sharing. Feel free to click into a tool to customize it further. When you are done, click on "Launch Room" in the top right corner. Magic School will generate a link for you to send to students. Access it by clicking on "Join URL" in the popup that appears after you launch the room.</p>
                                        </div>
                                        <div key="8" className="activity-question-container">
                                            <p className="poll-question question-number">Now that you have created the student room, open a new tab on your web browser and paste the URL for the room in your search bar to join the student room. This is what students will see when you send them a room. Type in your name and click on "Join Room." You will be able to see the tool or tools that you wanted to share with your students. Click on one to use it and review the output.</p>
                                        </div>
                                        <div key="9" className="activity-question-container">
                                            <p className="poll-question question-number">Now, return to the teacher side of MagicStudent to review the interaction. Switch back to the correct tab in your browser that has the teacher side of MagicStudent open. Then, refresh the page. You will see that your room has 1 participant (yourself). Click on the "View Output" button next to the participant's name to pull up the participant's history. This is where you can see how the student used the tool. View the output by clicking on the name of the tool used.</p>
                                        </div>
                                        <div key="10" className="activity-question-container">
                                            <p className="poll-question question-number">Finally, to exit the output history, click the "X" in the top right corner. On the top of the page, you will see a button that says "Status: Active." If you press on that, you will see you have the ability to lock or pause the room. This will stop the students from being able to use the room. Spend some more time exploring the page, then, press "Back" in the top left corner to return to the page that lists all of your rooms. Again, take some time to explore. Type out your thoughts on MagicStudent below. Do you like it? Can you see yourself using it with your students?</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="5" id="5" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Khan Academy Khanmigo Analysis
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://youtu.be/yEgHrxvLsz0" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Khan} alt="Khan Academy" />
                                                    <span className="link-label">Khan</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Instructions */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Watch the video linked in the "Links Needed for Activity" section. Then, think about the following questions. Do you believe that Khan Academy's approach to AI in education is ethical? Do you think Khan Academy's way of using AI shows what the future will be like for AI in schools? What, if any, changes would you suggest for Khan Academy's approach to AI in education?</p>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📚💬 Breakout Sessions</h2>
                            <div className="tool-grid">
                                <Link to="/sourcewell/breakout1" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Communications} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Breakout #1</h3>
                                    <p className="tool-text">Maximizing the Potential of AI to Enhance Efficiency as an Educator</p>
                                </Link>
                                <Link to="/sourcewell/breakout2" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Breakout #2</h3>
                                    <p className="tool-text">Ethical Integration of AI in the Classroom</p>
                                </Link>
                                <Link to="/sourcewell/breakout3" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Breakout #3</h3>
                                    <p className="tool-text">Crafting Assignments to Promote Critical Thinking and Discourage AI-Assisted Cheating</p>
                                </Link>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default SourcewellBreakout2;
