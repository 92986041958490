import React, { useEffect } from 'react';
import Nav1 from './Nav';
import Delivery from '../images/Delivery.png'
import Bonus from '../images/Bonus.jpg'
import Guarantee from '../images/Guarantee.jpg'
import ISS from "../images/ISS.png";
import { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import emailjs from "emailjs-com"
import { Helmet } from 'react-helmet';
import { useLayoutEffect } from 'react';
import Head from "../images/WilliamGrubeHeadshot.JPG";
import Footer from './Footer';


const PresentationSales = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const [showButton, setShowButton] = useState(false);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    useEffect(() => {
        const checkScrollPosition = () => {
            if (!showButton && window.pageYOffset > 600) {
                setShowButton(true);
            } else if (showButton && window.pageYOffset <= 600) {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', checkScrollPosition);
        return () => window.removeEventListener('scroll', checkScrollPosition);
    }, [showButton]);

    const handleClick = () => {
        const element = document.getElementById('getStarted');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // useState hook to manage the state of the switch
    const [isSchool, setIsSchool] = useState(true);

    const toggleSwitch = () => {
        setIsSchool(!isSchool); // toggle the current value of isSchool
    };

    return (
        <div className="holder">
            <Helmet>
                <meta charSet="utf8" />
                <title>Teaching During the Rise of AI Presntation Information | Gruvy Education</title>
                <link   href="https://gruvyeducation.com/rise-of-ai" />
                <meta
                    name="keywords"
                    content="Teaching in AI Era, AI in Education, Gruvy Education, Future of Teaching, AI Impact on Teaching, Teaching During the Rise of AI"
                />
                <meta
                    name="description"
                    content="Join our presentation to understand how AI is shaping teaching methods and strategies. Discover the future of education with Gruvy Education."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Teaching During the Rise of AI Presntation Information | Gruvy Education" />
                <meta property="og:description" content="Join our presentation to understand how AI is shaping teaching methods and strategies. Discover the future of education with Gruvy Education." />
                <meta property="og:url" content="https://gruvyeducation.com/presentationinfo" />
                <meta property="og:type" content="website" />
            </Helmet>


            <Nav1 />
            <header className="shortHeader">
                <div className="container header-content noBottomMargin">
                    <div className="header-text text-white text-center">
                    </div>
                </div>
            </header>
            <ScrollToTop />
            {showButton && (
                <button className="scroll-to-top getStarted" onClick={handleClick}>
                    ✓ Get Started
                </button>
            )}
            <div className="sales-page sales-container">
                <div className="title">Teaching During the Rise of AI Presentation Information</div>


                <div className='floatingNav salesNav morePaddingBottom'>
                    <div className="floatingNavContainer salesNavContainer">
                        <Link
                            activeClass='active'
                            to='info'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Presenter Information
                        </Link>
                        <Link
                            activeClass='active'
                            to='method'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Delivery Method
                        </Link>
                        
                        <Link
                            activeClass='active'
                            to='testimonials'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Testimonials
                        </Link>
                        
                        <Link
                            activeClass='active'
                            to='bonus'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Pricing
                        </Link>
                        <Link
                            activeClass='active'
                            to='getStarted'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Get started
                        </Link>
                    </div>
                </div>
                <div className='trainingContent'>
                    <TrainingSection />
                    <PresenterInformation id="info" />
                    <DeliveryMethod id='method' />
                    <TestimonialsSection id='testimonials' />
                    <BonusesSection id='bonus' />
                    <GuaranteeSection id='moneyback' />
                    <Pricing />
                    <CallToActionSection id='getStarted' />
                </div>
            </div>
            <Footer />
        </div>
    );
}
const TrainingSection = () => {
    return (
        <section className="training-section sales-section">
            <div className="sales-title">Teaching During the Rise of AI Presentation</div>
            <h2 className="section-sub-title section-sub-title1">Have your staff take a one-hour journey to learn and ease worries about AI in education.</h2>
            <h2 className="urgent">Limited spots available due to high demand: <Link activeClass='active'
                to='getStarted'
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className='urgentLink1'>Get started</Link></h2>
            <p className="section-content session-content">
                In one hour, this presentation will catalyze a complete mental shift about the role of Artificial Intelligence (AI) in education. You'll gain insights into AI tools, their use by students, and how teaching methods can adapt in this AI era.
            </p>
            <br />
            <h3 className='sessionTitle'>Objective 1</h3>
            <p className="section-content session-content">
                Ignite awareness about AI tools in education, stimulating important conversations about their usage. The first step towards understanding these tools and their implications.
            </p>
            <br />
            <h3 className='sessionTitle'>Objective 2</h3>
            <p className="section-content session-content">
                Assist teachers in transforming their teaching methods, taking into account the existence of AI tools. This change is vital to ensure our educational systems continue to thrive in our rapidly evolving digital world.
            </p>
            <br />
            <h3 className='sessionTitle'>Objective 3</h3>
            <p className="section-content session-content">
                Uncover alternative methods for evaluating students' understanding in areas where AI tools may not be as effective, ensuring that students are genuinely grasping the concepts being taught.
            </p>
            <br />
            <h3 className='sessionTitle'>Objective 4</h3>
            <p className="section-content session-content">
                Address the educational shift towards arming students with problem-solving skills through the use of AI tools, setting them up for success in the future.
            </p>
            <br />
            <p className="section-content">
                By the end of this one-hour presentation, you will experience a transformative mental shift regarding AI's impact on education. Check out our <Link activeClass='active'
                    to='testimonials'
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className='testimonialLink'>testimonials</Link> to see how this presentation has empowered educators to integrate AI tools effectively into their classrooms, preparing both them and their students for a future shaped by AI.
            </p>
        </section>

    );
}

const PresenterInformation = (props) => {
    return (
        <section className="training-section sales-section" id={props.id}>
            <img className="section-image morePaddingBottom" src={Head} alt="Presenter" />
            <h2 className="section-title">Presenter Information</h2>
            <a target="_blank" href='https://gruvyeducation.com/about' className="section-sub-title williamTitle">William Grube</a>
            <p className="section-content">
                Currently pursuing an accelerated master's degree in computer science at North Dakota State University, William is well-versed in cutting-edge AI tools and technologies. His understanding of AI enables him to remain abreast of and adapt to its latest developments.
                <br />
                <br />
                William has enriched his expertise through numerous speaking engagements at schools, delivering comprehensive AI training to educators, and aiding schools with cybersecurity. This experience has given him an in-depth understanding of the technological needs and capabilities of educational institutions nationwide, equipping him with the skills to offer significant assistance.
                <br />
                <br />
                Driven by a strong desire to contribute meaningfully to the field of education and artificial intelligence, William is applying his knowledge and skills to make a notable impact on the future of AI in education.
            </p>
            <br />
            <br />
            <br />
            <br />
            <h2 className="section-title">Accolades</h2>
            <p className="section-content">
                <li>
                    <ul className='bulletPoint'>• Has delivered 30+ "Teaching During the Rise of AI" presentations at school districts nationwide.</ul>
                    <ul className='bulletPoint'>• Spoke at the Innovative School Summit in Las Vegas</ul>
                    <ul className='bulletPoint'>• Currently delivering AI in Education Training at 20+ School Districts</ul>
                </li>
            </p>
            <br />
            <br />
        </section>
    );
}

const DeliveryMethod = (props) => {
    return (
        <section className="training-section sales-section" id={props.id}>
            <img className="section-image" src={Delivery} alt="Delivery" />
            <h2 className="section-title">Delivery Method</h2>
            <p className="section-content">
                Choose the mode of delivery that suits your school:
                <li>
                    <br />
                    <ul className='bulletPoint'>• Traditional in-person presentation</ul>
                    <ul className='bulletPoint'>• Real-time online presentation conducted via Zoom</ul>
                </li>
            </p>
            <br />
            <br />
        </section>

    );
}

const TestimonialsSection = (props) => {
    return (
        <section className="testimonials-section sales-section" id={props.id}>
            <h2 className="section-title">My presentation has reached 30+ districts nationwide and has been featured at the Innovative Schools Summit.</h2>
            <br />
            <img className="section-image bigger-image" src={ISS} alt="Innovative Schools Summit" />
            <br />
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    You were unbelievably poised and eloquent. You were knowledgeable on the subject and provided realistic ways for educators to work with this technology instead of against it. I heard from many teachers that you were one of the most engaging professional development facilitators we've ever had.
                </blockquote>
                <div></div>
                <p>
                    Teaching During the Rise of AI attendee
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    You have done an amazing job training us in regards to utilizing AI in our current job and sharing information and prompts to help further our understanding of AI's capabilities. You also do an awesome job answering questions on the spot and guiding us in our thinking. Overall, you have equipped us with knowledge to help us in the present and the future which enables us to do better for our students.
                </blockquote>
                <div></div>
                <p>
                    Teaching During the Rise of AI attendee
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    Your understanding is very impressive. I believe that the greatest hope education has to "compete" with the tech of the future is to pay appropriately for young, talented people like yourself to continue teaching and supporting staff to learn in this new environment.
                </blockquote>
                <div></div>
                <p>
                    Teaching During the Rise of AI attendee
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    Staff appreciated hearing from a younger, relatable person that is experiencing AI firsthand.
                </blockquote>
                <div></div>
                <p>
                    Teaching During the Rise of AI attendee
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    Very eye-opening. I like how you were straightforward about how this technology is here to stay, and the job of an educator is to adapt to the technology. We are not to fear it, but to use it to our advantage. It is not necessarily only a student tool, but can be used by educators as well.
                </blockquote>
                <div></div>
                <p>
                    Teaching During the Rise of AI attendee
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    You were extemely helpful in how to use ChatGPT positively. You also did an excellent job reassuring me that I shouldn't panic about this technology.
                </blockquote>
                <div></div>
                <p>
                    Teaching During the Rise of AI attendee
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    Great job! Highly recommend!
                </blockquote>
                <div></div>
                <p>
                    Teaching During the Rise of AI attendee
                </p>
            </div>
        </section>
    );
}


const BonusesSection = (props) => {
    return (
        <section className="bonuses-section sales-section" id={props.id}>
            <img className="section-image" src={Bonus} alt="Bonuses" />
            <br />
            <h2 className="section-title">FREE Bonuses</h2>
            <p className="section-content bonuses">
                <div className="bonus">
                    <div className="green">✓</div>
                    <strong>Bonus: </strong>
                    <div className="bonusText">Gain exclusive email support for follow-up questions post-presentation.</div>
                </div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Access to extensive digital libraries of AI tools and learning materials.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Full participation earns educators a university-accredited badge. See here: <u><a target='_blank' href='https://www.credly.com/organizations/north-dakota-state-university/collections/ai-in-education/badge_templates'>Badges</a></u></div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Ethical resources that guide educators on responsible and equitable use of AI in the classroom.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Detailed report provided to admin staff to monitor participation.</div></div>
            </p>
        </section>
    );
}

const GuaranteeSection = (props) => {
    return (
        <section className="guarantee-section sales-section" id={props.id}>
            <img className="section-image" src={Guarantee} alt="Guarantee" />
            <h2 className="section-title">Money Back Guarantee</h2>
            <p className="moneybackGuarantee">If the majority of you're staff is not satisfied after the presentation, I guarantee a full refund of your payment.</p>
        </section>
    );
}
const Pricing = () => {
    const [selectedPricing, setSelectedPricing] = useState('in-person');

    const pricingDetails = {
        'in-person': (
            <div className="pricing-details">
                <h3 className='pricing-catagory-title'>In-Person Pricing:</h3>
                <ul className='pricing-list'>
                    <li className='pricing-list-option'>• For a student population less than 500: $500</li>
                    <li className='pricing-list-option'>• For a student population between 500-999: $750</li>
                    <li className='pricing-list-option'>• For a student population over 999: $1,000</li>
                </ul>
                <h3 className='sessionTitle'>In-person Training Mileage:</h3>
                <p className="section-content session-content">
                    Travel costs are calculated at the current US Mileage Rate.
                </p>
            </div>
        ),
        'virtual': (
            <div className="pricing-details">
                <h3 className='pricing-catagory-title'>Virtual Pricing:</h3>
                <ul className='pricing-list'>
                    <li className='pricing-list-option'>• For a student population less than 500: $300</li>
                    <li className='pricing-list-option'>• For a student population between 500-999: $400</li>
                    <li className='pricing-list-option'>• For a student population over 999: $500</li>
                </ul>
            </div>
        ),
    }

    const handleChange = (event) => {
        setSelectedPricing(event.target.value);
    }

    return (
        <section className="guarantee-section sales-section">
            <h2 className="section-title">Pricing</h2>
            <div className="radioButtonContainer">
                <div className="radio-button">
                    <input type="radio" id="in-person" name="pricing" value="in-person" checked={selectedPricing === 'in-person'} onChange={handleChange} />
                    <label htmlFor="in-person">In-person</label>
                </div>
                <div className="radio-button">
                    <input type="radio" id="virtual" name="pricing" value="virtual" checked={selectedPricing === 'virtual'} onChange={handleChange} />
                    <label htmlFor="virtual">Virtual</label>
                </div>
            </div>
            {pricingDetails[selectedPricing]}
        </section>
    );
}



const CallToActionSection = (props) => {
    return (
        <section className="guarantee-section sales-section" id={props.id}>
            <div className="bonus signUpBox" id='getStarted'>If you are interested in bringing the "Teaching During the Rise of AI" presentation to your school, click the button below to fill out the interest form.</div>
            <a className="enrollBtn" target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLScFHBSIdnj59eM11KtVDuuvbfA5piXtilA_QelV6G4EQgVqLg/viewform?usp=sf_link'>Fill Out Interest Form</a>
            <a className="urgent urgentLink" target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLScFHBSIdnj59eM11KtVDuuvbfA5piXtilA_QelV6G4EQgVqLg/viewform?usp=sf_link'>Limited spots available due to high demand.</a>
        </section>
    );
}




export default PresentationSales;