import React, { useEffect, useLayoutEffect, useState } from 'react';
import NavMoASSP from './NavMoASSPbreakout';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Footer from '../Footer';

const AboutGruvyEducationBreakout = () => {
    const [email, setEmail] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('');

        // Simple email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        // Open the application form in a new tab
        window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link',
            '_blank'
        );

        // Prepare data for EmailJS
        const serviceID = 'service_1g4hajp';
        const userID = 'user_HAMTDSUiL200UN9RQyPHI'; // Your EmailJS user/public key

        // Send email to the user
        const emailToUser = emailjs.send(
            serviceID,
            'template_noz4kkb', // Template ID for the user
            { user_email: email },
            userID
        );

        // Send email to the site owner
        const emailToOwner = emailjs.send(
            serviceID,
            'template_8l7zltk', // Template ID for you
            { user_email: email },
            userID
        );

        // Wait for both emails to be sent
        Promise.all([emailToUser, emailToOwner])
            .then(() => {
                setMessageSent(true);
                setEmail('');
            })
            .catch((error) => {
                console.error('Error:', error);
                setErrorMessage('An error occurred. Please try again later.');
            });
    };


    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className='holder'>
                <NavMoASSP module="about" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">About Gruvy Education</div>
                        </div>
                    </header>

                    <section className="MoASSP-section">
                        <div className="MoASSP-container MoASSP-container-about">
                            <p className="MoASSP-text">
                                Gruvy Education delivers AI training that simplifies AI for schools, addressing misconceptions and helping teachers use it to boost efficiency and student engagement. Our training is designed so all staff, regardless of experience, can easily grasp and apply AI.
                            </p>

                            <blockquote className="MoASSP-testimonial">
                                "This was by far the best professional development I have ever attended. The information we received will be immensely helpful to our daily lives as educators. I would recommend this training to all school districts. Absolutely fantastic!"
                                <cite>- AI Training Participant</cite>
                            </blockquote>
                            <h2 className="MoASSP-section-title">Key Stats</h2>
                            <div className="MoASSP-stats">
                                <div className="MoASSP-stat">
                                    <h3 className="MoASSP-stat-number">3.23 hours</h3>
                                    <p className="MoASSP-stat-text">
                                        Saved every week by teachers on preparation, content creation, and administrative tasks—over 100 hours a year per teacher!
                                    </p>
                                </div>
                                <div className="MoASSP-stat">
                                    <h3 className="MoASSP-stat-number">24.93%</h3>
                                    <p className="MoASSP-stat-text">
                                        Increase in student engagement while using technology in lessons after using AI in the classroom.
                                    </p>
                                </div>
                                <div className="MoASSP-stat">
                                    <h3 className="MoASSP-stat-number">25.87%</h3>
                                    <p className="MoASSP-stat-text">
                                        Increase in confidence in meeting the needs of diverse learners after taking the AI training.
                                    </p>
                                </div>
                                <div className="MoASSP-stat">
                                    <h3 className="MoASSP-stat-number">100+</h3>
                                    <p className="MoASSP-stat-text">
                                        Schools have adopted the training. The training is recommended by the Minnesota Association of Secondary School Principals and several other state level organizations.
                                    </p>
                                </div>
                            </div>

                            <h2 className="MoASSP-section-title">Our Four Training Modules</h2>
                            <div className="MoASSP-modules">
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Module 1: AI Overview and Shifting Perspectives</h3>
                                    <p className="MoASSP-module-text">
                                        Transformative keynote that shifts educators from fearing AI to understanding its presence and importance. Participants will feel excited about AI’s potential and learn how to capitalize on the positives while mitigating the risks. Includes low-pressure demos that allow staff to experiment with AI without fear of failure.
                                    </p>
                                    <blockquote className="MoASSP-module-testimonial">
                                        "I was probably the most pessimistic person when it came to the in-service today. You changed that. The material was presented in the most understandable way. Thank you for that. I plan to utilize this starting next week in my class."
                                        <cite>- AI Training Participant, Alexander Public Schools</cite>
                                    </blockquote>
                                </div>
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Module 2: Tackling Student Cheating</h3>
                                    <p className="MoASSP-module-text">
                                        Learn to create AI-resistant assignments and show that AI can actually reduce cheating by helping teachers create cheat-proof assessments. Terminate the “arms race” with anti-cheating technology by changing the way courses are delivered. Students will learn how to use technology productively, preparing them for college and career readiness.
                                    </p>
                                    <blockquote className="MoASSP-module-testimonial">
                                        "I really felt as though AI was not applicable to me as an early elementary teacher. I thought it would be a workshop helping high school teachers prevent students from using it on assignments. Wow, I was wrong! I can't wait to use AI tools to help enhance my teaching and students' experiences!"
                                        <cite>- AI Training Participant at Deuel School District</cite>
                                    </blockquote>
                                </div>
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Module 3: Responsible Student Use</h3>
                                    <p className="MoASSP-module-text">
                                        Focus on student responsible use, emphasizing that AI has biases and is not always correct. Learn how to responsibly use AI tools in the classroom to boost student engagement.
                                    </p>
                                    <blockquote className="MoASSP-module-testimonial">
                                        "The most beneficial way that AI is now used in our district is how it has helped increase student engagement in the classroom."
                                        <cite>- Brian Christopherson, Superintendent, New Salem-Almont School District</cite>
                                    </blockquote>
                                </div>
                                <div className="MoASSP-module">
                                    <h3 className="MoASSP-module-title">Module 4: Maximizing AI to Save Teachers Time</h3>
                                    <p className="MoASSP-module-text">
                                        Discover how to use AI tools to enhance life as an educator. Learn strategies to save time on preparation, content creation, and administrative tasks.
                                    </p>
                                    <blockquote className="MoASSP-module-testimonial">
                                        "This is the most informative PD event I've had in my teaching career. I believe that this is a topic & presentation that can massively move the needle for me to become a more effective teacher. In turn, students will learn more & I will have more time to spend on family/hobbies."
                                        <cite>- AI Training Participant, LaMoure Public School</cite>
                                    </blockquote>
                                </div>
                            </div>

                            <h2 className="MoASSP-section-title">Resources Included</h2>
                            <ul className="MoASSP-resources-list">
                                <li>1-year access to Gruvy Education’s online training portal.</li>
                                <li>Recordings of each module.</li>
                                <li>Library of resources including data privacy guide, student responsible use guide, framework for designing prompts, and more.</li>
                                <li>50+ video tutorials on how to use AI as a tool.</li>
                                <li>Bonus courses for special education teachers, administrative staff, counselors, ESL teachers, sports coaches, music teachers, and physical education teachers.</li>
                                <li>Opportunity to complete the training for 10+ hours of continuing education.</li>
                                <li>Earn four university-accredited badges.</li>
                                <li>Enroll in the course through North Dakota State University for a credit.</li>
                            </ul>
                            <div className="MoASSP-contact-section" id="apply">
                                <h2 className="MoASSP-section-title">
                                    Interested in Bringing Gruvy Education to Your School?
                                </h2>
                                <p className="MoASSP-text">Enter your email below to apply.</p>
                                {!messageSent ? (
                                    <>
                                        <form className="MoASSP-contact-form" onSubmit={handleSubmit}>
                                            <input
                                                type="email"
                                                className="MoASSP-input"
                                                placeholder="Enter your email to apply."
                                                aria-label="Enter your email to apply."
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <button type="submit" className="MoASSP-submit-button">
                                                Apply
                                            </button>
                                        </form>
                                        {errorMessage && (
                                            <p className="MoASSP-error-message">{errorMessage}. Please contact william.grube@gruvyeducation.com directly.</p>
                                        )}
                                    </>
                                ) : (
                                    <p className="MoASSP-success-message">
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            style={{ color: '#005d41', marginRight: '10px' }}
                                            className='yayFontAwesome'
                                        />
                                        Thank you for your interest! We'll be in touch soon.
                                    </p>
                                )}
                            </div>
                        </div>
                    </section>
                </section>
                <footer id="contact" className="MoASSP-footer">
                    <div className="MoASSP-footer-container">
                        <div className="MoASSP-footer-section">
                            <h4 className="MoASSP-footer-heading">About Gruvy Education</h4>
                            <p className="MoASSP-footer-text">
                                We deliver AI training to schools and major educational associations nationwide.
                            </p>
                        </div>
                        <div className="MoASSP-footer-section">
                            <h4 className="MoASSP-footer-heading">Contact</h4>
                            <a className="MoASSP-footer-link" href="mailto:william.grube@gruvyeducation.com">
                                Email: william.grube@gruvyeducation.com
                            </a>
                            <a className="MoASSP-footer-link" href="tel:507-476-8096">
                                Phone: 507-476-8096
                            </a>
                        </div>
                        <div className="MoASSP-footer-section">
                            <h4 className="MoASSP-footer-heading">Follow Us</h4>
                            <a
                                className="MoASSP-footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://twitter.com/Gruvy_edu"
                            >
                                Twitter
                            </a>
                            <a
                                className="MoASSP-footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.linkedin.com/in/william-grube-16200a177/"
                            >
                                LinkedIn
                            </a>
                            <a
                                className="MoASSP-footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/profile.php?id=100092009478609"
                            >
                                Facebook
                            </a>
                        </div>
                    </div>
                    <div className="MoASSP-footer-bottom">
                        <p className="MoASSP-footer-copyright">
                            &copy; {new Date().getFullYear()} Gruvy Education | All rights reserved.
                        </p>
                    </div>
                </footer>
            </div>
        </body>
    );
};

export default AboutGruvyEducationBreakout;
