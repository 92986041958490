import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'
import { getAuth, GoogleAuthProvider} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCjqGNQITvVR879DCytmf224IVP0WF_B7g",
  authDomain: "teachingduringtheriseofai.firebaseapp.com",
  projectId: "teachingduringtheriseofai",
  storageBucket: "teachingduringtheriseofai.appspot.com",
  messagingSenderId: "269383630744",
  appId: "1:269383630744:web:132881b62ee78e7b6b75ed",
  measurementId: "G-832DKFXBR8"
};

const firebaseConfig2 = {
  apiKey: "AIzaSyDQ3A2Iz1fOBNBpjvBEyZ3Qvjf1el22qJ0",
  authDomain: "gruvyai.firebaseapp.com",
  projectId: "gruvyai",
  storageBucket: "gruvyai.appspot.com",
  messagingSenderId: "299789268239",
  appId: "1:299789268239:web:ee533151ca8b7216a0ba7a",
  measurementId: "G-2REY75HSZ7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
export const auth = getAuth(app)


// Initialize the second Firebase app
const app2 = initializeApp(firebaseConfig2, "GruvyAI");
const db2 = getFirestore(app2);
const storage2 = getStorage(app2);
const auth2 = getAuth(app2);

// Export the instances of the second app
export { db2, storage2, auth2 };

export default db;