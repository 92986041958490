import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'

const NDASAbusinessServices = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    const prompts1 = [
        {
            id: 'rfbAd',
            directions: "Copy and paste the prompt below into ChatGPT to write a Request for Bid Advertisement.",
            text: "Act as if you are the Superintendent of a school District in North Dakota and seeking to contract snow removal services for the four facilities in your district. Write a Request for Bid Advertisement with as much detail as you can, I will fill out the rest."
        },
        {
            id: 'scoringRubric',
            directions: "Copy and paste the prompt below into ChatGPT to create an objective rubric for scoring the bid proposals.",
            text: "Can you please create an objective rubric we can use to score the bid proposals for snow removal services?"
        },
        {
            id: 'responseLetters',
            directions: "Copy and paste the prompt below into ChatGPT to write letters to the RFP respondents.",
            text: "Please write letters to the RFP Respondents. Write one letter we will use for the award recipient and another letter for those we are not selecting for snow removal service."
        },
        {
            id: 'schoolBoardMemo',
            directions: "Copy and paste the prompt below into ChatGPT to write a memo to the school board.",
            text: "Write a memo to my school board that outlines the snow removal services bid process and recommends that they award the bid to the recipient I selected. Explain the objectivity in this process and detail the steps."
        },
        {
            id: 'contractDraft',
            directions: "Copy and paste the prompt below into ChatGPT to draft a contract for the snow removal services.",
            text: "My district is NDCEL Public Schools and we awarded the business to Copas Snow Removal. Please draft a contract for utilizing this service."
        }
    ];

    const prompts2 = [
        {
            id: 'rfp',
            directions: "Copy and paste the prompt below into ChatGPT to write a Request for Proposal. Fill in the variables in square brackets with specific details relevant to your school and project.",
            text: `As a school administrator at [School Name], I need to draft a Request for Proposal (RFP) for [Project or Service Description]. The RFP should be detailed and structured to attract qualified vendors or service providers. Please include the following sections in the RFP:\n\nIntroduction and Overview:\n- Briefly introduce [School Name] and the purpose of the RFP.\n- Overview of the project or service needed.\n\nScope of Work:\n- Detailed description of the project or services required.\n- Specific objectives and expected outcomes.\n- Any necessary timelines or milestones.\n\nVendor Qualifications and Requirements:\n- List the essential qualifications and experience required from vendors.\n- Any specific certifications or credentials needed.\n\nProposal Submission Guidelines:\n- Instructions for how and where to submit proposals.\n- Deadline for proposal submissions.\n- Required format and contents of the proposal (e.g., technical approach, budget breakdown, project timeline).\n\nEvaluation Criteria:\n- Criteria and process for how proposals will be evaluated.\n- Weightage given to different aspects of the proposal.\n\nBudget and Funding:\n- Outline available budget or budget constraints.\n- Information on any funding sources or grants.\n\nPoint of Contact:\n- Provide contact information for inquiries and proposal submissions.\n\nTerms and Conditions:\n- Legal and procedural terms governing the RFP process and subsequent agreements.\n\nEnsure the RFP is clear, comprehensive, and professionally formatted, with formal and precise language tailored to attract reputable and capable vendors.`.split('\n')
        },
    ];

    const prompts3 = [
        {
            id: 'mou',
            directions: "Copy and paste the prompt below into ChatGPT to draft a Memorandum of Understanding. Fill in the variables in square brackets with specific details relevant to your school and the partner organization.",
            text: `I am a school administrator working on drafting a Memorandum of Understanding (MOU) for a collaboration between our school, [School Name], and another organization, [Partner Organization Name]. The MOU should address the following key points:\n\nPurpose of the Partnership:\n[ Briefly describe the main objectives and goals of this partnership.]\n\nRoles and Responsibilities:\nFor [School Name]:\n- Outline specific responsibilities and commitments.\nFor [Partner Organization Name]:\n- Detail their expected contributions and roles.\n\nDuration of the Agreement:\n- State the start and end dates of the MOU, including any conditions for renewal.\n\nFinancial Arrangements (if applicable):\n- Describe any financial commitments or exchanges, including funding sources and budget allocations.\n\nConfidentiality and Data Sharing:\n- Clarify any data sharing agreements and confidentiality protocols, particularly concerning student information.\n\nDispute Resolution Mechanism:\n- Provide a brief outline of how disagreements or misunderstandings will be resolved.\n\nTermination Clause:\n- Include terms under which the MOU can be terminated by either party.\n\nSignatory Authority:\n- Specify who has the authority to sign the MOU on behalf of each organization.\n\nHelp me draft an MOU incorporating these points. The language should be formal yet accessible, ensuring clarity and mutual understanding. Additionally, I'd appreciate it if you could format the MOU in a standard business letter format, ready for review and signatures.`.split('\n')
        },
    ];

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    return (

        <body>
            <div className="holder">
                <Nav module="business"/>
                <header className="header-purple4 shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Using AI for Business Services with Dr. Gandhi</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <Link to="/ndasa" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">⬅️🏡</span> Back to Home</div>
                            </Link>
                            <ScrollLink activeClass="active" to="william" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">💼📈</span> Business Service Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">🗨️👥</span> Breakout Sessions</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section id='william' className="course-section course-activities-section">
                            <h2 className="course-sub-title">💼📈 Business Service Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a>
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                    <a
                                        href={`#3`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        3
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Leveraging ChatGPT to Enhance Snow Removal Request for Bids (RFB) Campaign
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts1.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Drafting a Request for Proposal
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/1jN83gDImB5jHeiftNDQR1EPfp5uJJ06ZO7MHJ-YuBkU/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="ChatGPT" />
                                                    <span className="link-label">Prompt Doc</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts2.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Utilizing ChatGPT for Drafting a Memorandum of Understanding
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/1NyA6ONZQupD-brWGx2bsYOalw4xsKLf7GEDtkTjpR4w/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="ChatGPT" />
                                                    <span className="link-label">Prompt Doc</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts3.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🗨️👥 Breakout Sessions</h2>
                            <div className="tool-grid">
                                <Link to="/ndasa/humancapitol" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Human Capital - Dr. McNeff</h3>
                                    <p className="tool-text">Evaluation of employees, Documenting employees, Coaching employees.</p>
                                </Link>
                                <Link to="/ndasa/businessservices" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Business Services - Dr. Gandhi</h3>
                                    <p className="tool-text">Writing MOUs/RFPs, Reviewing Bids, Analyzing Budget</p>
                                </Link>
                                <Link to="/ndasa/teachingandlearning" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={TeachingLearning} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Teaching and Learning - William Grube</h3>
                                    <p className="tool-text">Leave equipted to bring AI tools to your staff.</p>
                                </Link>
                                <Link to="/ndasa/communication" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Communications} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Communications - Stephanie Hochhalter</h3>
                                    <p className="tool-text">Emails to parents, Social posts, Applying for grants</p>
                                </Link>
                            </div>
                        </section>
                        <section name='NextSteps' className="course-section course-resources-section">
                            <h2 className="course-sub-title">✅ Next Steps</h2>
                            <div className="tool-grid">
                                <a href="https://youtu.be/kap60trj-K8" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Youtube" />
                                    </div>
                                    <h3 className="tool-title">Learn about Gruvy Ecucation's AI Training Opportunities for K-12 Educators</h3>
                                    <p className="tool-text">Less than 5 minute explanation video.</p>
                                </a>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Form} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Bring Gruvy Education's AI Training to Your Educators</h3>
                                    <p className="tool-text">Join the 40+ schools participating in our AI in Education Training.</p>
                                </a>
                                {/* <a href="https://usemotion.com/meet/william-grube/meeting?d=30" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Schedule a time to come see me today.</h3>
                                    <p className="tool-text">I will be set up in Hall B.</p>
                                </a> */}
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default NDASAbusinessServices;
