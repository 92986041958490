import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendEmailVerification,
    updateProfile,
    GoogleAuthProvider,
    signInWithPopup
} from 'firebase/auth'
import { auth, auth2, db2 } from "../Firebase";
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

const UserContext = createContext()

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({})
    const [user2, setUser2] = useState({});

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    }

    const googleSignIn2 = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth2, provider);
            const user = result.user;

            // Check if user document exists
            const userDocRef = doc(db2, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                // Create a new document if it doesn't exist
                await setDoc(userDocRef, {
                    email: user.email,
                    displayName: user.displayName,
                    profilePicture: user.photoURL || '',
                    phoneNumber: user.phoneNumber || '',
                    emailVerified: user.emailVerified,
                    providerId: user.providerData.map(provider => provider.providerId),
                    creationTime: new Date().toISOString(),
                    lastSignInTime: new Date().toISOString(),
                    isActive: true, // Assuming new accounts are active by default
                    accountType: 'individual', // Example, set based on your app logic
                    optInMarketing: false, // Default to false, update based on user preference
                    // ... other fields as needed
                });

            }

            // No need to return signInWithPopup again
            return user; // You can return the user object if needed elsewhere
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            // Handle the error appropriately
            // Example: showErrorToUser(error);
        }
    };

    const createUser = async (email, password, name) => {
        try {
            await createUserWithEmailAndPassword(auth, email, password).catch((err) =>
                console.log(err)
            );
            await sendEmailVerification(auth.currentUser).catch((err) =>
                console.log(err)
            );
            await updateProfile(auth.currentUser, { displayName: name }).catch(
                (err) => console.log(err)
            );
        } catch (err) {
            console.log(err);
        }
    }

    const createUser2 = async (email, password, name) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth2, email, password);
            const user = userCredential.user;

            await sendEmailVerification(user);
            await updateProfile(user, { displayName: name });

            // Create a Firestore document for the new user
            const userDocRef = doc(db2, 'users', user.uid);
            await setDoc(userDocRef, {
                email: user.email,
                displayName: name,
                profilePicture: user.photoURL || '',
                emailVerified: user.emailVerified,
                creationTime: new Date().toISOString(),
                lastSignInTime: new Date().toISOString(),
                isActive: true,
                accountType: 'individual',
                optInMarketing: false,
                // ... other fields as needed
            });

            return user; // Return the user object for further use
        } catch (error) {
            console.error("Error during user creation:", error);

            // Handle specific errors
            switch (error.code) {
                case 'auth/email-already-in-use':
                    throw new Error('This email address is already in use.');
                case 'auth/invalid-email':
                    throw new Error('The email address is invalid.');
                case 'auth/operation-not-allowed':
                    throw new Error('Account creation is not allowed at this time.');
                case 'auth/weak-password':
                    throw new Error('The password is too weak. Please use a stronger password.');
                default:
                    throw new Error('Failed to create an account. Please try again.');
            }
        }
    }

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const signIn2 = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth2, email, password);
            const user = userCredential.user;

            // Update last sign-in time in Firestore
            const userDocRef = doc(db2, 'users', user.uid);
            await updateDoc(userDocRef, {
                lastSignInTime: new Date().toISOString(),
            });

            return user; // Return the user object for further use
        } catch (error) {
            console.error("Error during sign-in:", error);

            // Handle specific errors
            switch (error.code) {
                case 'auth/invalid-email':
                    throw new Error('The email address is invalid.');
                case 'auth/user-disabled':
                    throw new Error('The user account has been disabled.');
                case 'auth/user-not-found':
                    throw new Error('No user found with this email address.');
                case 'auth/wrong-password':
                    throw new Error('Incorrect password.');
                default:
                    throw new Error('Incorrect Login. Please try again.');
            }
        }
    }


    const logout = () => {
        return signOut(auth)
    }

    const logout2 = async () => {
        try {
            // Optional: Perform any pre-logout actions if necessary
            // Example: update user's status in Firestore

            const user = auth2.currentUser;
            if (user) {
                const userDocRef = doc(db2, 'users', user.uid);
                await updateDoc(userDocRef, {
                    // Example: Update user's status or last sign-out time
                    isActive: false, // If you are tracking active sessions
                    lastSignOutTime: new Date().toISOString(),
                });
            }

            // Perform the sign-out operation
            await signOut(auth2);

            // Optional: Perform any post-logout cleanup operations
        } catch (error) {
            console.error("Error during logout:", error);
            // Handle the error appropriately
            // Example: showErrorToUser(error);
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        const unsubscribe2 = onAuthStateChanged(auth2, (currentUser2) => {
            setUser2(currentUser2);
        });

        return () => {
            unsubscribe();
            unsubscribe2();
        };
    }, []);


    return (
        <UserContext.Provider value={{ createUser, user, logout, signIn, googleSignIn, createUser2, user2, logout2, signIn2, googleSignIn2 }}>
            {children}
        </UserContext.Provider>
    )
}


export const UserAuth = () => {
    return useContext(UserContext)
}