import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import db from '../Firebase';

const AdminProgressReport = () => {
  const { schoolURL } = useParams();
  const schoolDoc = doc(db, 'School', schoolURL);
  const emailsRef = collection(schoolDoc, "Emails");
  const [schoolPic, setSchoolPic] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [resultsAreLoaded, setResultsAreLoaded] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [progressData, setProgressData] = useState({});
  const [idealData, setIdealData] = useState({});
  const [completionReport, setCompletionReport] = useState({});
  const [showProgress, setShowProgress] = useState({});
  const [expandedDays, setExpandedDays] = useState({});

  const toggleProgress = (email) => {
    setShowProgress((prev) => ({ ...prev, [email]: !prev[email] }));
  };

  useEffect(() => {
    const fetchIdealData = async () => {
      let newIdealData = {};
      const asyncDocRef = doc(schoolDoc, 'Content', 'Async');
      const asyncDocSnap = await getDoc(asyncDocRef);
      const numberOfDays = asyncDocSnap.data().numberOfDays;

      for (let i = 1; i <= numberOfDays; i++) {
        const dayRef = collection(asyncDocRef, `Day ${i}`);
        const daySnapshot = await getDocs(dayRef);

        const dayData = {
          activities: {},
          closingQuestions: {},
          polls: {},
          resources: {}
        };

        // Loop through each document in the day
        daySnapshot.forEach((doc) => {
          const docData = doc.data();
          const docId = doc.id;

          if (docId.startsWith('Activity')) {
            dayData.activities[docId] = docData;
          } else if (docId === 'Closing Questions') {
            Object.keys(docData).forEach((key) => {
              if (key.startsWith('Question')) {
                dayData.closingQuestions[key] = docData[key];
              }
            });
          } else if (docId === 'Lecture') {
            Object.keys(docData).forEach((key) => {
              if (key.startsWith('Poll')) {
                dayData.polls[key] = docData[key];
              }
            });
          } else if (docId.startsWith('Resource')) {
            dayData.resources[docId] = docData;
          }
        });

        newIdealData[`Day ${i}`] = dayData;
      }

      console.log(newIdealData);
      setIdealData(newIdealData);
    };

    fetchIdealData();
  }, []);

  useEffect(() => {
    const fetchSchoolDoc = async () => {
      const schoolDocSnap = await getDoc(schoolDoc);
      if (schoolDocSnap.exists()) {
        setSchoolName(schoolDocSnap.data().Name);
        setSchoolPic(schoolDocSnap.data().icon);
      } else {
        console.log('No such document exists!');
      }
    };
    fetchSchoolDoc();
  }, []);



  // Get participant information
  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(emailsRef);
      let participants = [];
      querySnapshot.forEach((doc) => {
        participants.push({
          name: doc.data().Name,
          email: doc.data().Email,
          // Add other fields here
        });
      });
      setParticipants(participants);
    };
    fetchData();
  }, [schoolName]);

  // Populate progressData
  useEffect(() => {
    if (participants.length === 0) return;

    const fetchParticipantData = async () => {
      let newProgressData = {};

      // First, fetch the number of days from schoolDoc -> Content -> Async -> numberOfDays
      const asyncDocRef = doc(schoolDoc, 'Content', 'Async');
      const asyncDocSnap = await getDoc(asyncDocRef);
      const numberOfDays = asyncDocSnap.data().numberOfDays;

      // Create an array of dayNames based on numberOfDays
      let dayNames = Array.from({ length: numberOfDays }, (_, i) => `Day ${i + 1}`);

      for (let participant of participants) {
        const participantRef = doc(emailsRef, participant.email);  // Reference to each participant's doc
        let participantProgress = {};

        for (const dayName of dayNames) {
          const dayRef = collection(participantRef, dayName);
          const daySnapshot = await getDocs(dayRef); // Fetch all documents in the "Day" collection

          let activities = {};
          let clickedResources = {};
          let polls = {};
          let closingQuestions = {};

          daySnapshot.forEach(doc => {
            const docId = doc.id;
            const docData = doc.data();

            if (docId.startsWith('Activity')) {
              let questions = {};
              for (const [key, value] of Object.entries(docData)) {
                if (key.startsWith("Question")) {
                  questions[key] = value;
                }
              }
              activities[docId] = questions;
            }

            else if (docId === 'ClickedResources') {
              clickedResources = docData; // Assumes this is already in the format you want
            }

            else if (docId.startsWith('Poll')) {
              polls[docId] = docData.answer; // Assumes the field for answer is named 'answer'
            }

            else if (docId.startsWith('Question')) {
              closingQuestions[docId] = docData.answer; // Assumes the field for answer is named 'answer'
            }
          });

          let dayData = {
            activities: activities,
            clickedResources: clickedResources,
            polls: polls,
            closingQuestions: closingQuestions
          };

          // Set the dayData into participantProgress
          participantProgress[dayName] = dayData;
        }

        // Set participantProgress into newProgressData
        newProgressData[participant.email] = participantProgress;
      }

      console.log(newProgressData)
      setProgressData(newProgressData);
      setResultsAreLoaded(true);
    };

    fetchParticipantData();
  }, [participants]);

  return (
    schoolPic ? (
      <div className="poll-result-container">
        <div className="poll-result-school">
          <img className="poll-result-school-pic" src={schoolPic} />
          <div className="poll-result-school-name">{schoolName}</div>
        </div>
        <h1>AI in Education Training Progress Report</h1>
        {resultsAreLoaded ? (
          <ul className="admin-result-list">
            {participants.map((participant, index) => (
              <div className={`admin-report-participant-container ${showProgress[participant.email] ? 'active' : ''}`}>
                <div className="admin-report-participant-card">
                  <div className="admin-report-participant-details">
                    <span>{participant.name}</span>
                    <span>{participant.email}</span>
                  </div>
                  <div className="admin-report-participant-day-info">
                    {
                      Object.keys(idealData)
                        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
                        .map((day, index) => {

                          // Extract ideal data for polls, activities, and questions
                          const idealPolls = Object.keys(idealData[day].polls || {});
                          const idealActivities = Object.keys(idealData[day].activities || {});
                          const idealQuestions = Object.keys(idealData[day].closingQuestions || {});

                          // Extract progress data for the participant
                          const progressDataForDay = progressData[participant.email][day] || {};
                          const progressPolls = Object.keys(progressDataForDay.polls || {});
                          const progressActivities = Object.keys(progressDataForDay.activities || {});
                          const progressQuestions = Object.keys(progressDataForDay.closingQuestions || {});

                          // Normalize the keys for easier comparison
                          const normalizeKeys = arr => arr.map(key => key.replace(/\s+/g, '').toLowerCase());
                          const normalizedIdealPolls = normalizeKeys(idealPolls);
                          const normalizedIdealActivities = normalizeKeys(idealActivities);
                          const normalizedIdealQuestions = normalizeKeys(idealQuestions);

                          const normalizedProgressPolls = normalizeKeys(progressPolls);
                          const normalizedProgressActivities = normalizeKeys(progressActivities);
                          const normalizedProgressQuestions = normalizeKeys(progressQuestions);

                          // Additional code to check if all questions in each activity are complete
                          const areAllQuestionsInActivitiesComplete = Object.keys(idealData[day].activities || {}).every(activity => {
                            const idealQuestions = Object.keys(idealData[day].activities[activity]).filter(key => key.startsWith("Question"));
                            return idealQuestions.every(questionKey => {
                              const spaceQuestionKey = questionKey.replace(/(\d+)/, ' $1')
                              return progressData[participant.email][day]?.activities?.[activity]?.[spaceQuestionKey];
                            });
                          });

                          // Check if each item is complete for the day
                          const isPollsComplete = normalizedIdealPolls.every(poll => normalizedProgressPolls.includes(poll));
                          const isActivitiesComplete = areAllQuestionsInActivitiesComplete;
                          const isQuestionsComplete = normalizedIdealQuestions.every(question => normalizedProgressQuestions.includes(question));

                          // Check if the day as a whole is complete
                          const isDayComplete = isPollsComplete && isActivitiesComplete && isQuestionsComplete;

                          const dayProgress = progressData[participant.email][day] || {};
                          const isAnyActivityDone = Object.keys(dayProgress.activities || {}).length > 0;
                          const isAnyPollDone = Object.keys(dayProgress.polls || {}).length > 0;
                          const isAnyQuestionDone = Object.keys(dayProgress.closingQuestions || {}).length > 0;

                          const isAnyProgress = isAnyActivityDone || isAnyPollDone || isAnyQuestionDone;

                          // Determine the day's completion status
                          let dayStatus;
                          if (isDayComplete) {
                            dayStatus = "Complete";
                          } else if (isAnyProgress) {
                            dayStatus = "In Progress";
                          } else {
                            dayStatus = "Incomplete";
                          }

                          // Render the day's completion status
                          return (
                            <span key={index}>
                              {day} :
                              &nbsp;<span className={`admin-report-day-${dayStatus === "In Progress" ? "pending" : dayStatus.toLowerCase()}`}>
                                {dayStatus}
                              </span>
                            </span>
                          );
                        })
                    }
                  </div>
                  <button
                    className={`show-details-button ${showProgress[participant.email] ? 'active' : ''}`}
                    onClick={() => toggleProgress(participant.email)}
                  >
                    Show Details
                  </button>
                </div>
                {showProgress[participant.email] && (
                  <div className={`collapsible-content ${showProgress[participant.email] ? 'show' : ''}`}>
                    {Object.keys(idealData)
                      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
                      .map((day, index) => {

                        const isDayExpanded = expandedDays[`${participant.email}-${day}`] || false;

                        return (
                          <div key={index} className="admin-report-day-container">
                            <h3
                              className="admin-report-day-title"
                              onClick={() => setExpandedDays({
                                ...expandedDays,
                                [`${participant.email}-${day}`]: !isDayExpanded
                              })}
                            >
                              {day} <span className='cursorPointer'>{isDayExpanded ? "▼" : "▶"}</span>
                            </h3>
                            {isDayExpanded && (
                              <div>
                                <div className="admin-report-section">
                                  <h4 className="admin-report-section-title">Polls</h4>
                                  <div className="admin-report-item-list">
                                    {(() => {
                                      // Keep the original keys alongside the normalized ones
                                      const originalAndNormalizedIdealKeys = Object.keys(idealData[day].polls).map(key => ({
                                        original: key.replace(/(\d+)/, ' $1'),  // Inserts a space before the number
                                        normalized: key.replace(/\s+/g, '')
                                      }));

                                      const normalizedProgressKeys = Object.keys(progressData[participant.email][day]?.polls || {})
                                        .map(key => key.replace(/\s+/g, ''))
                                        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

                                      return originalAndNormalizedIdealKeys.map(({ original, normalized }) => {
                                        const pollStatus = normalizedProgressKeys.includes(normalized);
                                        const displayText = original.replace(/(\d+)/, ' $1');  // Inserts a space before the number

                                        return (
                                          <div key={original} className="admin-report-field-container">
                                            <div className="admin-report-field-inner-container">
                                              <h5 className="admin-report-field-title">{displayText}</h5>
                                              <p className="admin-report-field-detail">
                                                {idealData[day].polls[normalized]}:
                                                <span className={pollStatus ? "admin-report-field-status-complete" : "admin-report-field-status-incomplete"}>
                                                  &emsp;{pollStatus ? progressData[participant.email][day]?.polls?.[original] : "Incomplete"}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      });
                                    })()}
                                  </div>
                                </div>

                                <div className="admin-report-section">
                                  <h4 className="admin-report-section-title">Activities</h4>
                                  <div className="admin-report-item-list">
                                    {Object.keys(idealData[day].activities)
                                      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
                                      .map(activity => (
                                        <div key={activity} className="admin-report-field-container">
                                          <h5 className="admin-report-field-title">{activity}</h5>
                                          {Object.keys(idealData[day].activities[activity])
                                            .filter(key => key.startsWith("Question"))
                                            .map(questionKey => {
                                              const spaceQuestionKey = questionKey.replace(/(\d+)/, ' $1')
                                              const questionText = idealData[day].activities[activity][questionKey];
                                              const answerText = progressData[participant.email][day]?.activities?.[activity]?.[spaceQuestionKey] || "Incomplete";
                                              return (
                                                <div key={questionKey} className="admin-report-field-inner-container">
                                                  <p className="admin-report-field-detail">
                                                    {questionText}:
                                                    <span className={answerText === "Incomplete" ? "admin-report-field-status-incomplete" : "admin-report-field-status-complete"}>
                                                      &emsp;{answerText}
                                                    </span>
                                                  </p>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      ))}
                                  </div>
                                </div>

                                <div className="admin-report-section">
                                  <h4 className="admin-report-section-title">Closing Questions</h4>
                                  <div className="admin-report-item-list">
                                    {(() => {
                                      // Normalize and sort the keys
                                      const normalizedIdealQuestionKeys = Object.keys(idealData[day].closingQuestions)
                                        .map(key => key.replace(/\s+/g, ''))
                                        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
                                      const normalizedProgressQuestionKeys = Object.keys(progressData[participant.email][day]?.closingQuestions || {})
                                        .map(key => key.replace(/\s+/g, ''))
                                        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

                                      return normalizedIdealQuestionKeys.map(question => {
                                        // Check the question status using normalized keys
                                        const questionStatus = normalizedProgressQuestionKeys.includes(question);
                                        const displayText = question.replace(/(\d+)/, ' $1'); // Inserts a space before the number

                                        return (
                                          <div key={question} className="admin-report-field-container">
                                            <div className="admin-report-field-inner-container">
                                              <h5 className="admin-report-field-title">{displayText}</h5>
                                              <p className="admin-report-field-detail">
                                                {idealData[day].closingQuestions[question]}:
                                                <span className={questionStatus ? "admin-report-field-status-complete" : "admin-report-field-status-incomplete"}>
                                                  &emsp;{questionStatus ? progressData[participant.email][day]?.closingQuestions?.[displayText] : "Incomplete"}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      });
                                    })()}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )
                }
              </div>
            ))}
          </ul>
        ) : (
          <div className="loader-wrapper">
            <div className="loader">
              <div className="spinner"></div>
              <div className="loading-text">Loading Results...</div>
            </div>
            <div className="additional-text">This may take a few minutes...</div>
          </div>
        )}

      </div>
    ) : (
      <div className="loader-wrapper">
        <div className="loader">
          <div className="spinner"></div>
          <div className="loading-text">Loading Page...</div>
        </div>
      </div>
    )
  );
};

export default AdminProgressReport;
