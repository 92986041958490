import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import db from '../Firebase';

const ViewPollResults = () => {
  const { schoolURL, dayNumber, pollNumber, question, type } = useParams();
  const formattedNumber = useMemo(() => {
    return type === 'poll' ? `Poll ${pollNumber.substring(4)}` : `Question ${pollNumber.substring(8)}`;
  }, [pollNumber, type]);
  const schoolDoc = doc(db, 'School', schoolURL);
  const emailsRef = collection(schoolDoc, "Emails");
  const [pollAnswers, setPollAnswers] = useState([]);
  const [schoolPic, setSchoolPic] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [resultsAreLoaded, setResultsAreLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);

  useEffect(() => {
    const fetchSchoolDoc = async () => {
      const schoolDocSnap = await getDoc(schoolDoc);
      if (schoolDocSnap.exists()) {
        setSchoolName(schoolDocSnap.data().Name);
        setSchoolPic(schoolDocSnap.data().icon);
      } else {
        setError('Error! Try again later.');
      }
    };
    fetchSchoolDoc();
  }, []);


  const MAX_CONCURRENT_REQUESTS = 100; // Or whatever limit works for you.
  const fetchInBatches = async (emailDocs) => {
    let allPollDocs = [];
    let fetchPromises = [];

    for (const emailDoc of emailDocs.docs) {
      const dayRef = collection(emailDoc.ref, dayNumber);
      const pollRef = doc(dayRef, formattedNumber);
      fetchPromises.push(getDoc(pollRef));

      if (fetchPromises.length >= MAX_CONCURRENT_REQUESTS) {
        const batchResults = await Promise.all(fetchPromises);
        allPollDocs.push(...batchResults);
        fetchPromises = [];
      }
    }

    if (fetchPromises.length > 0) {
      const remainingResults = await Promise.all(fetchPromises);
      allPollDocs.push(...remainingResults);
    }

    return allPollDocs;
  };

  useEffect(() => {
    const fetchPollAnswersForDay = async () => {
      const emailDocs = await getDocs(emailsRef);
      const allPollDocs = await fetchInBatches(emailDocs);

      let answers = allPollDocs
        .filter(pollDoc => pollDoc.exists())
        .map(pollDoc => pollDoc.data().answer);

      setPollAnswers(answers);
      setResultsAreLoaded(true);
    };

    setResultsAreLoaded(false);
    fetchPollAnswersForDay();
  }, [schoolURL, dayNumber, formattedNumber]);

  const handleClose = (event) => {
    event.stopPropagation();
    setClickedIndex(null);
  };


  return (
    schoolPic ? (
      <div className="poll-result-container">
        <div className="poll-result-school">
          <img className="poll-result-school-pic" src={schoolPic} />
          <div className="poll-result-school-name">{schoolName}</div>
        </div>
        <h1 className="poll-result-header">Results for {formattedNumber} on {dayNumber}:</h1>
        <h3 className="poll-result-question">{question}</h3>
        {resultsAreLoaded ? (
          <ul className="poll-result-list">
            {error && <div className="error">{error}</div>}
            {pollAnswers.map((answer, index) => (
              <li
                key={index}
                className={`poll-result-item ${clickedIndex === index ? 'clicked' : ''}`}
                onClick={() => setClickedIndex(index)}
              >
                {answer} 
                {clickedIndex === index && (
                  <button className="close-button" onClick={(event) => handleClose(event)}>
                    &times;
                  </button>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <div className="loader-wrapper">
            <div className="loader">
              <div className="spinner"></div>
              <div className="loading-text">Loading Results...</div>
            </div>
            <div className="additional-text">This may take a few minutes...</div>
            {error && <div className="error">{error}</div>}
          </div>
        )}
      </div>
    ) : (
      <div className="loader-wrapper">
        <div className="loader">
          <div className="spinner"></div>
          <div className="loading-text">Loading Page...</div>
        </div>
      </div>
    )
  );
};

export default ViewPollResults;
