import React from 'react';
import Logo from "../images/logo-no-background.png";
import Collab from "../images/Collab-removebg-preview.png";

import emailjs from "emailjs-com"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'


const NavSourcewell = ({ module }) => {
    const navigate = useNavigate();
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const openNavOptions = () => {
        document.getElementById("barsBtn").style.display = "none"
        document.getElementById("navPopup").style.display = "flex"
        document.getElementById("mainLogo").style.display = "none"

    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
        document.getElementById("mainLogo").style.display = "flex"

    }

    const scrollTo = (string, num) => {
        document.getElementById("mainLogo").style.display = "flex"

        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }

    function toAbout() {
        navigate("/about")
    }
    return (
        <nav className="navbar">
            <div className="mobileNav">
                <button id='barsBtn' onClick={openNavOptions}><FontAwesomeIcon className="bars" icon={faBars} /></button>
                <div className="navPopup" id='navPopup'>
                    <div className="mobileNavOption">
                        <button onClick={closeNavOptions} className="mobileNavLink">Exit</button>
                    </div>
                    <div className={module === "home" ? `mobileNavOption selectedNavOption` : `mobileNavOption`}>
                        <Link to="/sourcewell" className="mobileNavLink">Home</Link>
                    </div>
                    <div className={module === "communication" ? `mobileNavOption selectedNavOption` : `mobileNavOption`}>
                        <Link to="/sourcewell/breakout1" className="mobileNavLink">Breakout #1</Link>
                    </div>
                    <div className={module === "human" ? `mobileNavOption selectedNavOption` : `mobileNavOption`}>
                        <Link to="/sourcewell/breakout2" className="mobileNavLink">Breakout #2</Link>
                    </div>
                    <div className={module === "miscellaneous" ? `mobileNavOption selectedNavOption` : `mobileNavOption`}>
                        <Link to="/sourcewell/breakout3" className="mobileNavLink">Breakout #3</Link>
                    </div>
                </div>
            </div>
            <div className="topNav">
                <div className={module === "home" ? `navOption selectedNavOption` : `navOption`}>
                    <Link to="/sourcewell" className="navLink">Home</Link>
                </div>
                <div className={module === "communication" ? `navOption selectedNavOption` : `navOption`}>
                    <Link to="/sourcewell/breakout1" className="navLink">Breakout #1</Link>
                </div>
                <div className={module === "human" ? `navOption selectedNavOption` : `navOption`}>
                    <Link to="/sourcewell/breakout2" className="navLink">Breakout #2</Link>
                </div>
                <div className={module === "miscellaneous" ? `navOption selectedNavOption` : `navOption`}>
                    <Link to="/sourcewell/breakout3" className="navLink">Breakout #3</Link>
                </div>
            </div>

            <div className="container nav-content">
                <div className="brand-and-toggler">
                    <Link to="/" className="navbar-brand navbar-brand-collab">
                        {/* <img src={MASSP} className='swmn' id="mainLogo" alt="site logo" /> */}
                        {/* <img src={Collab} id="mainLogo" className='collab' alt="site logo" /> */}
                        <img src={Logo} id="mainLogo" className="logo" alt="site logo" />
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default NavSourcewell;
