import React, { useLayoutEffect, useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
// Removed axios import since it's no longer needed
import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Footer from './CanjoFooter';
import Library from '../../images/NewLibrary.png';
import HumanCapitol from '../../images/HumanCapitol3.png';
import Communications from '../../images/Communications3.png';
import BusinessServices from '../../images/BusinessServices3.png';
import TeachingLearning from '../../images/TeachingLearning3.png';
import Form from '../../images/form.png';
import Guidance from '../../images/Guidance.png';
import NavCanjo from './NavCanjo';
import Guide from '../../images/Guide.png';
import Quill from '../../images/Quil.jpg'
import TeacherChecklist from '../../images/TeacherChecklist.png';
import StudentGuide from '../../images/StudentGuide.png';
import Guide2 from '../../images/Guide2.png';
import Guide3 from '../../images/Guide3.png';
import Guide1 from '../../images/Guide1.png';
import DataGuide from '../../images/DataGuide.png';
import PromptDesign from '../../images/PromptDesign.png';
import AIresistant from '../../images/AIresistant.png';
import Tool from '../../images/toolsIcon.png';
import ChatGPT from '../../images/chatGPTicon.png';
import Magic from '../../images/Magic.png';
import SchoolAI from '../../images/SchoolAI.jpg';
import Diffit from '../../images/diffit.png';
import Khan from '../../images/Khan.png';
import Gamma from '../../images/Gamma.png';
import Eduaide from '../../images/eduaide.jpg';
import Brisk from '../../images/brisk.png';
import Curipod from '../../images/Curipod.png';
import AIEDU from '../../images/AIEDU.jpg';
import Newsletter from '../../images/newsletter.png';
import Logo from '../../images/GruvyIcon.png';
import Anonymize from '../../images/AnonymizeData.png';
import TeachAIpolicy from '../../images/TeachAIpolicy.png';
import Schedule from '../../images/Schedule.jpg'
import MoASSPLogo from "../../images/MoASSP.png"; // Replace with the path to your second logo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Doc from '../../images/doc.png'
import OverWelm from '../../images/OverWelm.png'
import GoogleSheetIcon from '../../images/GoogleSheetIcon.png'
import Folder from '../../images/Folder.png'
import PurpleLogo from '../../images/WhiteIcon_While_PurpleBG.png'
import GreenLogo from '../../images/WhiteIcon_While_GreenBG.png'
import Custom from '../../images/Custom.png'
import Policy from '../../images/Policy.png'
import Practical from '../../images/Practical.png'
import DataAnalysis from '../../images/DataAnalysis.png'
import Magic1 from '../../images/Magic1.png'
import Magic2 from '../../images/Magic2.png'
const CanjoSession1 = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Add empty dependency array here


    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showPopup]);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Send the email via EmailJS
            await emailjs.send(
                'service_1g4hajp', // Your EmailJS service ID
                'template_vw2orr8', // Your EmailJS template ID
                { user_email: email }, // Data to send to the template
                'user_HAMTDSUiL200UN9RQyPHI' // Your EmailJS user/public key
            );

            setSuccessMessage(
                'Resources have been sent to your email! Please check your spam folder if you don\'t see it.'
            );
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Uh oh. Something didn\'t work. Please email william.grube@gruvyeducation.com and let me know :)');
        } finally {
            setIsSubmitting(false);
        }
    };

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }


    const prompts1 = [
        {
            id: 'role-action-context-format',
            directions: "Use the role-action-context-format prompt design framework and experiment with it in ChatGPT. Here is an example:",
            text: `As a 11th grade science teacher (role), create a rubric (action). Students are examining the biological, medical, and real-world implications of mitosis in tissue repair and regeneration. The grading criteria should assess depth of research, critical analysis, clarity of argument, use of credible sources, and adherence to scientific writing standards. (context). Present the rubric in a table (format).`.split('\n')
        }
    ];


    const prompts3 = [
        {
            id: 'improvment-plan',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in creating an improvement plan for a technology coach.",
            text: `Act as if you are an high school school principal. You are struggling with your technology coach. Not only do they have a sarcastic and offputting personality, you haven't seen any results from their work over the last year and half. Please write an improvement plan and suggest activities for this improvement plan.`.split('\n')
        },
        {
            id: 'tech-coach-improvement-plan',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in coaching a teacher on their instruction in AP Physics.",
            text: `Act as if you are a high school principal coaching a teacher on their instruction. Though you lack the content expertise of AP Physics, you recognize that the teacher struggles with alignment between what they teach and what the students learn. The teacher has a wide variety of engaging lessons and lab activities, but you are unable to see a structure that is based on the standard the teacher needs to teach and evidence-based outcomes demonstrating student proficiency of those standards. Provide me with some notes to include in the teacher's evaluations or things I can say to the teacher in our coaching conversations.`.split('\n')
        },
        {
            id: 'role-play-registrar-conversation',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in generating kindly worded suggestions for improvement.",
            text: `Please provide a gently worded, constructive suggestion for a school staff member to enhance their [specific area needing improvement, e.g., 'classroom management techniques', 'student engagement methods', 'use of educational technology']. The feedback should be supportive and aimed at fostering professional growth.`.split('\n')
        },
    ];


    const prompts4 = [
        {
            id: 'Behavior',
            directions: "Open ChatGPT using the link in the \"Links Needed for Activity\" section. Inside of ChatGPT, click on the paper clip button in the prompt bar, select \"Upload from computer,\" and choose your downloaded Excel file. Send the following prompt to ChatGPT with the attached file:",
            text: `Act as a school data analyst. Attached is a set of student feedback for a teacher. Create a detailed report of the results for the teacher. Include five compliments and three action points for improvement. Use positive language. Be clear and use simple wording.`.split('\n')
        },
    ];

    const prompts5 = [
        {
            id: 'PowerSchoolDataAnalysis',
            directions:
                'After completing the data anonymization steps, open ChatGPT and use the following prompt with your anonymized data:',
            text: `Analyze the student data in the attached Excel file. Identify significant trends over time, highlight areas of concern, and suggest actionable interventions. Use the Anonymized IDs to reference students. Provide visualizations to support your analysis.`.split(
                '\n'
            ),
        },
    ];

    const prompts2 = [
        {
            id: 'tone-1',
            directions: "Open ChatGPT using the 'Links Needed for Activity' section. Craft any kind of email; we will focus on editing its tone. To specify a tone, simply end the prompt with the desired tone type. You can use this prompt:",
            text: `Act as a school principal. Draft an email to staff about a new attendance policy starting November 1, requiring attendance checks within the first 10 minutes of each class to improve tracking. Include a meeting on October 28 at 3:30 PM in Room 204 to discuss the policy and answer questions. Be assertive and extremely clear to reduce the number of questions.`.split('\n')
        },
        {
            id: 'tone-2',
            directions: "If further tone adjustments are needed, try these popular prompts: 'Make less wordy,' 'Make more clear,' or 'What information do you need from me to make this clearer? Provide 3 questions to help clarify.' - Give it a try:",
            text: `What information do you need from me to make this clearer? Provide 3 questions to help clarify. I will answer then you will rewrite it again.`.split('\n')
        },
        {
            id: 'tone-3',
            directions: "You can customize how ChatGPT responds to every prompt automatically. Click on your profile in the top right corner, then select 'Customize ChatGPT.' In the field labeled 'How would you like ChatGPT to respond?', enter your desired tone. Here’s what I use:",
            text: `Write in the present voice. Use active sentences. Avoid adverbs when possible. Keep sentences short and simple. Use positive language. Remove redundant and unnecessary words. Keep the wording clear and straightforward.`.split('\n')
        },
    ];


    return (
        <body>
            {showScrollToTop && (
                <button
                    className="MoASSP-scroll-to-top"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    aria-label="Scroll to top"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
            <div className="holder">
                <NavCanjo module="home" />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <header className="MoASSP-header">
                        <div className="MoASSP-header-content">
                            <div className="title noMarginBottom breakout-title">AI Basics for Teachers</div>
                        </div>
                    </header>
                    <div className="course-container">
                        <div className="MoASSP-course-navbar">
                            <ScrollLink
                                activeClass="active"
                                to="william"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">🗺️🚀</span> Session Roadmap
                                </div>
                            </ScrollLink>
                            <ScrollLink
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={50}
                                className="MoASSP-course-nav-item"
                            >
                                <div>
                                    <span role="img" aria-label="Contact">📞🎤</span> Contact William
                                </div>
                            </ScrollLink>

                        </div>
                        <section id="william" className="MoASSP-course-section MoASSP-activities-section">
                            <h2 className="MoASSP-course-sub-title">🗺️🚀 Roadmap</h2>
                            <div className="MoASSP-activities-wrapper">
                                {/* Navigation bar */}
                                <div className="MoASSP-activity-nav-bar">
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="1" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">1</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="2" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">2</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="3" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">3</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="4" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">4</ScrollLink>
                                    <ScrollLink activeClass="active" spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={50} to="5" className="MoASSP-activity-nav-item MoASSP-redBackground cursorPointer">5</ScrollLink>
                                </div>
                                {/* Activity 1 */}
                                <div key="1" id="1" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 1: </span>
                                        How to Create Classroom Content with ChatGPT
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/1pQZdVMrMs7CD88sqNlNQk7mbRf133heuSGz9lakBE4Y/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Practical} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Practical Guide: Using ChatGPT for Classroom Content Creation</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the "practical guide" in the "Links Needed for Activity" section. Let's read through it and apply it.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="2" id="2" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 2: </span>
                                        How to Effectively Design ChatGPT Prompts
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/13BweFvjB4SKDpZfnZAsl0WLS4z9PJKmi/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={PromptDesign} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Prompt Framework Guide</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        {prompts1.map((prompt, index) => (
                                            <div key={prompt.id} className="MoASSP-activity-step">
                                                <p className="MoASSP-activity-direction">
                                                    <strong>{index + 1}.</strong> {prompt.directions}
                                                </p>
                                                <CopyToClipboard
                                                    textToCopy={prompt.text.join('\n')}
                                                    displayText={prompt.text}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div key="3" id="3" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 3: </span>
                                        Exploring the Gruvy Education Prompt Library
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">ChatGPT</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://gruvyeducation.com/promptlibrary" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Library} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Prompt Library</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> In the "Links Needed for Activity" section, open the "Prompt Library" to access 115+ ready-made prompts for ChatGPT. Explore these prompts for ideas on how to use ChatGPT effectively, and feel free to use them directly.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="4" id="4" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 4: </span>
                                        How to Use MagicSchool
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://app.magicschool.ai/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Magic} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">MagicSchool</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://docs.google.com/document/d/18ylptbWgxiEUkgVMOtMW5Xa0oGMD6L7jzXKsPTt0G7I/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Magic1} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Simple Guide: How to Use MagicSchool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Open the "How to Use MagicSchool" guide in the "Links Needed for Activity" section. Let's read through it and apply it.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div key="5" id="5" className="MoASSP-course-content MoASSP-activity-content">
                                    {/* Separator */}
                                    <hr className="MoASSP-activity-separator" />
                                    {/* Title */}
                                    <h3 className="MoASSP-activity-title">
                                        <span className="MoASSP-activity-number">Stop 5: </span>
                                        Explore Additional AI Tools for Educators
                                    </h3>
                                    {/* Links Needed for Activity */}
                                    <div className="MoASSP-links-section">
                                        <div className="MoASSP-links-wrapper">
                                            <h4 className="MoASSP-links-title">🔗 Links Needed for Activity:</h4>
                                            <div className="MoASSP-links-container">
                                                <a className="MoASSP-link-icon" href="https://drive.google.com/file/d/18MrSglFFq2K4L8CjiQDvoJapWnXNXvHZ/view?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Guide2} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">All Popular AI Platforms for Teachers</span>
                                                </a>
                                                <a className="MoASSP-link-icon" href="https://app.diffit.me/" target="_blank" rel="noreferrer">
                                                    <img className="MoASSP-link-icon-img" src={Diffit} alt="ChatGPT" />
                                                    <span className="MoASSP-link-label">Diffit</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Steps */}
                                    <div className="MoASSP-activity-steps">
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>1.</strong> Start by using ChatGPT and MagicSchool. Trying to use all tools at once can feel overwhelming. To explore additional popular platforms for teachers, click "All Popular AI Platforms for Teachers" in the "Links Needed for Activity" section.
                                            </p>
                                        </div>
                                        <div className="MoASSP-activity-step">
                                            <p className="MoASSP-activity-direction">
                                                <strong>2.</strong> Teachers often find "Diffit" to be especially useful. Explore it by clicking the Diffit link in the 'Links Needed for Activity' section.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default CanjoSession1;
