import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Logo from "../images/logo-no-background.png";

const TrainNav = ({ url, day, numberOfDays, type, moduleTitles }) => {
    const navigate = useNavigate();

    const openNavOptions = () => {
        document.getElementById("barsBtn").style.display = "none";
        document.getElementById("navPopup").style.display = "flex";
        document.getElementById("mainLogo").style.display = "none";
    };

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex";
        document.getElementById("navPopup").style.display = "none";
        document.getElementById("mainLogo").style.display = "flex";
    };

    const scrollTo = (string, num) => {
        document.getElementById("mainLogo").style.display = "flex";

        const element = document.getElementById(string);
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        if (num === 1) closeNavOptions();
    };

    const daysArray = Array.from({ length: numberOfDays }, (_, i) => `day${i + 1}`);

    // Helper function to trim the title
    const getTrimmedTitle = (title, index) => {
        if (!title) return `Module ${index + 1}`; // Default title if undefined
        if (title.startsWith("Using AI in ")) {
            return title.replace("Using AI in ", "");
        }
        if (title.startsWith("Using AI for ")) {
            return title.replace("Using AI for ", "");
        }
        return title;
    };

    return (
        <nav className="navbar trainNav">
            <div className="mobileNav trainMobileNav">
                <button id='barsBtn' onClick={openNavOptions}><FontAwesomeIcon className="bars" icon={faBars} /></button>
                <div className="navPopup" id='navPopup'>
                    <button className="closeBtn" onClick={closeNavOptions}>
                        <FontAwesomeIcon icon={faTimes} className="blackIcon" inverse />
                    </button>
                    <div className={day === 'home' ? "mobileNavOption selectedMobileNavOption" : "mobileNavOption"}>
                        <Link to={`/training/${url}`} className="mobileNavLink">Training Home</Link>
                    </div>
                    {daysArray.map((dayItem, index) => (
                        <div className={day === dayItem ? "mobileNavOption selectedMobileNavOption" : "mobileNavOption"} key={index}>
                            <Link to={`/training/${url}/${dayItem}`} className="mobileNavLink">
                                {type === "positionSpecific" && moduleTitles && moduleTitles.length > index
                                    ? getTrimmedTitle(moduleTitles[index], index)
                                    : dayItem.replace('day', 'Module ')}
                            </Link>
                        </div>
                    ))}
                    {type === "regular" && (
                        <>
                        <div className="mobileNavOption">
                            <Link to={`/training/AI%20Training%20for%20School%20Administrators`} className="mobileNavLink">Admin Training</Link>
                        </div>
                        <div className="mobileNavOption">
                            <Link to={`/training/AI%20Training%20for%20Special%20Education%20Educators`} className="mobileNavLink">Special Education Training</Link>
                        </div>
                        <div className="mobileNavOption">
                            <Link to={`/training/AI%20Training%20for%20Music%20Teachers`} className="mobileNavLink">Music Teacher Training</Link>
                        </div>
                        <div className="mobileNavOption">
                            <Link to={`/training/AI%20Training%20for%20PE%20and%20Health%20Teachers`} className="mobileNavLink">Physical Education Training</Link>
                        </div>
                        <div className="mobileNavOption">
                            <Link to={`/training/AI%20Training%20for%20English%20as%20a%20Second%20Language%20Teachers`} className="mobileNavLink">ESL Teacher Training</Link>
                        </div>
                        <div className="mobileNavOption">
                            <Link to={`/training/AI%20Training%20for%20School%20Counselors`} className="mobileNavLink">School Counselor Training</Link>
                        </div>
                        <div className="mobileNavOption">
                            <Link to={`/training/AI%20Training%20for%20Sport%20Coaches`} className="mobileNavLink">Sport Coach Training</Link>
                        </div>
                    </>
                    )}
                    <div className="mobileNavOption">
                        <Link to={`/training`} className="mobileNavLink">Exit Training</Link>
                    </div>
                </div>
            </div>
            <div className="topNav">
                <div className={day === 'home' ? "navOption selectedNavOption" : "navOption"}>
                    <Link to={`/training/${url}`} className="navLink">Training Home</Link>
                </div>
                {daysArray.map((dayItem, index) => (
                    <div className={day === dayItem ? "navOption selectedNavOption" : "navOption"} key={index}>
                        <Link to={`/training/${url}/${dayItem}`} className="navLink">
                            {type === "positionSpecific" ? getTrimmedTitle(moduleTitles[index], index) : dayItem.replace('day', 'Module ')}
                        </Link>
                    </div>
                ))}
                {type === "regular" && (

                    <div className="navOption dropdown">
                        <div className="dropdown">
                            <button className="dropbtn">Role-Specific Training</button>
                            <div className="dropdown-content">
                                <Link className='dropdownLink' to={`/training/AI%20Training%20for%20School%20Administrators`}>Admin Training</Link>
                                <Link className='dropdownLink' to={`/training/AI%20Training%20for%20Special%20Education%20Educators`}>Special Education Training</Link>
                                <Link className='dropdownLink' to={`/training/AI%20Training%20for%20Music%20Teachers`}>Music Teacher Training</Link>
                                <Link className='dropdownLink' to={`/training/AI%20Training%20for%20PE%20and%20Health%20Teachers`}>Physical Education Training</Link>
                                <Link className='dropdownLink' to={`/training/AI%20Training%20for%20English%20as%20a%20Second%20Language%20Teachers`}>ESL Teacher Training</Link>
                                <Link className='dropdownLink' to={`/training/AI%20Training%20for%20School%20Counselors`}>School Counselor Training</Link>
                                <Link className='dropdownLink' to={`/training/AI%20Training%20for%20Sport%20Coaches`}>Sport Coach Training</Link>
                            </div>
                        </div>
                    </div>
                )}
                {type !== "regular" && (
                    <div className="navOption">
                        <Link to={`/training`} className="navLink">Exit Training</Link>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default TrainNav;
