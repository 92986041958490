import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import Nolej from "../images/Nolej.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import Code from '../images/Code.jpg'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import NavNDCELprincipals from './NavNDCELprincipals';
import NavDPI from './NavDPI';

const DPI = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }


    const prompts1 = [
        {
            id: 'meeting1',
            directions: "To generate a summary and key contribution points from a meeting, copy and paste the prompt below into ChatGPT. Make sure to include an attachment of the transcription data.",
            text: "I have attached raw transcription data from a recent TEAMs meeting that included representatives from ND DPI and Edutech. Please summarize the attached transcription data. Also, indicate who was at the meeting and include their main contributions to the meeting.".split('\n')
        },
        {
            id: 'meetings2',
            directions: "For creating meeting minutes, copy and paste the following prompt into ChatGPT. Make sure to include an attachment of the meeting minutes template.",
            text: "Now use the following document as a template. Review the raw transcription data, the summary that was created, and create a downloadable Word document that is identical to the format found within this attached document.".split('\n')
        },
    ]

    const prompts2 = [
        {
            id: 'summ1',
            directions: "To generate a summarization of Chapter 15.1-12 of the North Dakota Centry Code located in the \"Links Needed for Activity\" section above, copy and paste the prompt below into ChatGPT. Make sure to include an attachment of the downloaded version of the code.",
            text: "The following information is the North Dakota Century Code Language on School District Annexation. Act as if you an employee at the North Dakota Department of Public Instruction asked to write a one page summary detailing how school district annexation works in North Dakota, as prescribed by NDCC. Complete the following tasks in sequential order:\n\n 1) review the century code language, highlighting key points and important detail.\n 2) Verify what you identified as the key points and important details with me by prompting me for feedback.\n 3) Write a one-page summary incorporating the feedback I provided.".split('\n')
        },
        {
            id: 'summ2',
            directions: "To generate a recursive summarization of Chapter 15.1-12 of the North Dakota Centry Code located in the \"Links Needed for Activity\" section above, copy and paste the prompt below into ChatGPT. Make sure to include an attachment of the downloaded version of the code.",
            text: "The following information is the North Dakota Century Code Language on School District Annexation. Act as if you an employee at the North Dakota Department of Public Instruction asked to write a one page summary detailing how school district annexation works in North Dakota, as prescribed by NDCC. Complete the following tasks for a recursive summary in sequential order:\n\n 1) review the century code language, highlighting key points and important detail.\n 2) Summarize the key points and important details from each individual section of the century code (new sections are identified by a century code number such as “15.1-12-05) and then\n 3) Write a one-page summary of the overall Century Code incorporating all of the individual summaries you provided.".split('\n')
        },
        {
            id: 'summ3',
            directions: "To generate a compression of Chapter 15.1-12 of the North Dakota Centry Code located in the \"Links Needed for Activity\" section above, copy and paste the prompt below into ChatGPT. Make sure to include an attachment of the downloaded version of the code.",
            text: "You are an excellent linguist and very good at compressing passages into short expressions by removing unimportant words while retaining as much information as possible. Compress the text I am going to give you and compress the text into short expressions, and such that you (ChatGPT) can reconstruct it as close as possible to the original. Unlike the usual text compression, I need you to comply with 5 conditions:\n\n First condition: You can ONLY remove unimportant words.\n Second condition: Do not reorder the original words.\n Third condition: Do not change the original words.\n Fourth condition: Do not use abbreviations or emojis.\n Fifth condition: Do not add new words or symbols.\n\n Make sure to compress the original text aggressively by removing words only. Compress the original text as short as you can, while retaining as much information as possible. Attached is the text to be compressed.".split('\n')
        },
        {
            id: 'summ4',
            directions: "To generate a chain of destiny of Chapter 15.1-12 of the North Dakota Centry Code located in the \"Links Needed for Activity\" section above, copy and paste the prompt below into ChatGPT. Make sure to include an attachment of the downloaded version of the code.",
            text: "Act as if you are an expert on the process for Annexation, Reorganization, and Dissolution as outlined in North Dakota Century Code. I am an employee who works for the North Dakota Department of Public Instruction and were recently told that you need to become the expert and answer all questions about the Annexation, Reorganization, and Dissolution process for school districts because Adam Tescher is in the Bahamas on vacation and nobody else is available. The following text provides the North Dakota Century Code on this process. Complete the following tasks in sequential order:\n\n 1) Review the entire century code to thoroughly understand what it says regarding Annexation, Reorganization, and Dissolution\n 2) Keep giving me summaries to help me understand the content directly. \n\nGo section by section and when applicable, use golf analogies to help me understand the content better since I am a golfer but know nothing of this process. Prompt me to verify that I understand each section. When finished with all sections, provide a detailed one page explanation (with no golf analogies) of this process that I can share with others and generate a FAQ sheet I can use to answer questions on this process. Use your judgement on what you believe will be the most commonly asked questions about this process by Superintendents and Board Members for the FAQ.".split('\n')
        },
    ]

    const prompts3 = [
        {
            id: 'train1',
            directions: "Copy and paste the prompt below into ChatGPT to generate a comprehensive training plan for new employees at the North Dakota Department of Public Instruction.",
            text: "Develop a training plan for new employees at the North Dakota Department of Public Instruction. The plan should include an orientation schedule, key areas of focus such as local education policies, curriculum standards, student data privacy laws, and interdepartmental collaboration. Include actionable steps for familiarization with departmental tools and software, engagement with educational communities, and ongoing professional development opportunities. The training should incorporate effective teaching strategies, assessment methods, and include benchmarks for progress evaluation within the first 90 days. Ensure the plan is adaptable for various roles, emphasizing a balance between independent learning and mentorship.".split('\n')
        },
        {
            id: 'train2',
            directions: "Then, use the prompt below in ChatGPT to develop a detailed implementation guide for the training plan you just generated.",
            text: "Based on the training plan outline provided, develop a detailed implementation guide. This should include a week-by-week schedule for the first month, specifying the topics to be covered, the format of each training session (e.g., workshop, webinar, hands-on activity), and the resources needed. Identify key personnel responsible for delivering each part of the training, and outline a feedback mechanism for new employees to share their experiences and suggestions for improvement. Additionally, propose metrics for evaluating the effectiveness of the training at the end of the first month and the first 90 days.".split('\n')
        },
        {
            id: 'train3',
            directions: "Next, enter the prompt below into ChatGPT to create an engaging presentation for a specific training session.",
            text: "Create an engaging and educational presentation intended as a key resource for the 'Understanding Local Education Policies' session in the new employee training at the North Dakota Department of Public Instruction. The presentation should introduce the foundational education policies in North Dakota, highlight their implications for teaching and administrative practices, and include real-world examples of their application in local schools. Ensure the content is accessible to individuals with varying levels of prior knowledge, and incorporate interactive elements like quizzes or discussion prompts to facilitate active learning.".split('\n')
        },
    ]

    const prompts4 = [
        {
            id: 'sched1',
            directions: "To create an efficient weekly schedule for the North Dakota Department of Public Instruction, paste the prompt below into ChatGPT. ",
            text: "Provide a detailed weekly schedule template for the North Dakota Department of Public Instruction that allocates time for administrative tasks, teacher consultations, policy review sessions, and educational program development. Include flexible slots for unforeseen meetings and ensure there's time set aside for breaks and personal time management. The schedule should accommodate typical office hours and consider the need for collaboration across different departments.".split('\n')
        },
        {
            id: 'sched2',
            directions: "Then, utilize the prompt below to request ChatGPT to refine the weekly schedule it just created for the North Dakota Department of Public Instruction.",
            text: "Review the provided weekly schedule template and suggest specific adjustments to optimize time management and productivity for the North Dakota Department of Public Instruction. Focus on balancing departmental meetings, individual work periods, and collaborative projects. Also, include strategies for incorporating regular feedback sessions and professional development opportunities within the schedule.".split('\n')
        },
    ]

    return (

        <body>
            <div className="holder">
                <NavDPI module="home" />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Empowering Work at the ND DPI Through AI</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="rupak-section" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">🤖📚</span> TeachAI</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="william" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">👨‍🏫🔧</span> Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">🗨️👥</span> Breakout Sessions</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section name='rupak-section' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🤖📚 TeachAI</h2>
                            <div className="tool-grid">
                                <a href="https://www.teachai.org/toolkit" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={TeachAI} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">AI Guidance for Schools Toolkit Website | TeachAI</h3>
                                    <p className="tool-text">AI Guidance for Schools Toolkit Website</p>
                                </a>
                            </div>
                        </section>
                        <section id='william' className="course-section course-activities-section">
                            <h2 className="course-sub-title">👨‍🏫🔧 Activites</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a> 
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                    <a
                                        href={`#3`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        3
                                    </a>
                                    <a
                                        href={`#4`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        4
                                    </a>
                                    <a
                                        href={`#5`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        5
                                    </a>
                                    <a
                                        href={`#6`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        6
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Setting up a ChatGPT Account
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/18FFY3JducMtMyuc9_m_UrR4nVVzQQv8zgbB9o96B7cM/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="Doc" />
                                                    <span className="link-label">Doc</span>
                                                </a>
                                                <a className="course-link-icon" href="https://youtu.be/v1sDxcN-sX4" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={YouTube} alt="Doc" />
                                                    <span className="link-label">Video</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow along with the document or video above to create your ChatGPT account.</p>
                                        </div>
                                    </form>
                                </div>


                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Streamlining Meeting Management with ChatGPT-4
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts1.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>

                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Summarizing Text with ChatGPT-4
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://ndlegis.gov/cencode/t15-1c12.pdf#nameddest=15p1-12-01" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Code} alt="ChatGPT" />
                                                    <span className="link-label">Code</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/1N7xiNxCEd3SZV74Wg82A4M0BqNrHJXHdJlFcOar3bjE/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="ChatGPT" />
                                                    <span className="link-label">Prompts</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts2.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>

                                <div key="4" id="4" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Generating New Employee Training Plans with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts3.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                                <div key="5" id="5" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Exploring NOLEJ AI
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://nolej.io/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Nolej} alt="ChatGPT" />
                                                    <span className="link-label">NOLEJ</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Click on the link under the "Links Needed for Activity" section to access NOLEJ AI. Once there, press on "Try It Free Today." Once you have created an account, simply click on "+ Create" in the top right corner to create a short course. (Note: You get 3 for free per registered email address.)</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="6" id="6" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Using ChatGPT to Draft a Weekly Schedule Template
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts4.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                            </div>
                            
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🗨️👥 Breakout Sessions</h2>
                            <div className="tool-grid">
                                <Link to="/nddpi/humanresources" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Human Resources</h3>
                                    <p className="tool-text">Coaching employees, Improvement Plans, Role-Play Conversations.</p>
                                </Link>
                                <Link to="/nddpi/businessservices" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Business Services</h3>
                                    <p className="tool-text">Writing RFPs, Reviewing Bids, Writting Board Memos, Drafting Contracts.</p>
                                </Link>
                                <Link to="/nddpi/teachingandlearning" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={TeachingLearning} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Teaching and Learning</h3>
                                    <p className="tool-text">Newsletters, Emails, Lesson Plans, Rubrics, Student Feedback.</p>
                                </Link>
                            </div>
                        </section>
                        <section name='NextSteps' className="course-section course-resources-section">
                            <h2 className="course-sub-title">✅ Next Steps</h2>
                            <div className="tool-grid">
                                <a href="https://youtu.be/kap60trj-K8" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Youtube" />
                                    </div>
                                    <h3 className="tool-title">Learn about Gruvy Ecucation's AI Training Opportunities for K-12 Educators</h3>
                                    <p className="tool-text">Less than 5 minute explanation video.</p>
                                </a>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Form} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Bring Gruvy Education's AI Training to Your Educators</h3>
                                    <p className="tool-text">Join the 40+ schools participating in our AI in Education Training.</p>
                                </a>
                                {/* <a href="https://usemotion.com/meet/william-grube/meeting?d=30" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Schedule a time to come see me today.</h3>
                                    <p className="tool-text">I will be set up in Hall B.</p>
                                </a> */}
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default DPI;
