import React, { useEffect, useLayoutEffect, useState } from 'react';
import Footer from './Footer';
import Logo from "../images/logo-no-background.png"
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Pencil from "../images/Pencil.png"
import Magic from "../images/magic.jpg"
import Eduaide from '../images/eduaide.jpg'
import slides from '../images/slides.png'
import classpoint from '../images/classpoint.jpg'
import copilot from '../images/copilot.jpg'
import Auto from '../images/auto.jpg'
import orig from '../images/orig.png'
import winston from '../images/winston.png'
import copy from '../images/copy.jpg'
import sap from '../images/sap.jpg'
import scale from '../images/scale.png'
import hug from '../images/hug.png'
import Nav from './Nav'
import { collection, doc, getDocs, increment, updateDoc } from 'firebase/firestore';
import db from '../Firebase';
import { ScrollLink } from 'react-scroll';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import GPTZero from "../images/GPTZero.png"
import eSpark from "../images/eSpark.jpg"


const AItools = () => {
    const [title, setTitle] = useState('All AI Tools');
    const [selectedCategory, setSelectedCategory] = useState('All AI Tools');
    const [tools, setTools] = useState([])
    const toolsRef = collection(db, "AI Tools")
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    const filteredTools = tools.filter((tool) => {
        return tool.Name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    useEffect(() => {
        const getTools = async () => {
            const data = await getDocs(toolsRef)
            setTools(data.docs.map((doc) => ({
                ...doc.data(), id: doc.id
            })))
        }
        getTools()
    }, [])
    function Dropdown() {
        const options = [
            'All AI Tools',
            'AI Text Detectors',
            'AI Resistent Assignments',
            'Automated Grading',
            'Class Projects',
            'Creating Activities',
            'Creating Rubrics',
            'Creating Syllabuses',
            'Email Efficiency',
            'Fun!',
            'General Efficency',
            'Instructional Coach',
            'Lesson Planning',
            'Letter of Recommendation',
            'Powerpoint/Slides Efficency',
            'Question Creation',
            'Sports Coach',
            'Student Reflection',
            'Student Support'
        ];

        const [isActive, setIsActive] = useState(false);

        return (
            <>
                <label htmlFor="touch">
                    <span className={`dropSpan ${isActive ? "active" : ""}`} onClick={() => setIsActive(!isActive)}>
                        {title}
                    </span>
                </label>
                <input type="checkbox" id="touch" />
                <ul className="slide">
                    {options.map((option, index) => (
                        <li key={index} className='dropLi' onClick={() => {
                            setTitle(option)
                            setSelectedCategory(option)
                        }}>
                            <div className='dropA'>{option}</div>
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const openNavOptions = () => {
        document.getElementById("barsBtn").style.display = "none"
        document.getElementById("navPopup").style.display = "flex"
    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
    }

    const scrollTo = (string, num) => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }

    const handleToolClick = (tool) => {
        // Access the Firestore database
        const toolRef = doc(db, "AI Tools", tool.id);

        // Increment the "Uses" field by 1
        updateDoc(toolRef, {
            Uses: increment(1)
        });
    };

    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AI Tools for Educators | Gruvy Education</title>
                <link   href="https://gruvyeducation.com/aitools" />
                <meta
                    name="keywords"
                    content="AI in education, Gruvy Education, AI Tools, AI for educators, Educational AI tools, AI-powered teaching, Artificial Intelligence in teaching, AI-based training"
                />
                <meta
                    name="description"
                    content="Explore Gruvy Education's suite of AI Tools designed specifically for educators. Improve your teaching strategies and classroom experience with our AI-powered tools."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="AI Tools for Educators | Gruvy Education" />
                <meta property="og:description" content="Explore Gruvy Education's suite of AI Tools designed specifically for educators. Improve your teaching strategies and classroom experience with our AI-powered tools." />
                <meta property="og:url" content="https://gruvyeducation.com/aitools" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <section className="section-one bg-ghost-white">
                    <div className="title">AI Tools for Educators</div>
                    <Dropdown />
                    <div className="search-container">
                        <input
                            type="text"
                            id="school-search"
                            className="search-input"
                            placeholder="Search by tool name..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="container section-one-content">
                        <div className="tool-grid">
                            {
                                filteredTools
                                    .filter(tool => tool.Category === selectedCategory || selectedCategory === 'All AI Tools')
                                    .sort((a, b) => a.Uses < b.Uses ? 1 : -1)
                                    .map((tool) => {
                                        const { Link, Type, Name, Description, Uses, Company } = tool;
                                        return (
                                            <a
                                                href={Link}
                                                onClick={() => handleToolClick(tool)}
                                                target='_blank'
                                                className={`tool-card ${Type}`}
                                            >
                                                <div className="tool-icon">
                                                    {tool.Type === 'magic' && <img src={Magic} />}
                                                    {tool.Type === 'eduaide' && <img src={Eduaide} />}
                                                    {tool.Type === 'slidesai' && <img src={slides} />}
                                                    {tool.Type === 'classpoint' && <img src={classpoint} />}
                                                    {tool.Type === 'copilot' && <img src={copilot} />}
                                                    {tool.Type === 'auto' && <img src={Auto} />}
                                                    {tool.Type === 'zero' && <img src={GPTZero} />}
                                                    {tool.Type === 'orig' && <img src={orig} />}
                                                    {tool.Type === 'winston' && <img src={winston} />}
                                                    {tool.Type === 'copy' && <img src={copy} />}
                                                    {tool.Type === 'sap' && <img src={sap} />}
                                                    {tool.Type === 'scale' && <img src={scale} />}
                                                    {tool.Type === 'hug' && <img src={hug} />}
                                                    {tool.Type === 'gruvy' && <img src={Pencil} />}
                                                    {tool.Type === 'eSpark' && <img src={eSpark} />}
                                                </div>
                                                <h3 className="tool-title">{Name}</h3>
                                                <p className="tool-text">{Description}</p>
                                                <p className="tool-usage">Uses: {Uses}</p>
                                                {Type !== 'gruvy' && <p className="tool-disclaimer">*Created by {Company}, all credit to {Company}.</p>}
                                            </a>
                                        );
                                    })
                            }

                        </div>
                    </div>
                </section>
                <section className="section-two" >
                    <h2 className='pollsTitle' id='about'>Learn more about Gruvy Education</h2>
                    <div className="container section-two-content">
                        <div className="accordion">
                            <Link to="/testimonials" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Testimonials</span>
                                </div>
                            </Link>
                            <Link to="/about" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">About Us</span>
                                </div>
                            </Link>
                            <a target="_blank" href="https://usemotion.com/meet/william-grube/meeting?d=30" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Schedule a Virtual Meeting</span>
                                </div>
                            </a>
                            <ScrollLink to="contact" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Send us a message</span>
                                </div>
                            </ScrollLink>
                        </div>
                        <div className="info text-center">
                            <div className="section-title">
                                <h2>Join our Facebook Group</h2>
                            </div>
                            <p className="text">Join our community of educators staying ahead of the curve with AI.</p>
                            <a target='_blank' href="https://www.facebook.com/groups/630420622459086/" className="btn">Join</a>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </body>
    );
}

export default AItools;
