import React from 'react';
import Logo from "../../images/logo-no-background.png";
import SecondLogo from "../../images/MoASSP.png"; // Path to your second logo
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link as ScrollLink } from 'react-scroll';

const NavMoASSP = ({ module }) => {
  const navigate = useNavigate();

  const openNavOptions = () => {
    document.getElementById("MoASSP-bars-btn").style.display = "none";
    document.getElementById("MoASSP-nav-popup").style.display = "flex";
    document.getElementById("MoASSP-main-logo").style.display = "none";
    document.getElementById("MoASSP-second-logo").style.display = "none";
    document.getElementById("MoASSP-apply-button").style.display = "block"; // Show the apply button on mobile menu open

    // Disable scrolling
    document.body.classList.add("no-scroll");
  };

  const closeNavOptions = () => {
    document.getElementById("MoASSP-bars-btn").style.display = "flex";
    document.getElementById("MoASSP-nav-popup").style.display = "none";
    document.getElementById("MoASSP-main-logo").style.display = "flex";
    document.getElementById("MoASSP-second-logo").style.display = "flex";
    document.getElementById("MoASSP-apply-button").style.display = "block"; // Keep the apply button visible when mobile menu is closed

    // Enable scrolling
    document.body.classList.remove("no-scroll");
  };

  return (
    <nav className="MoASSP-navbar">
      <div className="MoASSP-container">
        {/* Left Logo */}
        <div className="MoASSP-brand">
          <Link to="/" target='_blank' className="MoASSP-navbar-brand">
            <img src={Logo} id="MoASSP-main-logo" className="MoASSP-logo" alt="Gruvy Education Logo" />
          </Link>
        </div>

        {/* Navigation Links */}
        <div className="MoASSP-nav-center">
          <div className={module === "home" ? "MoASSP-nav-item MoASSP-active-nav-item" : "MoASSP-nav-item"}>
            <Link to="/moasspbreakout" className="MoASSP-nav-link">Breakout Resources</Link>
          </div>
          <div className={module === "about" ? "MoASSP-nav-item MoASSP-active-nav-item" : "MoASSP-nav-item"}>
            <Link to="/AboutGruvyEducationBreakout" className="MoASSP-nav-link">About Gruvy Education</Link>
          </div>
        </div>

        {/* Right Side - Second Logo or Apply Button */}
        <div className="MoASSP-brand MoASSP-brand-right">
          {module === "about" ? (
            // Show Apply Button on About Page
            <ScrollLink
              activeClass="active"
              to="apply"
              spy={true}
              smooth={true}
              offset={-70}
              duration={50}
              id="MoASSP-apply-button"
              className="MoASSP-apply-button-nav cursorPointer"
            >
              Apply for Training
            </ScrollLink>
          ) : (
            // Show Second Logo on other pages, but hide it on mobile
            <a href="https://www.moassp.com/" target='_blank' className="MoASSP-navbar-brand">
              <img src={SecondLogo} id="MoASSP-second-logo" className="MoASSP-logo" alt="Second Logo" />
            </a>
          )}
        </div>

        {/* Mobile Menu Icon */}
        <button id="MoASSP-bars-btn" className="MoASSP-bars-btn" onClick={openNavOptions}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      {/* Mobile Navigation Popup */}
      <div className="MoASSP-nav-popup" id="MoASSP-nav-popup">
        <div className="MoASSP-mobile-nav-header">
          <button onClick={closeNavOptions} className="MoASSP-close-btn">&times;</button>
        </div>
        <div className="MoASSP-mobile-nav-links">
          <Link to="/moasspbreakout" className="MoASSP-mobile-nav-link" onClick={closeNavOptions}>
            Keynote Resources
          </Link>
          <Link to="/AboutGruvyEducationBreakout" className="MoASSP-mobile-nav-link" onClick={closeNavOptions}>
            About Gruvy Education
          </Link>
          {/* Apply Button in Mobile Menu */}
          <ScrollLink
            activeClass="active"
            to="apply"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}  // Adjust duration for a smoother experience
            id="MoASSP-apply-button"
            className="MoASSP-mobile-nav-link"
            onClick={() => {
              setTimeout(() => closeNavOptions(), 100); // Delay of 100ms to allow scrolling
            }}
          >
            Apply for Training
          </ScrollLink>
        </div>
      </div>
    </nav>
  );
};

export default NavMoASSP;
