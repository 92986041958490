import { useState, useEffect } from 'react'
import React, { useLayoutEffect } from 'react';
import Footer from './Footer';

import emailjs from "emailjs-com"
import { Helmet } from 'react-helmet';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import Logo from "../images/logo-no-background.png" 

import Nav from './Nav'
import db from '../Firebase'
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom';
import { ScrollLink } from 'react-scroll';

const fetch = require('node-fetch');
const LessonPlanGenerator = () => {
    const [userName, setUserName] = useState('')
    const [users, setUsers] = useState([])
    const [userEmail, setUserEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [grade, setGrade] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [warmUp, setWarmUp] = useState(false);
    const [directInstruction, setDirectInstruction] = useState(false);
    const [guidedPractice, setGuidedPractice] = useState(false);
    const [independentPractice, setIndependentPractice] = useState(false);
    const [inClassAssignment, setInclassAssignment] = useState(false);
    const [formativeAssessment, setFormativeAssessment] = useState(false);
    const [selfAssessment, setSelfAssessment] = useState(false);
    const [differentiation, setDifferentiation] = useState(false);
    const [closure, setClosure] = useState(false);
    const [homework, setHomework] = useState(false);
    const [realWorld, setRealWorld] = useState(false);
    const [crossCurricular, setCrossCurricular] = useState(false);
    const [management, setManagement] = useState(false);
    const [socratic, setSocratic] = useState(false);
    const [metacognitive, setMetacognitive] = useState(false);
    const [ethicalDilemma, setEthicalDilemma] = useState(false);
    const [topic, setTopic] = useState('')
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [countDown, setCountDown] = useState(120);
    const [templateResponseArray, setTemplateResponseArray] = useState(null)
    const [templateResponse, setTemplateResponse] = useState(null)
    const [dots, setDots] = useState('')
    const [docDetails, setDocDetails] = useState(null)
    const [step, setStep] = useState('step1')
    const [errorOccured, setErrorOccured] = useState(false)
    const [isCourse, setIsCourse] = useState(false)
    const usersRef = collection(db, "AI Tools Users")
    const errorRef = collection(db, "AI Tool Error")
    const toolDoc = doc(db, 'AI Tools', 'J3cRmqS3Wtxv0jZgeGbH')

    const onStarClick = async (newRating) => {
        const ratingsRef = collection(db, 'Ratings');
        await addDoc(ratingsRef, {
            Rating: newRating,
            Name: userName,
            Email: userEmail,
            AIToolName: docDetails.Name, // replace with your tool's name
            Response: templateResponseArray,
        });
    }

    useEffect(() => {
        const fetchToolData = async () => {
            const toolDocSnapshot = await getDoc(toolDoc);
            if (toolDocSnapshot.exists()) {
                // the document exists
                const toolData = toolDocSnapshot.data()
                setDocDetails(toolData)
            } else {
                // the document does not exist
                console.log("No such document!");
            }
        }
        fetchToolData()
    }, [])



    useEffect(() => {
        const getusers = async () => {
            const data = await getDocs(usersRef)
            setUsers(data.docs.map((doc) => ({
                ...doc.data(), id: doc.id
            })))
        }
        getusers()
    }, [])

    const resetForm = () => {
        // Reset each field state here
        setUserName("");
        setUserEmail("");
        setSubject("");
        setGrade("");
        setProjectDescription('')
        setProjectName('')
        setCountDown(120)
        // Also reset the form step
        setStep("step1");
        window.location.reload();
    };


    async function downloadAsWord(e) {
        e.preventDefault();
        let htmlContent = templateResponseArray.map((para) => {
            return `<p className="activityTxt white">${para}<br /></p>`;
        }).join('');

        // Create a Blob object from HTML content
        const converted = htmlDocx.asBlob(`<html><body>${htmlContent}</body></html>`);

        // Trigger download
        saveAs(converted, 'document.docx');
    };

    const prompt1 =
        `Create a comprehensive and adaptable lesson plan template that guides teachers in designing engaging and effective lessons for students in ` + subject + ` at the ` + grade + ` level, focusing on a specific topic, ` + topic + `. This template should be flexible, allowing for customization to suit various learning objectives and classroom settings. Incorporate the following components:

    Lesson Information: Essential details such as the lesson title, specific topic, date, duration, class, and relevant unit.

    Preparation Tasks: Tasks to be completed before the lesson, such as preparing materials, setting up technology, or pre-assessing student knowledge.

    Learning Objectives: Specific outcomes that students should achieve by the end of the lesson.

    Materials and Resources: Any required materials, resources, or technology needed for the lesson.

    ` + (warmUp == false && directInstruction == false && guidedPractice == false && independentPractice == false ? '' : 'Learning Activities:')
        + (warmUp ? 'Warm-Up Activity: A brief introductory activity or discussion to capture students\' attention and prepare them for the lesson\'s content.' : '') +
        (directInstruction ? 'Direct Instruction: Primary instructional strategies or methods for teaching the lesson content.' : '') +
        (guidedPractice ? 'Guided Practice: Structured activities that allow students to practice and apply new skills or concepts under the teacher\'s guidance.' : '') +
        (independentPractice ? 'Independent Practice: Activities or assignments that encourage students to apply and extend their learning independently.' : '') + `

        ` + (inClassAssignment == false && formativeAssessment == false && selfAssessment == false && differentiation == false && closure == false && homework == false ? '' : 'Assessment Strategies:')
        + (inClassAssignment ? 'In-class Assessment and Feedback: Methods for assessing students\' understanding and progress during the lesson. Opportunities for providing feedback and addressing misconceptions.' : '') +
        (formativeAssessment ? 'Formative and Summative Assessment: Longer-term assessments that measure student learning over time.' : '') +
        (selfAssessment ? 'Student Self-assessment and Peer Feedback: Opportunities where students can evaluate their own work or provide feedback to each other.' : '') +
        (differentiation ? 'Differentiation: Strategies or accommodations for meeting the diverse needs of learners.' : '') +
        (closure ? 'Closure: A brief closing activity or discussion that summarizes the key points of the lesson.' : '') +
        (homework ? 'Homework/Extended Learning: Any homework or out-of-class assignments to reinforce the lesson\'s content or extend students\' learning.' : '') + `

        ` + (realWorld == false && crossCurricular == false && management == false && socratic == false && metacognitive == false && ethicalDilemma == false ? '' : 'Lesson Connections:')
        + (realWorld ? 'Real-world Connections: Activities or discussions that connect the lesson\'s content to real-world contexts, current events, or students\' experiences.' : '') +
        (crossCurricular ? 'Cross-curricular Connections: Opportunities to integrate content from other subject areas or to develop cross-curricular skills.' : '') +
        (management ? 'Classroom Management Strategies: Specific strategies or routines that maintain a positive and productive learning environment.' : '') +
        (socratic ? 'Socratic Connections: Incorporate Socratic questioning techniques to encourage critical thinking and in-depth understanding.' : '') +
        (metacognitive ? 'Metacognitive Connections: Strategies to help students think about their own learning processes and develop self-regulation skills.' : '') +
        (ethicalDilemma ? 'Incorporate scenarios that present ethical dilemmas related to the lesson\'s content to facilitate deeper understanding and to help students develop ethical decision-making skills.' : '') + `

    Reflection and Notes: Space for the teacher to reflect on the lesson's effectiveness, make notes on any adjustments needed for future lessons, and record observations about student engagement or progress.
    
    Remember to maintain a clear and concise structure, use easily understood language, and provide ample guidance to help teachers effectively design and implement their lesson plans focused on the specific topic, ` + topic + `.`;



    useEffect(() => {
        let intervalId;
        // Start the countdown when isLoading is true
        if (isLoading && countDown > 0) {
            intervalId = setInterval(() => {
                setCountDown(countDown => countDown - 1);
            }, 1000);
            dots == '' ? setDots('.') : void (0)
            dots == '.' ? setDots('..') : void (0)
            dots == '..' ? setDots('...') : void (0)
            dots == '...' ? setDots('....') : void (0)
            dots == '....' ? setDots('') : void (0)
        } else if (!isLoading && countDown !== 120 && step == 'step1') {
            // Reset the countdown when isLoading is false
            setCountDown(120);
        } else if (isLoading && countDown === 0) {
            // If isLoading is true and countDown reaches 0, stop the loading
            setIsLoading(false);
        }

        // Clean up function
        return () => clearInterval(intervalId);
    }, [isLoading, countDown]);

    const handleFormSubmit = async e => {
        e.preventDefault();
        const userEmailWithId = users.map(user => ({ id: user.id, email: user.Email })); // Create an array of objects with id and email
        const foundUser = userEmailWithId.find(user => user.email === userEmail);
        const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const year = now.getFullYear();
        const dateLastUsed = `${month}/${day}/${year}`; // Find the user object with the matching email
        window.scrollTo(0, 0)
        setIsLoading(true);
        const toolDocSnapshot = await getDoc(toolDoc);
        if (toolDocSnapshot.exists()) {
            const toolData = toolDocSnapshot.data();
            const currentUses = toolData.Uses;
            const updatedUses = currentUses + 1;
            await updateDoc(toolDoc, { Uses: updatedUses });
        }

        if (foundUser) {
            // If the user was found, update the 'TotalUses' field
            const userDocRef = doc(db, 'AI Tools Users', foundUser.id);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.data();
            const currentTotalUses = userData.LessonPlanGeneratorUses || 0;
            const updatedTotalUses = currentTotalUses + 1;
            await updateDoc(userDocRef, { LessonPlanGeneratorUses: updatedTotalUses });
        } else {
            // If the user was not found, add a new document
            await addDoc(usersRef, {
                Name: userName,
                Email: userEmail,
                LessonPlanGeneratorUses: 1
            });
        }

        await getMessages1(); // remove this and replace with your actual request
        setStep('step3');
        setCountDown(120);
    };

    useEffect(() => {
        if (templateResponse) {
            setTemplateResponseArray(templateResponse.content.toString().split(/\n/))
            setIsLoading(false);
        }
    }, [templateResponse])

    const getMessages1 = async () => {
        // console.log(prompt1)
        const prompt = prompt1
        const options = {
            method: "POST",
            body: JSON.stringify({
                message: prompt
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }
        try {
            const response = await fetch("https://us-central1-teachingduringtheriseofai.cloudfunctions.net/app/completion", options)
            // const response = await fetch("http://localhost:3000/completion", options)
            const data = await response.json()
            setTemplateResponse(data.choices[0].message)
        } catch (error) {
            console.error(error)
            setErrorOccured(true)
            const toolDocSnapshot = await getDoc(toolDoc);
            if (toolDocSnapshot.exists()) {
                const currentErrors = docDetails.Errors
                const updatedErrors = currentErrors + 1
                await updateDoc(toolDoc, { Errors: updatedErrors })
            }
            await addDoc(errorRef, {
                Name: userName,
                Email: userEmail,
                Error: error,
                timeStamp: Timestamp.fromDate(new Date()),
            })
        }
    }


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
    }

    const scrollTo = (string, num) => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }

    const step1buttonClick = (bool) => {
        setIsCourse(bool)
        setStep('step2')
    }

    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AI Tool: AI Lesson Plan Generator for Educators | Gruvy Education</title>
                <link   href="https://gruvyeducation.com/aitools/lessonplangenerator" />
                <meta
                    name="keywords"
                    content="Gruvy Education, AI in Education, AI Tools, Lesson Plan Generator, AI Lesson Plan, AI for Educators, AI Teaching Resources"
                />
                <meta
                    name="description"
                    content="Discover how Gruvy Education's AI Lesson Plan Generator can help streamline your lesson planning and foster a dynamic, AI-driven learning environment."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="AI Tool: AI Lesson Plan Generator for Educators | Gruvy Education" />
                <meta property="og:description" content="Discover how Gruvy Education's AI Lesson Plan Generator can help streamline your lesson planning and foster a dynamic, AI-driven learning environment." />
                <meta property="og:url" content="https://gruvyeducation.com/aitools/lessonplangenerator" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <section className="section-one bg-ghost-white">
                    <div className="title">Lesson Plan Generator</div>
                    <div className="container section-one-content">
                        <div className="aiToolsFormContainer container">
                            <div className="buttonContainer backButtonContainer">
                                <Link to='/aitools' className="backBtn">⬅ Back to AI Tools</Link>
                            </div>
                            <div className="buttonContainer">
                                <input className="resetButton" type="button" onClick={resetForm} value="Reset" />
                            </div>
                            <div className="margin"></div>
                            <form className="aiToolsForm" onSubmit={handleFormSubmit} id='aiToolsForm'>
                                {!errorOccured && !isLoading && step == 'step1' &&
                                    <div className='aiToolsFormContent'>
                                        <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>Complete the form to develop a lesson plan.</h2>
                                        <label className="aiToolsFormLabel" htmlFor="name">Name:</label><br />
                                        <input className="aiToolsFormInput" placeholder='First Last' required type="text" id="name" name="name" value={userName} onChange={e => setUserName(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="email">Email:</label><br />
                                        <input className="aiToolsFormInput" placeholder='Enter Email' required type="text" id="email" name="email" value={userEmail} onChange={e => setUserEmail(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="subject">Subject:</label><br />
                                        <input className="aiToolsFormInput" placeholder='e.g. Math' required type="text" id="subject" name="subject" value={subject} onChange={e => setSubject(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="topic">Topic:</label><br />
                                        <input className="aiToolsFormInput" placeholder='e.g. Algebra' required type="text" id="topic" name="topic" value={topic} onChange={e => setTopic(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="grade">Grade Level:</label><br />
                                        <select required className="aiToolsFormSelect" id="grade" name="grade" value={grade} onChange={e => setGrade(e.target.value)}>
                                            <option value="Pre-School">Pre-School</option>
                                            <option value="Kindergarden">Kindergarden</option>
                                            <option value="1st-Grade">1st-Grade</option>
                                            <option value="2nd-Grade">2nd-Grade</option>
                                            <option value="3rd-Grade">3rd-Grade</option>
                                            <option value="4th-Grade">4th-Grade</option>
                                            <option value="5th-Grade">5th-Grade</option>
                                            <option value="6th-Grade">6th-Grade</option>
                                            <option value="7th-Grade">7th-Grade</option>
                                            <option value="8th-Grade">8th-Grade</option>
                                            <option value="9th-Grade">9th-Grade</option>
                                            <option value="10th-Grade">10th-Grade</option>
                                            <option value="11th-Grade">11th-Grade</option>
                                            <option value="12th-Grade">12th-Grade</option>
                                            <option value="College Freshman">College Freshman</option>
                                            <option value="College Sophmore">College Sophmore</option>
                                            <option value="College Junior">College Junior</option>
                                            <option value="College Senior">College Senior</option>
                                            <option value="College Masters Course">Masters Course</option>
                                            <option value="College Doctorate Course">Doctorate Course</option>
                                        </select>
                                        <br />
                                        <br />
                                        <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>Please choose the components you'd like to include in your customized lesson plan by clicking on the corresponding buttons.</h2>
                                        <label className="aiToolsFormLabel">Learning Activities:</label><br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="warmUp" name="warmUp" onChange={e => setWarmUp(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="warmUp">Include Warm-Up Activity: A brief introductory activity or discussion to capture students' attention and prepare them for the lesson's content.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="directInstruction" name="directInstruction" onChange={e => setDirectInstruction(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="directInstruction">Include Direct Instruction: Primary instructional strategies or methods for teaching the lesson content.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="guidedPractice" name="guidedPractice" onChange={e => setGuidedPractice(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="guidedPractice">Include Guided Practice: Structured activities that allow students to practice and apply new skills or concepts under the teacher's guidance.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="independentPractice" name="independentPractice" onChange={e => setIndependentPractice(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="independentPractice">Include Independent Practice: Activities or assignments that encourage students to apply and extend their learning independently.</label><br />
                                        </div>
                                        <br />
                                        <br />

                                        <label className="aiToolsFormLabel">Assessment Strategies:</label><br />

                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="inClassAssignment" name="inClassAssignment" onChange={e => setInclassAssignment(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="inClassAssignment">Include In-class Assessment and Feedback: Methods for assessing students' understanding and progress during the lesson. Opportunities for providing feedback and addressing misconceptions.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="formativeAssessment" name="formativeAssessment" onChange={e => setFormativeAssessment(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="formativeAssessment">Include Formative and Summative Assessment: Longer-term assessments that measure student learning over time.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="selfAssessment" name="selfAssessment" onChange={e => setSelfAssessment(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="selfAssessment">Include Student Self-assessment and Peer Feedback: Opportunities where students can evaluate their own work or provide feedback to each other.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="differentiation" name="differentiation" onChange={e => setDifferentiation(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="differentiation">Include Differentiation Strategies: Strategies or accommodations for meeting the diverse needs of learners.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="closure" name="closure" onChange={e => setClosure(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="closure">Include Closure: A brief closing activity or discussion that summarizes the key points of the lesson.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="homework" name="homework" onChange={e => setHomework(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="homework">Include Homework/Extended Learning: Any homework or out-of-class assignments to reinforce the lesson's content or extend students' learning.</label><br />
                                        </div>
                                        <br />
                                        <br />
                                        <label className="aiToolsFormLabel">Lesson Connections:</label><br />

                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="realWorld" name="realWorld" onChange={e => setRealWorld(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="realWorld">Include Real-world Connections: Activities or discussions that connect the lesson's content to real-world contexts, current events, or students' experiences.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="crossCurricular" name="crossCurricular" onChange={e => setCrossCurricular(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="crossCurricular">Include Cross-curricular Connections: Opportunities to integrate content from other subject areas or to develop cross-curricular skills.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="management" name="management" onChange={e => setManagement(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="management">Include Classroom Management Strategies: Specific strategies or routines that maintain a positive and productive learning environment.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="socratic" name="socratic" onChange={e => setSocratic(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="socratic">Include Socratic Connections: Incorporate Socratic questioning techniques to encourage critical thinking and in-depth understanding.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="metacognitive" name="metacognitive" onChange={e => setMetacognitive(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="metacognitive">Include Metacognitive Connections: Strategies to help students think about their own learning processes and develop self-regulation skills.</label><br />
                                        </div>
                                        <br />
                                        <div className="checkBoxContainer">
                                            <input type="checkbox" className='checkboxInput' id="ethicalDilemma" name="ethicalDilemma" onChange={e => setEthicalDilemma(e.target.checked)} />
                                            <label className='checkboxLabel' htmlFor="ethicalDilemma">Incorporate Ethical Dilemmas: Scenarios that present ethical dilemmas related to the lesson's content to facilitate deeper understanding and to help students develop ethical decision-making skills.</label><br />

                                            <br />                                        </div>

                                        <br />

                                        <input className="aiToolsFormButton" type="submit" value="Generate Lesson Plan" />

                                    </div>}
                                {
                                    !errorOccured && !isLoading && step == 'step3' &&
                                    <div className='aiToolsFormContent'>
                                        <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>{grade} lesson Plan for '{topic}':</h2>

                                        {templateResponseArray.map((para, index) => {
                                            return <p key={index} className="activityTxt white">{para}<br /></p>
                                        })}
                                        <div className="rateContainer">
                                            <label className='rateLabel'>Rate:</label>
                                            <ReactStars
                                                count={5}
                                                onChange={onStarClick}
                                                size={24}
                                                activeColor="#ffd700"
                                                style={{ marginTop: '8px' }}
                                            />
                                        </div>

                                        <button className="aiToolsFormButton" onClick={downloadAsWord}>Download as Word Document</button>
                                        {/* <button className="aiToolsFormButton" onClick={downloadAsPdf}>Download as PDF</button> */}
                                    </div>

                                }
                                {isLoading && <div className='loadingTxt step2txt'>Creating a lesson plan for your {grade.toLowerCase()} {subject.toLowerCase()} classroom{dots} <br /><br /> {countDown} Seconds Left</div>}
                                {errorOccured && <div className='loadingTxt'>Oops! We're experiencing some difficulty :( <br /><br /> We're sorry for the inconvenience and we're looking into it. Please try refreshing the page or come back a little later. Rest assured, our team is actively investigating this issue. If the problem continues, don't hesitate to reach out for assistance.</div>}
                            </form>
                        </div>
                    </div>
                </section>
                <section className="section-two" >
                    <h2 className='pollsTitle' id='about'>Learn more about Gruvy Education</h2>
                    <div className="container section-two-content">
                        <div className="accordion">
                            <Link to="/testimonials" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Testimonials</span>
                                </div>
                            </Link>
                            <Link to="/about" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">About Us</span>
                                </div>
                            </Link>
                            <a target="_blank" href="https://usemotion.com/meet/william-grube/meeting?d=30" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Schedule a Virtual Meeting</span>
                                </div>
                            </a>
                            <ScrollLink to="contact" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Send us a message</span>
                                </div>
                            </ScrollLink>
                        </div>
                        <div className="info text-center">
                            <div className="section-title">
                                <h2>Join our Facebook Group</h2>
                            </div>
                            <p className="text">Join our community of educators staying ahead of the curve with AI.</p>
                            <a target='_blank' href="https://www.facebook.com/groups/630420622459086/" className="btn">Join</a>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </body>
    );
}

export default LessonPlanGenerator;
