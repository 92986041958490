import React, { useEffect, useLayoutEffect, useState } from 'react';
import Footer from './Footer';

import onehundred from "../images/100percent-circle.png";
import emailjs from "emailjs-com"
import PowerPointIcon from "../images/powerPointIcon.png"
import pollIcon from "../images/thoughtIcon.jpg"
import PresentaionProposal from '../images/Teaching During The Rise Of AI Presentation Proposal.pdf'
import { Helmet } from 'react-helmet';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import db from '../Firebase';
import { Link, useNavigate } from 'react-router-dom';
import Nav from './Nav'
import ScrollToTop from './ScrollToTop';
import GetStarted from './GetStarted';

const Testimonials = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const [schools, setSchools] = useState([])
    const [colleges, setColleges] = useState([])
    const schoolsRef = collection(db, "Pic")
    const collegesRef = collection(db, "College Pic")
    const [testimonials, setTestimonials] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchTestimonials = async () => {
            const docRef = doc(db, "Testimonials", "AllTestimonials");
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setTestimonials(docSnap.data().testimonials);
                } else {
                    console.log("No testimonials found!");
                }
            } catch (error) {
                console.error("Error fetching testimonials:", error);
            }
            setIsLoading(false);
        };

        fetchTestimonials();
    }, []);

    const handleClick = () => {
        const element = document.getElementById('getStarted');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const getSchools = async () => {
            const data = await getDocs(schoolsRef)
            setSchools(data.docs.map((doc) => ({
                ...doc.data(), id: doc.id
            })))
        }
        getSchools()
    }, [])

    useEffect(() => {
        const getColleges = async () => {
            const data = await getDocs(collegesRef)
            setColleges(data.docs.map((doc) => ({
                ...doc.data(), id: doc.id
            })))
        }
        getColleges()
    }, [])
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }


    const openNavOptions = () => {
        document.getElementById("barsBtn").style.display = "none"
        document.getElementById("navPopup").style.display = "flex"
    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
    }

    const scrollTo = (string, num) => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }

    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Testimonials for AI in Education Training | Gruvy Education</title>
                <link href="https://gruvyeducation.com/testimonials" />
                <meta name="keywords" content="AI in Education Training, Gruvy Education Reviews, AI Training Testimonials, Education Technology Testimonials, EdTech AI Testimonials, AI Education Courses Review" />
                <meta name="description" content="Discover how Gruvy Education's AI in Education Training has empowered educators worldwide. Read testimonials and join our community of innovative educators today!" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Testimonials for AI in Education Training | Gruvy Education" />
                <meta property="og:description" content="Read testimonials from educators who have benefited from Gruvy Education's AI in Education Training. Join the ranks of satisfied clients today." />
                <meta property="og:url" content="https://gruvyeducation.com/testimonials" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <GetStarted />
                <section className="section-one bg-ghost-white">
                    <div className="title">School Wide AI Training Testimonials</div>
                        {isLoading ? (
                            <div className="testimonial-loader-container">
                                <div className="testimonial-loader"></div>
                                <p>Loading...</p>
                            </div>
                        ) : (
                            <div id="content">

                            {
                                testimonials.map((testimonial, index) => (
                                    <div key={index} className="testimonial">
                                        <blockquote>{testimonial}</blockquote>
                                        <div></div>
                                        <p>
                                            Participant in the AI in Education Training
                                        </p>
                                    </div>
                                ))
                            }
                            </div>
                        )}
                        {/* {testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial">
                                <blockquote>{testimonial}</blockquote>
                                <div></div>
                                <p>
                                    Participant in the AI in Education Training
                                </p>
                            </div>
                        ))} */}
                </section>
                {/* <section className="section-one bg-ghost-white">
                    <div className="title">School Wide AI Training Testimonials</div>
                    <div id="content">
                        <div className="testimonial">
                            <blockquote>
                                ALL teachers should take this class! ABSOLUTELY one of the most valuable courses I have taken as a 30 year teacher.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                Every school district in America should have this training.  Our kids would be serviced much better as a result and teachers' work load would be greatly reduced.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                Having access to the Gruvy Education portal is PRICELESS!
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                I've been an educator for over 30 years.  This is a top 5 all-time in-service.  No fluff, real examples, a chance to experiment with the technology, and a lot to think about.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                This seriously has been so cool. Very much worth the time! I hope all teachers can go through this training with you!
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                My thoughts about Chat GPT coming into this training were that it was an insidious cheating tool. I learned that AI is around to stay, and we should not just pretend that it doesn't exist. In fact, if we incorporate it into education correctly, we can teach students that it is just a tool that can be helpful in their lives if used appropriately. William did a great job of presenting AI as a creative, useful tool that even teachers can use in ways that do not constitute "cheating." William presented the material clearly, concisely, and professionally.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                You have done an amazing job training us in regards to utilizing AI in our current job and sharing information and prompts to help further our understanding of AI's capabilities. You also do an awesome job answering questions on the spot and guiding us in our thinking. Overall, you have equipped us with knowledge to help us in the present and the future which enables us to do better for our students.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                This PD day was unlike any other in terms of engagement. William's presentation style was captivating, exuding confidence in his subject expertise. The content was highly informative, presented in a manner that made it effortless to comprehend and stay engaged with his teachings.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                Your understanding is very impressive. I believe that the greatest hope education has to "compete" with the tech of the future is to pay appropriately for young, talented people like yourself to continue teaching and supporting staff to learn in this new environment.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                Staff appreciated hearing from a younger, relatable person that is experiencing AI firsthand.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                Very eye-opening. I like how you were straightforward about how this technology is here to stay, and the job of an educator is to adapt to the technology. We are not to fear it, but to use it to our advantage. It is not necessarily only a student tool, but can be used by educators as well.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                You were extemely helpful in how to use ChatGPT positively. You also did an excellent job reassuring me that I shouldn't panic about this technology.
                            </blockquote>
                            <div></div>
                        </div>
                        <div className="testimonial">
                            <blockquote>
                                You were unbelievably poised and eloquent. You were knowledgeable on the subject and provided realistic ways for educators to work with this technology instead of against it. I heard from many teachers that you were one of the most engaging professional development facilitators we've ever had.
                            </blockquote>
                            <div></div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="section-one bg-ghost-white">
                    <div className="title">Trusted By College Institutions: Our College Collaborations</div>
                    <div className="schoolLogos">
                        {colleges.map((college) => {
                            return <div className="schoolLogo">
                                <img src={`${college.URL}`} />
                                <h3>{college.Name}</h3>
                            </div>
                        })}
                    </div>
                    <div className="title">Proudly Trusted By and Collaborated With:</div>
                    <div className="schoolLogos">
                        {schools.map((school) => {
                            return <div className="schoolLogo">
                                <img src={`${school.URL}`} />
                                <h3>{school.Name}</h3>
                            </div>
                        })}
                    </div>
                </section> */}
                <section className="section-four">
                    <div className="container section-four-content">
                        <div className="info text-center">
                            <div className="section-title">
                                <h2 className='text-center white'>Bring AI Training To Your School.</h2>
                            </div>
                            <p className="text text-center wider ">Schedule a meeting to see if your school is the right fit.</p>
                        </div>
                    </div>
                    <a target="_blank" href="https://usemotion.com/meet/william-grube/meeting?d=30" className="btn get-app-btn">Schedule Meeting</a>
                </section>
                <Footer />
            </div>

        </body>
    );
}

export default Testimonials;
